import React, {FunctionComponent, useEffect, useState} from "react";
import useUserRole from "../../../../../hooks/useUserRole";
import empty from "../../../../../style/assets/img/empty.png";
import {assetService, errorService, indoorMapService, messageService} from "../../../../../services";
import {IndoorMap} from "../../../../../interfaces/indoor-map.interface";
import AssignMapPopup from "../../../AssignPopup";
import { Asset } from "../../../../../interfaces/asset.interface";
import DeletePopup from "../../../DeletePopup";
import {canShow} from "../../../../../helpers/userHelper";
import Plot from "react-plotly.js";
import { generateLayoutForGraph } from "../../../../../helpers/graphFunctions";
import { Device } from "../../../../../interfaces/device.interface";
import {renderCurrentImgForPin} from "../../../../../helpers/imagePin";
import graphLegend from "../../../GraphLegend";

interface Props {
  mapId: null | number;
  asset: Asset | null;
}
interface Pin {
  x: number[];
  y: number[]
}

const AssetLocation: FunctionComponent<Props> = ({ mapId, asset }) => {
  const permission = useUserRole();
  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [selectedMap, setSelectedMap] = useState<IndoorMap | null>(null);
  const [maps, setMaps] = useState<IndoorMap[] | null>(null);
  const [assignedMap, setAssignedMap] = useState<IndoorMap | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [layout, setLayout] = useState<any>({});
  const [pin, SetPin] = useState<Pin>({x: [], y: []});

  useEffect(() => {
    indoorMapService.getAllMaps({ sortingParameter: 'id', sortingType: 'DESC' }).subscribe(setMaps);
  }, []);

  useEffect(() => {
    mapId && indoorMapService.getMapById(mapId).subscribe(setAssignedMap);

    return () => setAssignedMap(null);
  }, [mapId]);

  useEffect(() => {
    const layoutData = generateLayoutForGraph(
      {width: 1100, height: 800},
      {xLine: assignedMap?.graphValue?.x ?? 0, yLine: assignedMap?.graphValue?.y ?? 0},
      assignedMap?.imageUrl ?? empty);
    if (asset?.deviceDtos && asset?.deviceDtos?.length > 0) {
      let xAxis: number[] = [],
        yAxis: number[] = [],
        images: any = [
          ...layoutData.images,
        ]
      asset?.deviceDtos?.forEach((item: Device) => {
        if (item.position?.x > 0 && item.position?.y > 0) {
          xAxis.push(item.position.x);
          yAxis.push(item.position.y);
          images.push({
            source: renderCurrentImgForPin(item.status),
            xref: "x",
            yref: "y",
            x: item.position.x,
            y: item.position.y,
            sizex: 80,
            sizey: 80,
            xanchor: "center",
            yanchor: "middle",
            layer: "above",
          })
        }
      });
      const newLayout = { ...layoutData, images: [...images]}
      SetPin({x: xAxis, y: yAxis});
      setLayout(newLayout);
    } else {
      setLayout(layoutData);
      SetPin({x: [], y: []});
    }
  }, [assignedMap, asset]);

  const openAssignPopup = () => setPopup({ isOpen: true, type: "create" });
  const openUnssignPopup = () => setPopup({ isOpen: true, type: "delete" });
  const closePopup = () => {
    setPopup({isOpen: false, type: "create"});
    setSelectedMap(null);
  };

  const renderPopups = () => {
    if (popup.type === 'create') {
      return <AssignMapPopup
        closePopup={closePopup}
        maps={maps}
        selectedItem={selectedMap}
        setSelectedItem={setSelectedMap}
        submitFunc={submitFunc}
        isSubmitting={isSubmitting}
      />
    } else {
      return <DeletePopup itemType="map"
                handleCancel={closePopup} 
                handleSubmit={submitRemoveMap} 
                disabled={isSubmitting}/>
    }
  };

  const submitFunc = () => {
    if (asset && selectedMap) {
      const updatedAsset = { ...asset, mapId: selectedMap.id };
      setIsSubmitting(true);
      assetService.updateAsset(updatedAsset).subscribe({
        next: () => {
          closePopup();
          assetService.getCurrentAsset(asset.id).subscribe();
        },
        complete: () => {
          messageService.addMessage(`${selectedMap.name} has been assigned successfully`);
          setIsSubmitting(false);
        },
        error: error => {
          errorService.addError({ message: `Failed to assign map! ` + error.messageText });
          setIsSubmitting(false);
        }
      });
    }
  };

  const submitRemoveMap = () => {
    if (asset && assignedMap) {
      const updatedAsset = { ...asset, mapId: null };
      setIsSubmitting(true);
      assetService.updateAsset(updatedAsset).subscribe({
        next: () => {
          assetService.getCurrentAsset(asset.id).subscribe();
          closePopup();
        },
        complete: () => {
          messageService.addMessage(`${assignedMap.name} has been successfully removed`);
          setAssignedMap(null);
          setIsSubmitting(false);
        },
        error: error => {
          errorService.addError({ message: `Failed to remove map! ` + error.messageText });
          setIsSubmitting(false);
        }
      });
    }
  };

  const renderMap = () => {
    const {x, y} = pin;
    return (
      <>
        <div className="img-block">
          <Plot
            divId="myPlot"
            data={[
              {
                x: x,
                y: y,
                type: 'scatter',
                mode: 'markers',
                marker: {
                  color: 'transparent',
                  size: 20,
                },
                name: '',
                hovertemplate: asset?.deviceDtos?.filter((item: Device) => item?.position?.x > 0 && item?.position?.y > 0)
                  .map((item: Device) => {
                    let payload = item.payload && JSON.parse(item.payload);
                    if (payload) {
                      return `<br><b>${item.name}</b><br>` +
                        `<br>CO2: ${payload?.co2_pressure_compensated ?? '-'} ppm<br>` +
                        `Battery: ${payload?.rem_batt_capacity_sensor ?? '-'} %<br>`
                    } else {
                      return `<br><b>${item.name}</b><br>`
                    }
                  }) ?? [],
                hoverlabel: {
                  bgcolor: '#F4F6FA',
                  bordercolor: 'transparent',
                  font: {
                    size: 12,
                    color: '#42454E',
                  }
                }

              }
            ]}
            layout={ layout }
            config={{displayModeBar: false}}
          />
        </div>
        { (x.length > 0 && y.length > 0) && graphLegend() }
      </>
    )
  };

  return (
    <>
      <div className="device-location">
        <div className="map-title-block">
          <p className="title">{assignedMap?.name ?? ""}</p>
          {canShow(permission) && <div className="btns-block">
            <button className="btn save-btn add-map-btn" onClick={openAssignPopup}>{!assignedMap ? 'Add New Map' : 'Change Map'}</button>
            {assignedMap && <button className="btn save-btn unassign-btn" onClick={openUnssignPopup}>unassign map</button>}
          </div>}
        </div>
        {
          assignedMap ? renderMap() : <div className="empty-block">
            <img className='empty-img' src={empty} alt="empty" />
          </div>
        }
        {
          popup.isOpen && renderPopups()
        }
      </div>
    </>
  )
};

export default AssetLocation;