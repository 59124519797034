import { useEffect, useState } from "react";
import { customerService } from "../../services";
import { Customer } from "../../interfaces/customer.interface";

const useCustomers = () => {
    const [state, setState] = useState<Customer[]>([]);
    useEffect(() => {
        const sub = customerService.getAllCustomers().subscribe(setState);
        return () => sub.unsubscribe();
    }, []);
    return state;
};

export default useCustomers;