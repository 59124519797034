import { useEffect } from "react";
import { deviceService } from "../../services";
import { delay, mergeMap, of, repeat, take } from "rxjs";

const toMinutes = (minutes: number): number => 1000 * 60 * minutes;

const useDevicePagePoll = (deviceEUI: string | undefined) => {
  useEffect(() => {
    if (deviceEUI) {
      const sub = of({}).pipe(
        mergeMap(() => deviceService.getCurrentDevice(deviceEUI).pipe(
          take(1)
        )),
        delay(toMinutes(1)),
        repeat()
      ).subscribe();

      return () => sub.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDevicePagePoll;