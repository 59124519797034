import {BehaviorSubject, catchError, map, Observable, throwError} from "rxjs";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { Api } from "../../api/api";
import { errorService } from "../index";
import { NewTag, Tag, TagWithPage, UpdateTag } from "../../interfaces/tag.interface";
import {FilterQuery} from "../../interfaces/device.interface";

const TagService = () => {
  const tags$ = new BehaviorSubject<Tag[]|null>(null);

  const createTag = (body: NewTag): Observable<any> => {
    return Api.post(API_ROUTES.TAG.CREATE_TAG, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const updateTag = (body: UpdateTag): Observable<any> => {
    return Api.put(API_ROUTES.TAG.CREATE_TAG, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  }

  const getTagsByCustomer = (options?: any): Observable<Tag[]> => {
    const urlParams = { ...options };
    return Api.get(API_ROUTES.TAG.GET_ALL_TAGS,
      urlParams ? { urlParams } : undefined
    ).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((tags: TagWithPage) => {
        return tags.content;
      }),
    );
  };

  const getFiltersParams = (): Observable<any> => {
    return Api.get(API_ROUTES.TAG.GET_FILTER_PARAMS).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const getFilteredData = (params: FilterQuery): Observable<any> => {
    return Api.post(API_ROUTES.TAG.GET_FILTER_DATA, params).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const deleteTag = (id: number): Observable<any> => {
    return Api.delete(`${API_ROUTES.TAG.DELETE_TAG}/${id}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const deleteTagFlow = (id: number): Observable<any> => {
    return deleteTag(id).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    )
  };

  return {
    tags: tags$.asObservable(),
    createTag,
    getFiltersParams,
    getFilteredData,
    deleteTag,
    deleteTagFlow,
    getTagsByCustomer,
    updateTag,
  }
};

const singleton = TagService();
export default Object.freeze(singleton);
