import { useEffect } from "react";
import { deviceService } from "../../services";
import { delay, mergeMap, of, repeat, take } from "rxjs";

const toMinutes = (minutes: number): number => 1000 * 60 * minutes;

const useDevicePoll = (filterStatus: boolean) => {
  useEffect(() => {
    if (!filterStatus) {
      const sub = of({}).pipe(
        mergeMap(() => deviceService.getDeviceByCustomer({ sortingParameter: 'installDate', sortingType: 'DESC' }).pipe(
          take(1)
        )),
        delay(toMinutes(1)),
        repeat()
      ).subscribe();

      return () => sub.unsubscribe();
    }
  }, [filterStatus]);
};

export default useDevicePoll;