import CustomSelect from "./Select";
import CustomMultiSelect from "./MultiSelect";
import SearchSelect from "./SearchSelect";
import { SelectOption } from "./select.interface"

const makeSelectOptions = (array: any[], valueProp: string, labelProp: string) => {
    let options: SelectOption[] = [];
    array.map((item) =>
        options.push(
            {
                value: item[valueProp].toString(),
                label: item[labelProp].toString()
            })
    )
    return options;
}

export {
    CustomSelect,
    CustomMultiSelect,
    SearchSelect,
    makeSelectOptions
};
