import { GraphMetrics, GraphSize } from "../interfaces/graphMetrics.interface";

export const generateLayoutForGraph = (graphSize: GraphSize, metrics: GraphMetrics, imgUrl: any) => {
  return {
    width: graphSize.width,
    height: graphSize.height,
    xaxis: {
      showgrid: false,
      zeroline: false,
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false,
      range: [0, metrics?.xLine || 0],
      tickwidth: 2,
      fixedrange: true,
    },
    yaxis: {
      tickwidth: 1,
      showgrid: false,
      range: [0, metrics?.yLine || 0],
      fixedrange: true,
      zeroline: false,
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false,
    },
    images: [
      {
        source: imgUrl,
        xref: "x",
        yref: "y",
        x: 0,
        y: 0,
        sizex: metrics?.xLine || 0,
        sizey: metrics?.yLine || 0,
        sizing: "contain",
        opacity: 1,
        layer: "below",
        xanchor: "left",
        yanchor: "bottom",
      },
    ],
  }
};

export const generateLayoutForSetBeacon = (graphSize: GraphSize, metrics: GraphMetrics, imgUrl: any) => {
  return {
    width: graphSize.width,
    height: graphSize.height,
    xaxis: {
      showgrid: false,
      fixedrange: true,
      zeroline: false,
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false,
    },
    yaxis: {
      showgrid: false,
      fixedrange: true,
      zeroline: false,
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false,
    },
    images: [
      {
        source: imgUrl,
        xref: "x",
        yref: "y",
        x: 0,
        y: 0,
        sizex: metrics?.xLine || 0,
        sizey: metrics?.yLine || 0,
        sizing: "contain",
        opacity: 1,
        layer: "below",
        xanchor: "left",
        yanchor: "bottom",
      },
    ],
  }
}

export const heatmapData = (width: number, height: number) => {
  let x = [],
    y = [],
    z = [];

  for(let i = 0; i < width; i = i + 1) {
    x.push(i);
  }
  for(let j = 0; j < height; j = j + 1) {
    y.push(j);
  }
  for(let j = 0; j < y.length; j++) {
    let temp = [];
    for(let k = 0; k < x.length; k++) {
      temp.push(0);
    }
    z.push(temp);
  }

  let heatmap = {
    z: z,
    type: 'heatmap',
    colorscale: [
      [0.0, 'rgba(255, 255, 255, 0)'],
      [1.0, 'rgba(255, 255, 255, 0)'],
    ],
    xgap: 1,
    ygap: 1,
    hoverinfo: 'x+y',
    showscale: false
  };

  const beaconTrace = {
    type: 'scatter',
    mode: 'markers',
    marker: {
      color: '#1F92C4',
      size: 20,
    },
    name: 'Device',
    x: [],
    y: [],
  };

  return {
    heatmap,
    beaconTrace,
    xCoordinate: x,
  };
};