import React, { FunctionComponent, memo } from "react";
import ButtonEdit from "../Buttons/ButtonEdit";
import ButtonDelete from "../Buttons/ButtonDelete";
import useTags from "../../../hooks/useTags";
import TagPanel from "../TagPanel";
import { Device } from "../../../interfaces/device.interface";
import { getCO2LevelClass } from "../displayFunctions"
import { tableDeviceNames } from "../../../constants/table-names.constant";
import { measurements } from "../../../constants/measurements.constant";
import useUserRole from "../../../hooks/useUserRole";
import { canShow } from "../../../helpers/userHelper";
import ButtonCreateConfiguration from "../Buttons/ButtonCreateConfiguration";

interface Props {
  device: Device;
  editFunc: () => void;
  editTag: () => void;
  deleteDevice: () => void;
  createConfig: () => void;
}

const devicePageNames: { [key: string]: string } = {
  ...tableDeviceNames,
  deviceType: "Device type",
  deviceEUI: "Device EUI",
  inactivityTimeout: 'Inactivity Timeout',
  lastPayloadTime: "Last activity",
  rem_batt_capacity_display: "Display battery level",
  rem_batt_capacity_sensor: "Sensor battery level",
}

const deviceInfo = ["deviceType", "deviceEUI", "siteName", "inactivityTimeout", "lastPayloadTime"];
const deviceInfoStates = ['co2_pressure_compensated', 'temperature', 'relative_humidity',
  'rem_batt_capacity_display', 'barometric_pressure', 'rem_batt_capacity_sensor'];

const convertTimestamp = (timestamp: number) => {
  let date = new Date(timestamp);
  return date.toLocaleDateString("en-US") + ' ' + date.toLocaleTimeString("it-IT");
}

const DeviceInfo: FunctionComponent<Props> = memo(({ device, editFunc, deleteDevice, editTag, createConfig }) => {

  const allTags = useTags() || [];
  const permission = useUserRole();
  const payload = device.payload && JSON.parse(device.payload);

  const displayData = (key: string) => {
    switch (key) {
      case "deviceType":
      case "deviceEUI":
      case "siteName":
        return device[key];
      case "inactivityTimeout":
        return device[key] + " " + measurements[key];
      case "lastPayloadTime":
        if (device.status === "pending") return "-";
        return convertTimestamp(+device[key])
    }
  }

  return (
    <div className="device-item">
      <div className="device-item-block">
        <div className="device-info-block">
          <div className="device-id-block">
            <div className="device-id">
              <div className="device-status">
                <div className={`status-img ${device.status}`} />
              </div>
              <span className="device-name">{device.name}</span>
            </div>
            {
              canShow(permission) ? <div className="device-btn">
                <ButtonEdit onClick={editFunc} />
                <ButtonDelete onClick={deleteDevice} />
              </div> : null
            }
          </div>
          <div className="flexbox-block">
            <div className="device-info">
              {deviceInfo.map((data, index) => {
                return (
                  <div className="device-info-data" key={index}>
                    <p className="device-info-data_name">{devicePageNames[data]}</p>
                    <p className="device-info-data_value">{displayData(data)}</p>
                  </div>
                )
              })}
            </div>
            { canShow(permission) && <div className="config-btn-block">
              <ButtonCreateConfiguration onClick={createConfig}/>
            </div> }
          </div>
        </div>
        <div className="device-data-block">
          {deviceInfoStates.map((state, index) => {
            return (
              <div key={index} className={`device-data ${state} 
                                         ${state === "co2_pressure_compensated" ? getCO2LevelClass(payload?.[state]) : ""}`}>
                <p className="device-data_value ">{typeof payload?.[state] === 'number' ? `${payload[state]} ${measurements[state]}` : "-"}</p>
                <p className="device-data_name-value">{devicePageNames[state]}</p>
              </div>
            )
          })}
        </div>
      </div>
      <TagPanel tags={device.tags} allTags={allTags} editTagFunc={editTag} />
    </div>
  )
});

export default DeviceInfo;
