import React, {FunctionComponent, memo} from "react";
import trash from "../../../../style/assets/img/trash-icon.png";

interface Button {
  onClick: (item: any) => void,
}

const ButtonDelete: FunctionComponent<Button> = memo(({onClick}) =>
  <button className="btn table-btn delete-red-btn" onClick={onClick}><img src={trash} alt="trash"/></button>);

export default ButtonDelete;