import { useEffect, useState } from "react";
import { assetService } from "../../services";
import { Asset } from "../../interfaces/asset.interface";

const useAssetList = () => {
  const [state, setState] = useState<Asset[] | null>(null);

  useEffect(() => {
    const sub = assetService.assetsList.subscribe(setState);

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useAssetList;