import {FilterValue} from "../interfaces/device.interface";

export const tagFilterObject = (tagArray: string[]) => {
  return tagArray.reduce((prevValue, currValue, index) => {
    if (index === 0) {
      return {
        left: currValue,
        operand: "EXISTS",
        right: currValue,
      }
    } else {
      return {
        left: prevValue,
        operand: "OR",
        right: {
          left: currValue,
          operand: "EXISTS",
          right: currValue,
        }
      }
    }
  }, {});
};

export const activeFilterField = (obj: FilterValue) => {
  const activeField = { ...obj };
  Object.keys(activeField).forEach((item) => {
    if (item === 'search' || activeField[item].length === 0 || item === 'tags') {
      return delete activeField[item];
    }
  });

  return activeField;
}