import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';

interface Layout {
    withSidebar?: boolean;
}

const MainLayout = ({ withSidebar = true }: Layout) => {
    return (
        <div className='app'>
            {withSidebar && <Sidebar />}
            <div className={`main ${withSidebar ? "main-with-sidebar" : ""}`}>
                <Outlet />
            </div>
        </div>
    )
}

export default MainLayout
