import React, {FunctionComponent, memo} from "react";
import StaticTable from "../../../../StaticTable";

interface Props {
  data: any[];
  editMap: () => void;
}

const friendlyNamesForTableHead = {
  keyFriendlyName: "Key",
  value: "Value"
}

const IndoorMapInfo: FunctionComponent<Props> = memo(({data, editMap}) => {

  return (
    <div className="device-item">
      <StaticTable
        friendlyTableHeadNames={friendlyNamesForTableHead}
        data={data}
        childrenRoute={""}
        editItem={() => {}}
        deleteItem={() => {}}
      />
      {/*{popup.isOpen && renderPopup()}*/}
    </div>
  );
});

export default IndoorMapInfo;