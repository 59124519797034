import { useEffect, useState } from "react";
import { Asset } from "../../interfaces/asset.interface";
import { assetService } from "../../services";

const useCurrentAsset = () => {
  const [state, setState] = useState<Asset | null>(null);

  useEffect(() => {
    const sub = assetService.asset.subscribe(setState)

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useCurrentAsset;