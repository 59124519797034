import { useEffect, useState } from "react";
import { authService } from "../../services";

const useUserRole = () => {
  const [state, setState] = useState<string | null>('');

  useEffect(() => {
    const sub = authService.storedUserRole.subscribe(setState);

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useUserRole;