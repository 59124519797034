export interface TableNames {
    [key: string]: string;
}

export const siteViewerTableNames: TableNames = {
    userName: "Name",
    email: "Email",
    customerId: "Customer",
    sites: "Site"
}

export const tableTagNames: TableNames = {
  keyFriendlyName: 'Name',
  tagColor: 'Color',
}

export const deviceParameters = ["temperature", "humidity", "pressure", "battery", "co2", "position"];

export const tableDeviceNames: TableNames = {
  status: "Status",
  name: "Name",
  deviceEUI: "EUI",
  deviceType: "Type",
  tags: "Tags",
  siteName: "Site name",
  position: "Coordinates",
  co2_pressure_compensated: "CO2",
  barometric_pressure: "Pressure",
  temperature: "Temperature",
  relative_humidity: "Humidity",
  rem_batt_capacity_display: "Display battery",
  rem_batt_capacity_sensor: "Sensor battery",
  battery_voltage: "Battery Voltage",
  light_intensity: "Light Intensity",
  light_state: "Light State",
  co2_raw: "CO2 Raw",
  motion_event_state: "Motion State",
  motion_event_count: "Motion Count",
  deviceName: 'Device name',
}

export const defaultDeviceTableGeneralNames = ['status', 'name', 'deviceEUI', 'deviceType', 'tags', 'siteName'];
export const defaultAssetTableGeneralNames = ['name', 'tags', 'siteName', 'deviceName'];
export const defaultTableStateNames = ['light_state', 'light_intensity', 'battery_voltage', 'rem_batt_capacity_sensor',
  'rem_batt_capacity_display', 'co2_pressure_compensated', 'co2_raw', 'barometric_pressure', 'motion_event_state',
  'motion_event_count', 'temperature', 'relative_humidity'];