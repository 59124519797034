import { useEffect } from "react";
import { settingsService } from "../../services";

const useSettings = () => {
  useEffect(() => {
    const sub = settingsService.getSettings().subscribe();

    return () => sub.unsubscribe();
  }, []);
};

export default useSettings;