import { Asset, NewAsset } from "../../../../interfaces/asset.interface";
import { assetService, deviceService, errorService, messageService } from "../../../../services";

export const addAsset = (asset: NewAsset, onComplete: () => void, devices: string[]) => {
    assetService.createAsset(asset).subscribe({
        next: (asset) => {
            if (devices.length > 0) {
                devices.forEach((item) => {
                    deviceService.getCurrentDevice(item).subscribe((device) => {
                        if (device) {
                            const data = {
                                ...device,
                                assetId: asset.id
                            };
                            deviceService.updateDevice(data).subscribe();
                        }
                    })
                })
            };
            messageService.addMessage(`Room was created!`);
            onComplete();
        },
        error: (error) => {
            errorService.addError({ message: `Failed to add room! ` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
};

export const editAsset = (asset: Asset, onComplete: () => void, devices: string[]) => {
    const currentDevices = asset.deviceDtos.map((item, index) => item.deviceEUI);
    const filteredData = asset.deviceDtos?.map((item, index) => !devices?.includes(item.deviceEUI) ? item : null) ?? [];
    if (devices.length > 0) {
        devices.forEach((item) => {
            if (!currentDevices?.includes(item)) {
                deviceService.getCurrentDevice(item).subscribe((device) => {
                    if (device) {
                        const data = {
                            ...device,
                            assetId: asset.id,
                            mapId: asset.mapId ? asset.mapId : undefined,
                        };
                        deviceService.updateDevice(data).subscribe();
                    }
                })
            }
        })
    }
    if (filteredData.length > 0) {
        filteredData.forEach(item => {
            if (item) {
                const newData = { ...item, assetId: null, mapId: null, position: null }
                deviceService.updateDevice(newData).subscribe();
            }
        })
    }
    assetService.updateAsset(asset).subscribe({
        next: () => {
            messageService.addMessage(`Room was updated!`);
            onComplete();
        },
        error: (error) => {
            errorService.addError({ message: `Failed to update room! ` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
}

export const deleteAssetFlow = (assetId: number|undefined, assetName: string, deviceDtos: any[], onComplete: ()=>void) => {
    if (assetId && deviceDtos?.length > 0) {
      assetService.unpairDevices(assetId).subscribe(
        () => {
          deleteAsset(assetId, assetName, onComplete);
        }
      )
    } else if (assetId) {
      deleteAsset(assetId, assetName, onComplete);
    }
  };

const deleteAsset = (assetId: number, assetName: string, onComplete: () => void) => {
    assetService.deleteAssetFlow(assetId).subscribe({
      next: () => {
        messageService.addMessage(`Room ${assetName} was removed successfully`);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to remove room! ` + error.messageText });
        onComplete();
      },
      complete: () => onComplete()
    });
  };