import React, {memo, FunctionComponent} from "react";

interface Props {
  label: string;
  value: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (event: any) => void;
  name?: string;
}

const CustomCheckbox: FunctionComponent<Props> = memo(({label, value, defaultChecked, onChange, checked, name}) => {
  return (
    <div className="custom-checkbox-block">
      <input className="custom-checkbox" type="checkbox" id={value} name={name} onChange={onChange} value={value} checked={checked} />
      <label htmlFor={value}>{label}</label>
    </div>
  );
});

export default CustomCheckbox;