import React from "react";
import ButtonEdit from "../Buttons/ButtonEdit";
import ButtonDelete from "../Buttons/ButtonDelete";
import { Link } from "react-router-dom";
import useUserRole from "../../../hooks/useUserRole";
import { canShow } from "../../../helpers/userHelper";
import {defaultTableStateNames} from "../../../constants/table-names.constant";

interface StaticTableProps {
  friendlyTableHeadNames?: { [key: string]: string };
  data: any[];
  childrenRoute?: string;
  displayFunc?: (key: string, value: string) => any;
  editItem: (item: any) => void;
  deleteItem: (item: any) => void;
  className?: string;
  onClick?: (item: any) => void;
  activeElementId?: number | null;
  lastHeadItemElement?: any;
}

export default function StaticTable({
  friendlyTableHeadNames, data, childrenRoute,
  displayFunc, editItem, deleteItem, className, onClick, activeElementId, lastHeadItemElement }: StaticTableProps) {

  const tableHeads = Object.keys(friendlyTableHeadNames ?? {});
  const permission = useUserRole();

  const handleEditClick = (event: any, item: any) => {
    event.preventDefault();
    event.stopPropagation();
    editItem(item);
  };

  const handleDeleteClick = (event: any, item: any) => {
    event.preventDefault();
    event.stopPropagation();
    deleteItem(item)
  }

  const renderItem = (item: any, index: number) => {
    const bodyElements = () => {
      return (
        <>
          {tableHeads.map((key: string, index) => {
            return displayFunc
              ? <div className={`table-body_item ${defaultTableStateNames.includes(key) ? 'state' : `${key}`}`} key={index}>{displayFunc(key, item[key]) ?? '-'}</div>
              : <div className={`table-body_item ${defaultTableStateNames.includes(key) ? 'state' : `${key}`}`} key={index}>{item[key] ?? '-'}</div>
          })}
          {
            canShow(permission) ? <div className={`table-body_item ${lastHeadItemElement ? "columns-wide" : "columns"} buttons`}>
              <ButtonEdit onClick={(event) => handleEditClick(event, item)} />
              <ButtonDelete onClick={(event) => handleDeleteClick(event, item)}/>
            </div> : null
          }
        </>
      )
    };

    if (childrenRoute) {
      return (
        <Link to={childrenRoute} className="table-body_row" key={index}>
          {bodyElements()}
        </Link>
      )
    } else {
      return (
        <div
          className={`table-body_row ${onClick ? 'clickable' : ''} ${(activeElementId && activeElementId === item.id) ? 'active' : ''}`}
          key={index}
          onClick={onClick ? () => onClick(item) : () => {}}>
          {bodyElements()}
        </div>
      )
    }
  };

  return (
    <div className={`table static-table ${className ? className : ''}`}>
      <div className="table-head">
        {tableHeads.map((key: string, index) => <div className="table-head_item" key={index}>{friendlyTableHeadNames?.[key] || ""}</div>)}
        {canShow(permission)
          ? lastHeadItemElement
            ? <div className="table-head_item columns-wide buttons">{lastHeadItemElement}</div>
            : <div className="table-head_item columns"></div>
          : null}
      </div>
      <div className="table-body">
        {data.length > 0
          ? data.map((item: any, index: number) => renderItem(item, index))
          : <div className="table-body_row">No elements to show</div>
        }
      </div>
    </div>
  )
}
