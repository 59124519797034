import { Device, NewDevice } from "../../../../interfaces/device.interface";
import { deviceService, messageService, errorService } from "../../../../services";

export const addDevice = (device: NewDevice, onComplete: () => void) => {
    deviceService.createDevice(device).subscribe({
        next: () => {
            messageService.addMessage(`Device was created!`);
        },
        error: (error) => {
            errorService.addError({ message: `Failed to add device! ` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
}

export const updateDevice = (device: NewDevice, onComplete: () => void, message?: string) => {
    deviceService.updateDeviceFlow(device).subscribe({
        next: () => {
            messageService.addMessage(!message ? `Device was updated!` : message);
        },
        error: (error) => {
            errorService.addError({ message: `Failed to ${!message ? 'update device!' : 'add location!'} ` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
}

export const deleteDevice = (device: Device, onComplete: () => void) => {
    let deviceId = device.deviceEUI;
    deviceService.deleteDeviceFlow(deviceId).subscribe({
        next: () => {
            messageService.addMessage(`Device ${deviceId} was removed successfully`);
        },
        error: (error) => {
            errorService.addError({ message: `Failed to remove device! ` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
};