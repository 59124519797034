import React from "react";
import online from "../../../style/assets/img/online-pin.png";
import offline from "../../../style/assets/img/offline-pin.png";
import pending from "../../../style/assets/img/pending-pin.png";

export default function graphLegend () {
  const legendsValues: any = {
    online: {
      text: 'online',
      image: online
    },
    offline: {
      text: 'offline',
      image: offline
    },
    pending: {
      text: 'pending connection',
      image: pending
    },
  }
  return (
    <div className="legend-block">
      {
        Object.keys(legendsValues).map((item, index) => {
          return (
            <div className="legend-block_item" key={index}>
              <img src={legendsValues[item].image} alt={legendsValues[item].text}/>
              <p>{legendsValues[item].text}</p>
            </div>
          )
        })
      }
    </div>
  )
}