import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { Asset, NewAsset } from "../../interfaces/asset.interface";
import { Api } from "../../api/api";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { tap } from "rxjs/operators";
import { errorService } from "../index";
import { FilterQuery} from "../../interfaces/device.interface";


const AssetService = () => {
  const asset$ = new BehaviorSubject<Asset | null>(null);
  const assetsList$ = new BehaviorSubject<Asset[] | null>(null);

  const updateAssetStore = (param: Asset | null) => {
    asset$.next(param);
  };

  const updateAssetListStore = (param: Asset[] | null) => {
    assetsList$.next(param);
  };

  const createAsset = (body: NewAsset): Observable<any> => {
    return Api.post(API_ROUTES.ASSET, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const updateAsset = (body: Asset): Observable<any> => {
    return Api.put(API_ROUTES.ASSET, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const getAllAssets = (options?: any): Observable<Asset[]> => {
    const urlParams = { ...options };
    return Api.get(API_ROUTES.ASSET,
      urlParams ? { urlParams } : undefined
    ).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((assets: Asset[]) => {
        return assets || null
      }),
      tap((assets) => assetsList$.next(assets))
    );
  };
  
  const getCurrentAsset = (assetId: number): Observable<Asset | null> => {
    return Api.get(`${API_ROUTES.ASSET}/${assetId}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((asset: Asset) => {
        return asset || null
      }),
      tap(updateAssetStore)
    );
  };

  const getFiltersParams = (): Observable<any> => {
    return Api.get(`${API_ROUTES.ASSET}/get-filter-parameters`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    )
  };

  const getFilteredData = (params: FilterQuery): Observable<any> => {
    return Api.post(`${API_ROUTES.ASSET}/filter`, params).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((assetList: Asset[]) => {
        return assetList || null
      }),
      tap(updateAssetListStore)
    );
  };

  const unpairDevices = (assetId: number): Observable<any> => {
    return Api.get(`${API_ROUTES.ASSET}/unpair-devices/${assetId}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const deleteAsset = (id: number): Observable<any> => {
    return Api.delete(`${API_ROUTES.ASSET}/${id}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const deleteAssetFlow = (id: number): Observable<any> => {
    return deleteAsset(id).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  return {
    asset: asset$.asObservable(),
    assetsList: assetsList$.asObservable(),
    createAsset,
    updateAsset,
    updateAssetStore,
    updateAssetListStore,
    getAllAssets,
    getCurrentAsset,
    getFiltersParams,
    getFilteredData,
    unpairDevices,
    deleteAsset,
    deleteAssetFlow,
  }
};

const singleton = AssetService();
export default Object.freeze(singleton);