import React, {FunctionComponent, memo} from "react";
import edit from "../../../../style/assets/img/edit.png";

interface Button {
  onClick: (item: any) => void,
}

const ButtonEdit: FunctionComponent<Button> = memo(({onClick}) =>
  <button className="btn table-btn edit-btn" onClick={onClick}><img src={edit} alt="edit"/></button>);

export default ButtonEdit;