import React, { FunctionComponent, memo, useRef, useState } from "react";
import errorTriangle from "../../../style/assets/img/error-triangle.png";
import uploadImg from "../../../style/assets/img/upload.png";
import uploadSecondImg from "../../../style/assets/img/upload-2.png";
import { FormikProps } from "formik";
import { FileUploader } from "react-drag-drop-files";
import ButtonDelete from "../Buttons/ButtonDelete";
import CustomForm from "../CustomForm";
import { mixed as yupMixed, object as yupObject } from "yup";
import { validation}  from "../../../constants/errors/errors.constant";
import { FormWithCloseFunc } from "../../../interfaces/form.interface";
import { deviceService, errorService } from "../../../services";
import Progress from "../ProgressBar";

const validationSchema = () => yupObject().shape({
  file: yupMixed().required(validation.isRequired("file")),
});

interface ErrorCodes {
  code: string;
  message: string;
  messageText: string;
}
interface FileForm {
  file: any;
}


const MultipleDeviceForm: FunctionComponent<FormWithCloseFunc> = memo(({ closeFunc, ...props}) => {
  const fileTypes = ["CSV"];
  const [uploadErrors, setUploadErrors] = useState({type: false, size: false});
  const [errorCodesFromFile, setErrorCodesFromFile] = useState<ErrorCodes[] | null>(null);
  const [savedRowCount, setSavedRowCount] = useState<number | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const key = useRef(0);
  const fileUploader = (values: any) => {
    setLoader(true);
    let formData = new FormData();
    formData.append('file', values.file);
    deviceService.createDeviceByCSV(formData).subscribe({
      next: (device) => {
        const { errorMessageCodes, savedRowCount } = device;
        setErrorCodesFromFile(errorMessageCodes);
        setSavedRowCount(savedRowCount);
        setDisableBtn(true);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to add device! ` + error.messageText });
        setLoader(false);
        setDisableBtn(true);
      },
      complete: () => setLoader(false)
    });
  };

  const cancelFunc = () => {
    deviceService.getDeviceByCustomer({ sortingParameter: 'installDate', sortingType: 'DESC' }).subscribe({});
    closeFunc();
  };

  const renderChildren = () => {
    return (
      <div className="popup-map_children">
        { uploadErrors.type || uploadErrors.size
          ? <div className="popup-map_children_error">
            <img src={errorTriangle} alt="upload-img"/>
            <p className="popup-map_children__title">File cannot be uploaded</p>
            {uploadErrors.type
              ? <p className="popup-map_children__formats">{`Invalid image format`}</p>
              : <p className="popup-map_children__formats">{`Invalid image size`}</p>}
          </div>
          : <img src={uploadImg} alt="upload-img"/>
        }
        <p className="popup-map_children__title">Drag and drop your file here or</p>
        <div className="blue-block">
          <p>Select a file</p>
          <img src={uploadSecondImg} alt="upload-img"/>
        </div>
        <p className="popup-map_children__formats">{`Supported file formats: .csv`}</p>
        <p className="popup-map_children__formats size-format">{`Maximum file size: 1MB`}</p>
      </div>
    );
  }

  const renderForm = ({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        setFieldTouched,
                        isSubmitting,
                        isValid,
                        dirty,
                        handleSubmit,
                      }: FormikProps<FileForm>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeFile = (file: any) => {
      key.current = key.current + 1;
      setUploadErrors({type: false, size: false});
      setErrorCodesFromFile(null);
      setSavedRowCount(null);
      setFieldValue("file", file);
      setDisableBtn(false);
    };
    return (
      <>
        <form className="map-popup-body" onSubmit={handleSubmit}>
          <div className="main-content">
            <div className="popup-form_uploader">
              <FileUploader
                key={key.current}
                handleChange={onChangeFile}
                name="file"
                types={fileTypes}
                maxSize={1}
                classes={`drop_area ${Boolean(touched.file && errors.file) ? "error" : ""}`}
                children={renderChildren()}
                onTypeError={() => setUploadErrors({type: true, size: false})}
                onSizeError={() => setUploadErrors({type: false, size: true})}
                disabled={loader}
                onBlur={onBlur('file')}
              />
            </div>
            {
              values.file && !errorCodesFromFile && !savedRowCount && <div className="map-popup_inputs__file">
                <div className="map-popup_inputs__file__name">
                  <p>{values.file.name}</p>
                </div>
                <ButtonDelete onClick={() => onChangeFile(null)}/>
              </div>
            }
            {
              savedRowCount && <div className="successful-block">
                <p>Devices have been successfully added: {savedRowCount}</p>
              </div>
            }
            {
              errorCodesFromFile && <div className="errors-block">
                {
                  errorCodesFromFile.map((item, index) => {
                    return (
                      <div className="error-code" key={index}>
                        <p>{item.messageText}</p>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>
          <div className="form-btn">
            <button type="submit" disabled={disableBtn || loader || !isValid} className="btn save-btn">{loader ? <Progress size={20} /> : "Upload"}</button>
            <button className="btn cancel-btn" onClick={cancelFunc}>Cancel</button>
          </div>
        </form>
      </>
    )
  };
  return (
    <>
      <div className="sample-block">
        <a className="sample-btn" href="https://breeze-prod.s3.eu-west-1.amazonaws.com/breeze-file/sample_template.csv" download>CSV sample</a>
        <CustomForm renderForm={renderForm} validationSchema={validationSchema} submitForm={fileUploader} {...props} />
      </div>
    </>
  )
});

export default MultipleDeviceForm;