import React  from "react";
import BreadCrumbs from "../../../BreadCrumbs";
import Location from "../../../Location";
// import IndoorMapForm from "../IndoorMapForm"
// import CustomPopup from "../../../CustomPopup";
import IndoorMapInfo from "./IndoorMapInfo";

export default function IndoorMapPage() {
  const dataOption = [
    {
      keyName: 'building',
      value: 'Skovorody 19',
      keyFriendlyName: 'building',
    },
    {
      keyName: 'floor',
      value: '2',
      keyFriendlyName: 'floor',
    },
    {
      keyName: 'site',
      value: 'A',
      keyFriendlyName: 'site',
    },
    {
      keyName: 'size_px',
      value: 'h, w',
      keyFriendlyName: 'Size px',
    },
    {
      keyName: 'size_m',
      value: 'h, w',
      keyFriendlyName: 'Size m',
    },
  ]

  // const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  // const closePopup = () => setPopup({ ...popup, isOpen: false });

  return (
    <>
      <BreadCrumbs
        currentPage={'Indoor map'}
        currentItem={"map 0001"}
        buttonName={"Map"}
        addClick={() => {}}
      />
      <div className="device-page">
        <IndoorMapInfo data={dataOption} editMap={() => {}} />
        <Location device={false} />
      </div>
      {/*{popup.isOpen &&
        <CustomPopup title={"Indoor Map"} titlePrefix={popup.type} classes={"map-popup"} closeFunc={closePopup}>
          <IndoorMapForm closeFunc={closePopup} initialValues={{}} />
        </CustomPopup>}*/}
    </>
  )
}
