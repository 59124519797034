import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../Pages/layouts/MainLayout';
import AssetList from '../Pages/components/pages/Assets/AssetList';
import AssetPage from '../Pages/components/pages/Assets/AssetPage';
import DeviceList from '../Pages/components/pages/Devices/DeviceList';
import DevicePage from '../Pages/components/pages/Devices/DevicePage';
import IndoorMapList from '../Pages/components/pages/IndoorMaps/IndoorMapList';
import IndoorMapPage from '../Pages/components/pages/IndoorMaps/IndoorMapPage';
import SiteViewerList from '../Pages/components/pages/SiteViewerList';
import TagList from '../Pages/components/pages/TagList';
import LoginPage from "../Pages/components/pages/LoginPage";
import ResetPassword from "../Pages/components/pages/ResetPassword";
import ConfirmPassword from "../Pages/components/pages/ConfirmPassword";
import NotFoundPage from '../Pages/components/pages/NotFoundPage';
import PrivateRoute from './PrivateRoute';
import ProtectedRoute from './ProtectedRoute';
import { VERIFICATION_STATE } from "../constants/verification-state.enum";
import Settings from '../Pages/components/pages/Settings';
import ConfigurationsList from "../Pages/components/pages/Configurations/ConfigurationsList";

export default function MainNavigator() {
  return (
    <Routes>
      <Route element={
        <PrivateRoute
          expectedStatus={VERIFICATION_STATE.AUTHORIZED}
          redirectTo="/sign-in"
          errorMessage="You are not Authorised!"
        />}>
        <Route element={<ProtectedRoute />}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<DeviceList />} />
            <Route path="/device">
              <Route index element={<DeviceList />} />
              <Route path=":deviceId" element={<DevicePage />} />
            </Route>
            <Route path="/asset">
              <Route index element={<AssetList />} />
              <Route path=":assetId" element={<AssetPage />} />
            </Route>
            <Route path="/maps">
              <Route index element={<IndoorMapList />} />
              <Route path="map-id" element={<IndoorMapPage />} />
            </Route>
            <Route path="/site-viewers" element={<SiteViewerList />} />
            <Route path="/tags" element={<TagList />} />
            <Route path="/configurations" element={<ConfigurationsList />} />
          </Route>
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Route>
      <Route element={<MainLayout withSidebar={false} />}>
        <Route path="/sign-in" element={<LoginPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/confirm-password" element={<ConfirmPassword />} />
      </Route>
      <Route element={<MainLayout withSidebar={false} />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
