import React, {FunctionComponent, memo, FocusEvent, useState, useEffect} from "react";
import { SelectOption } from "../select.interface"
import CustomInput from "../../CustomInput";

interface Props {
  name: string;
  value: string;
  options: SelectOption[],
  placeholder?: string;
  className?: string;
  onChange: (name: string, value: string) => void;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
}

const SearchSelect: FunctionComponent<Props> = memo(({
   name, value, options, placeholder, onChange, onBlur,
   className, disabled, required, error, errorMessage }) => {

  const [openOptions, setOpenOptions] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);

  const clearSearch = () => setSearchValue('');
  const closeOptions = () => {
    setOpenOptions(false);
    clearSearch();
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (searchValue.length > 0) {
      let newOptions = options.filter((item) => item.label.toLocaleLowerCase().includes(searchValue.toLowerCase()));
      setFilteredOptions(newOptions);
    } else {
      setFilteredOptions(options);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  const handleChange = (event: any, value: string) => {
    event.stopPropagation();
    onChange(name, value);
    closeOptions();
  };

  const handleChangeSearch = (event: any, name: string, value: string) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchValue(value);
  };

  const blur = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      closeOptions();
      onBlur && onBlur(event.target.name);
    }
  };

  return (
    <div className={`custom-select-block ${disabled ? "disabled" : ""}`} tabIndex={0}>
      <div className={`custom-select ${className ? className : ""} ${error ? 'error' : ""}`} tabIndex={0}
           onClick={() => { setOpenOptions(true) }} onBlur={(event) => blur(event)}>
        {value ? <span>{options.find(option => option.value === value)?.label}</span> : <span>{placeholder}</span>}
        {openOptions &&
        <div className="custom-select__options">
          <div className="custom-select__options__search">
            <CustomInput
              className="form-input search-select-field"
              name={"search"}
              placeholder={"Search"}
              value={searchValue}
              onChange={() => {}}
              onChangeWithEvent={handleChangeSearch}
            />
            {
              searchValue.length > 0 ? <span className="clear-icon" onClick={clearSearch}/>
                : <span className="search-icon"/>
            }
          </div>
          {options.length > 0 ?
              filteredOptions.length > 0
              ? filteredOptions.map((option, index) => {
                return (
                  <div className={`custom-select__option ${option.value === value} ? ":hover" : ""`}
                       onClick={(e) => handleChange(e, option.value)} key={index}>
                    <span>{option.label}</span>
                  </div>
                )
              })
              : <div className="custom-select__option empty">No matching records were found</div>
            : <div className="custom-select__option"></div>
          }
        </div>}
      </div>
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </div>
  )
});

export default SearchSelect;