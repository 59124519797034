import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { Api } from "../../api/api";
import { errorService } from "../index";
import { User, NewUser, UpdateUser } from "../../interfaces/user.interface";
import {FilterQuery} from "../../interfaces/device.interface";

const UserService = () => {

    const users$ = new BehaviorSubject<User[] | null>(null);

    const createUser = (body: NewUser): Observable<any> => {
        return Api.post(API_ROUTES.USER.CREATE_USER, body).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            }),
        );
    };

    const updateUser = (body: UpdateUser): Observable<any> => {
        return Api.put(API_ROUTES.USER.UPDATE_USER, body).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            }),
        );
    }

    const getAllUsers = (options?: any): Observable<User[]> => {
        const urlParams = { ...options };
        return Api.get(API_ROUTES.USER.GET_ALL_USERS,
            urlParams ? { urlParams } : undefined
        ).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            })
        );
    };

    const getFiltersParams = (): Observable<any> => {
        return Api.get(API_ROUTES.USER.GET_FILTER_PARAMS).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            })
        )
    };

    const getFilteredData = (params: FilterQuery): Observable<any> => {
        return Api.post(API_ROUTES.USER.GET_FILTER_DATA, params).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            })
        );
    };

    const deleteUser = (email: string): Observable<any> => {
      return Api.post(API_ROUTES.USER.DELETE_USER, {email}).pipe(
        catchError(e => {
          errorService.addError(e);
          return throwError(e);
        })
      );
    };

    return {
        users: users$.asObservable(),
        createUser,
        deleteUser,
        getAllUsers,
        getFiltersParams,
        getFilteredData,
        updateUser
    }
};

const singleton = UserService();

export default Object.freeze(singleton);