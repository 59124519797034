import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import StaticTable from "../../StaticTable";
import empty from "../../../../style/assets/img/empty.png";
import CustomPopup from "../../CustomPopup";
import TagForm from "./TagForm";
import { Tag, NewTag } from "../../../../interfaces/tag.interface";
import { tagService, messageService, errorService } from "../../../../services";
import { TagFormValues } from "./interfaces/tag-form.interface";
import FilterPanel from "../../FilterPanel"
import { makeSelectOptions } from "../../CustomSelect";
import useUserInfo from "../../../../hooks/useUserInfo";
import ProgressBar from "../../ProgressBar";
import { FilterValue } from "../../../../interfaces/device.interface";
import { activeFilterField } from "../../../../helpers/filterHelper";

export default function TagList() {
  // const allTags = useTags();
  const { sites, customerId } = useUserInfo();
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [filterParams, setFilterParams] = useState<any>(null);
  const [editTag, setEditTag] = useState<Tag>();
  const [filterData, setFilterData] = useState<any>([]);
  const [filterStatus, setFilterStatus] = useState<boolean>( false);
  const [savedFilter, setSavedFilter] = useState<FilterValue | null>(null);


  const uploadData = () => {
    if (filterStatus) {
      savedFilter && submitFilter(savedFilter);
    } else {
      tagService.getTagsByCustomer({
        pageNumber: 0,
        pageSize: 100,
        sortingParameter: 'id',
        sortingType: 'DESC'
      }).subscribe(setTags);
    }
  };
  const uploadFilterParams = () => tagService.getFiltersParams().subscribe(setFilterParams);

  useEffect(() => {
    uploadData();
    uploadFilterParams();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    uploadFilterData();
    // eslint-disable-next-line
  }, [filterParams]);

  useEffect(() => {
    !filterStatus && uploadData();
    // eslint-disable-next-line
  }, [filterStatus]);

  const uploadFilterData = () => {
    const filterData = [
      {
        name: "siteName",
        friendlyName: "Sites",
        options: filterParams?.sites && filterParams.sites.length > 1 ? makeSelectOptions(filterParams.sites, "name", "name") : []
      },
    ];
    setFilterData(filterData);
  };

  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const closePopup = () => setPopup({ ...popup, isOpen: false });

  const tableTagNames = {
    keyFriendlyName: 'Name'
  };

  const deleteTag = (tag: Tag) => {
    tagService.deleteTagFlow(tag.id).subscribe({
      next: () => {
        messageService.addMessage(`Tag '${tag.keyFriendlyName}' was removed successfully`);
        uploadData();
      },
      error: (error) => {
        errorService.addError({ message: `Failed to delete tag! ` + error.messageText });
      }
    });
  };

  const addTag = (tag: NewTag, onComplete: () => void) => {
    tagService.createTag(tag).subscribe({
      next: () => {
        uploadData();
        messageService.addMessage(`Tag '${tag.keyFriendlyName}' was created!`);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to create tag! ` + error.messageText });
        onComplete();
      },
      complete: () => onComplete()
    });
  }

  const updateTag = (tag: Tag, onComplete: () => void) => {
    tagService.updateTag(tag).subscribe({
      next: () => {
        uploadData();
        messageService.addMessage(`Tag '${tag.keyFriendlyName}' was updated!`);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to update tag! ` + error.messageText });
        onComplete();
      },
      complete: () => onComplete()
    });
  }

  const submitForm = (values: TagFormValues, onComplete: () => void, createBool: boolean) => {
    const { keyFriendlyName, tagColor, siteId } = values;
    const keyName = keyFriendlyName.split(' ').join('_').toLocaleLowerCase();
    const tagSiteId = siteId ? parseInt(siteId) : sites && sites[0].id;
    if (createBool) {
      //TODO: fix hardcode variables
      const jsonType = 'string';
      const tagCustomerId = customerId;
      addTag({ keyName, keyFriendlyName, customerId: tagCustomerId, jsonType, tagColor, siteId: tagSiteId }, onComplete);
    } else if (editTag) {
      updateTag({ ...editTag, keyName, keyFriendlyName, tagColor, siteId: tagSiteId }, onComplete);
    };
  };

  const handleEditClick = (item: Tag) => {
    setPopup({ isOpen: true, type: "edit" })
    setEditTag(item);
  };

  const addSubmit = (values: TagFormValues) => submitForm(values, closePopup, true);
  const editSubmit = (values: TagFormValues) => submitForm(values, closePopup, false);

  const submitFilter = (data: FilterValue) => {
    setTags(null);
    setFilterStatus(true);
    setSavedFilter(data);
    const { search } = data;
    const activeField = activeFilterField(data);

    let filterData = {
      customerId: customerId,
      search: search.length > 0 ? search : null,
      siteId: sites?.map((item) => item.id) ?? null,
      queryParameters: Object.keys(activeField).length > 0 ? activeField : null,
      tagFilterJson: null,
    };
    tagService.getFilteredData(filterData).subscribe(setTags);
  };

  const clearFilterState = () => {
    setFilterStatus(false);
    setSavedFilter(null);
  };

  return (
    <>
      <BreadCrumbs
        currentPage={"Tag List"}
        currentItem={null}
        buttonName={"Tag"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
        filterPanel={<FilterPanel searchFunc={submitFilter} filterData={filterData} defaultDataFunc={clearFilterState} />}
      />
      <div className="container ">
        {
          !tags ? <ProgressBar />
            : tags.length > 0 ? <div className="custom-width-block">
              <StaticTable
                  className={'tag-table'}
                  friendlyTableHeadNames={tableTagNames}
                  data={tags}
                  childrenRoute={""}
                  editItem={handleEditClick}
                  deleteItem={deleteTag}
              />
              <div className="empty-block">
                <img src={empty} alt="empty" />
              </div>
            </div> : <div className="empty-text">No matching records were found</div>
        }
      </div>
      {popup.isOpen &&
        <CustomPopup title={"Tag"} titlePrefix={popup.type} closeFunc={closePopup}>
          {(popup.type === "create")
            ? <TagForm
              closeFunc={closePopup}
              initialValues={{ keyFriendlyName: '', tagColor: '', siteId: sites && sites.length === 1 ? sites[0].id.toString() : "", }}
              submitForm={addSubmit}
              sites={sites ?? []}
            />
            : <TagForm closeFunc={closePopup} initialValues={editTag} submitForm={editSubmit} isEdit={true} sites={sites ?? []} />
          }
        </CustomPopup>
      }
    </>
  )
}
