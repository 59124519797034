import React, { FunctionComponent, memo, useState, FocusEvent } from "react";
import { SelectOption } from "../select.interface"
import CustomCheckbox from "../../CustomCheckbox"

interface Props {
    name: string;
    values: string[];
    options: SelectOption[],
    placeholder?: string;
    className?: string;
    selectedClassName?: string;
    onChange: (name: string, values: string[]) => void;
    onBlur?: (event: FocusEvent<HTMLElement>) => void;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    errorMessage?: string;
    getColor?: (value: string) => string | undefined;
    withCheckbox?: boolean;
    closeOptions?: boolean;
}

const CustomMultiSelect: FunctionComponent<Props> = memo(({
    name, values, options, placeholder = "Value", onChange, onBlur, selectedClassName,
    className, disabled, required, error, errorMessage, getColor, withCheckbox, closeOptions = true }) => {

    const [openOptions, setOpenOptions] = useState(false);
    const closeOpenOptions = () => setOpenOptions(false);

    const handleBlur = (event: any) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            closeOpenOptions();
        }
    }

    const togleOpenOptions = (event: any) => {
        if (event.target.classList.contains('custom-select') || event.target.classList.contains('selected-values')) {
            setOpenOptions(!openOptions)
        }
        else {
            openOptions ? setOpenOptions(!closeOptions) : setOpenOptions(true)
        }
    }

    const handleChange = (event: any, value: string) => {
        event.stopPropagation();
        let result;
        if (!values.includes(value)) {
            result = [...values, value];
        }
        else {
            result = values.filter(item => item !== value);
        }
        closeOptions && closeOpenOptions();
        onChange(name, result);
    }

    const buildSelectedValues = (values: string[]) => {
        return (
            <>
                {!withCheckbox
                    ? values.map((value, index) =>
                        <div className={`selected-value ${selectedClassName ? selectedClassName : ''}`}
                            style={getColor ? { background: getColor(value) } : {}} key={index}>
                            <div className="selected-value__value">{options.find(option => option.value === value)?.label}</div>
                            <div className="selected-value__close" onClick={(e) => handleChange(e, value)}></div>
                        </div>
                    )
                    : <>
                        <span className="selected-values_value">
                            <span className="selected-values_title"> {placeholder}: </span>
                            {values.map((value) => options.find(option => option.value === value)?.label).join(", ")}
                        </span>
                    </>
                }
            </>
        )
    }

    const buildSelectOptions = (options: SelectOption[]) => {
        return options.length > 0
            ?
            !withCheckbox
                ? options.map((option, index) => {
                    const isSelected = values.includes(option.value);
                    return (
                        !isSelected && <div className="custom-select__option" onClick={(e) => handleChange(e, option.value)} key={index}>
                            <span>{option.label}</span>
                        </div>
                    )
                })
                : <div className="custom-select__options-block">
                    {options.map((option: any, index: number) => {
                        const isSelected = values.includes(option.value);
                        return (
                            <div className="custom-select__option" key={index} >
                                <CustomCheckbox label={option.label} key={index} value={option.value} name={option.value} checked={isSelected}
                                    onChange={(e) => handleChange(e, option.value)} />
                            </div>
                        )
                    })}
                </div>
            : <div className="custom-select__option">Nothing to show</div>
    }

    return (
        <div className={`custom-select-block ${disabled ? "disabled" : ""}`} tabIndex={0} onBlur={onBlur}>
            <div className={`custom-select multi-select ${withCheckbox ? "multi-select-with-checkbox" : ""} 
                            ${values.length > 0 ? "multi-select_active" : ""}
                            ${className ? className : ""} ${error ? 'error' : ""}
                            ${openOptions ? "multi-select_opened" : ""} `} tabIndex={0}
                onClick={(e) => { togleOpenOptions(e); }}
                onBlur={handleBlur}
            >
                {values.length > 0 ?
                    <div className="selected-values">
                        {buildSelectedValues(values)}
                    </div>
                    : <span>{placeholder}</span>
                }
                {openOptions &&
                    <div className="custom-select__options">
                        {buildSelectOptions(options)}
                    </div>}
            </div>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
})

export default CustomMultiSelect;