import React from "react";
import { useNavigate } from 'react-router-dom';
import useUserInfo from "../../../../hooks/useUserInfo";
import { settingsService, messageService } from "../../../../services";
import { Settings as SettingsInterface } from "../../../../interfaces/settings.interface";
import { SettingsFormValues } from "./interface/settings-form.interface";
import CustomPopup from "../../CustomPopup";
import SettingsForm from "./SettingsForm";
import { defaultUserSettingsConstant } from "../../../../constants/default-user-settings.constant";


export default function Setting() {
    const navigate = useNavigate();
    const user = useUserInfo();

    const initialValues = {
        generalParameters: [],
        stateParameters: []
    }

    const closeFunction = () => navigate('/');

    const addSettings = (settings: SettingsInterface) => {
        settingsService.addSettings(settings).subscribe(
            () => {
                messageService.addMessage('Successful add settings');
                closeFunction();
            },
        )
    };

    const submitForm = (values: SettingsFormValues) => {
        let settings: SettingsInterface = {
            ...defaultUserSettingsConstant,
            userId: user.id,
            deviceTableToDisplay: values.generalParameters,
            deviceColumnsToDisplay: values.stateParameters
        }
        addSettings(settings);
    };

    return (
        <CustomPopup title="Settings"
            comment="Please, choose which parameters of the device you want to see within the application."
            closeFunc={closeFunction}
        >
            <SettingsForm closeFunc={closeFunction} initialValues={initialValues} submitForm={submitForm} />
        </CustomPopup>
    )
}
