import React, { FunctionComponent, memo } from 'react';

interface Props {
    title: string;
    classes?: string;
    position?: string;
    withArrow?: boolean,
    children?: any
}

const CustomTooltip: FunctionComponent<Props> = memo(({ title, classes, position="top", withArrow=true, children }) => {

    return (
        <div className={`tooltip ${classes ? classes : ""} ${`tooltip-${position}`} ${withArrow ? 'tooltip-arrow' : ""}` }>
            <div className="tooltiptext">{title}</div>
            {children}
        </div>
    )
})

export default CustomTooltip;