import { useEffect, useState } from "react";
import { messageService } from "../../services";
import { debounceTime, switchMap, tap } from "rxjs/operators";
import { timer } from "rxjs";


const useMessage = () => {
  const [message, setMessage]: [any, (arg: any) => void] = useState(null);

  useEffect(() => {
    const sub = messageService.store.pipe(
      debounceTime(100),
      tap(setMessage),
      switchMap(() => timer(3000).pipe(
        tap(() => setMessage(null))
      ))
    ).subscribe()

    return () => sub.unsubscribe();
  }, []);

  return [message, setMessage];
};

export default useMessage;