import React, {FunctionComponent, memo} from "react";
import {Link} from "react-router-dom";
import CustomPopup from "../CustomPopup";
import {IndoorMap} from "../../../interfaces/indoor-map.interface";

interface Props {
  maps: IndoorMap[] | null;
  selectedItem: any;
  setSelectedItem: (item: any) => void;
  closePopup: () => void;
  submitFunc: () => void;
  isSubmitting: boolean;
}


const AssignMapPopup: FunctionComponent<Props> = memo(({maps, selectedItem, setSelectedItem, closePopup, submitFunc, isSubmitting}) => {
  const renderForm = () => {
    return (
      <>
        <div className="popup-form_map-list">
          { maps && maps.map((item, index) => {
            return (
              <p
                className={`popup-form_map-list_item ${selectedItem?.id === item.id ? 'active' : ''}`}
                onClick={() => setSelectedItem(item)}
                key={index}
              >
                {item.name}
              </p>
            )
          })}
        </div>
        <div className="form-btn">
          <button type="submit" disabled={!selectedItem || isSubmitting} className="btn save-btn" onClick={submitFunc}>Save</button>
          <button type="button" className="btn cancel-btn" onClick={closePopup}>Cancel</button>
        </div>
      </>
    )
  };
  const renderEmptyForm = () => {
    return (
      <div className="popup-form popup-form_empty">
        <p>No maps have been created yet. Please add new indoor map <Link to={"/maps"} className="link">here</Link></p>
      </div>
    )
  }
  return (
    <CustomPopup title={"Indoor maps in this site"} titlePrefix={""} closeFunc={closePopup}>
      {
        maps && maps.length > 0 ? renderForm() : renderEmptyForm()
      }
    </CustomPopup>
  )
});

export default AssignMapPopup;