import React, {FunctionComponent, memo, useEffect} from 'react';

interface Props {
  title: string;
  titlePrefix?: string;
  comment?: string;
  classes?: string;
  closeFunc: () => void,
  withCloseBtn?: boolean;
  children?: any
}

const CustomPopup: FunctionComponent<Props> = memo(({
  title, titlePrefix, comment, classes, withCloseBtn=true, closeFunc, children }) => {

  useEffect(() => {
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    }
  }, []);

  return (
    <div className='popup'>
      <div className={`popup-body ${classes ? classes : ""}`}>
        {(title === 'Room' || title === 'Map') && (titlePrefix === 'delete' || titlePrefix === 'remove') ?
          <div className="popup-body_title center">
            <p>{`${titlePrefix ? titlePrefix : ""} ${title}`}</p>
          </div>
        : <div className="popup-body_title">
            <p>{`${titlePrefix ? titlePrefix : ""} ${title}`}</p>
            {withCloseBtn && <button className="btn close-btn" onClick={closeFunc}/>}
          </div>}
        {comment && <p className="popup-body_comment">{comment}</p>}
        {children}
      </div>
    </div>
  )
})

export default CustomPopup;
