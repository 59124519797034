import {useEffect, useState} from "react";
import { deviceService } from "../../services";

const useGetFreeDevices = () => {
  const [state, setState] = useState(null);
  useEffect(() => {
    const sub = deviceService.getFreeDevices().subscribe(setState);

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useGetFreeDevices;