import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../../BreadCrumbs";
import CustomPopup from "../../../CustomPopup";
import CustomTable from "../../../CustomTable";
import AssetForm from "../AssetForm";
import useSettingsStore from "../../../../../hooks/useSettingsStore";
import useAssetListPoll from "../../../../../hooks/useAssetListPoll";
import useAssetList from "../../../../../hooks/useAssetList";
import { Asset, AssetFilterData, NewAsset } from "../../../../../interfaces/asset.interface";
import { keyListToTags, getKeyList } from "../../../../../utils/tags";
import { displayTags } from "../../../displayFunctions";
import {
  deviceParameters,
  tableDeviceNames
} from "../../../../../constants/table-names.constant";
import useTags from "../../../../../hooks/useTags";
import useSettings from "../../../../../hooks/useSettings";
import { AssetFormValues } from "../interfaces/asset-form.interface";
import { assetService } from "../../../../../services";
import AssetDeleteForm from "../AssetDeleteForm";
import { addAsset, editAsset, deleteAssetFlow } from "../assets";
import { makeSelectOptions } from "../../../CustomSelect";
import FilterPanel from "../../../FilterPanel";
import Progress from "../../../ProgressBar";
import { FilterValue } from "../../../../../interfaces/device.interface";
import { activeFilterField, tagFilterObject } from "../../../../../helpers/filterHelper";
import useUserInfo from "../../../../../hooks/useUserInfo";

export default function AssetList() {
  const [filterStatus, setFilterStatus] = useState<boolean>( false);

  useSettings();
  useAssetListPoll(filterStatus);

  const allTags = useTags() || [];
  const assetsList = useAssetList();
  const { sites, customerId } = useUserInfo();
  const settings = useSettingsStore();
  // const sites = useSitesByCustomer(1);
  const [filterParams, setFilterParams] = useState<AssetFilterData | null>(null);
  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [assets, setAssets] = useState<Asset[] | null>(null);
  const [currentAsset, setCurrentAsset] = useState<Asset|null>(null);
  const [filterData, setFilterData] = useState<any>([]);
  const [savedFilter, setSavedFilter] = useState<FilterValue | null>(null);
  const initialValues = {
    customerId: customerId ?? 3,
    siteId: sites && sites.length === 1 ? sites[0].id.toString() : '',
    name: "",
    devices: [],
    tags: [],
  };

  useEffect(() => {
    const sub = assetService.getFiltersParams().subscribe(setFilterParams);

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    uploadFilterData();
    // eslint-disable-next-line
  }, [filterParams]);

  const uploadFilterData = () => {
    const filterData = [
      {
        name: "siteName",
        friendlyName: "Sites",
        options: filterParams?.sites && filterParams.sites.length > 1 ? makeSelectOptions(filterParams.sites, "name", "name") : []
      },
      {
        name: "tags",
        friendlyName: "Tags",
        options: filterParams?.tags && filterParams?.tags.length > 0 ? makeSelectOptions(filterParams.tags, "keyName", "keyFriendlyName") : []
      },
    ];
    setFilterData(filterData);
  };

  const closePopup = () => setPopup({ ...popup, isOpen: false });

  useEffect(() => {
    const newData = assetsList && transformationData(assetsList);
    setAssets(newData);
  }, [assetsList]);

  const transformationData = (data: any[]) => {
    return data.reduce((newArray: any, item: any) => {
      if (item.deviceDtos.length > 0) {
        item.deviceDtos.map((deviceItem: any, index: number) => {
          return newArray.push({...item, payload: deviceItem.payload, deviceName: deviceItem.name})
        })
      } else {
        newArray.push(item)
      }
      return newArray
    }, []);
  };

  const displayFunc = (key: string, value: any, measurement: string | null) => {
    switch (key) {
      case "tags":
        return displayTags(value, allTags);
      default:
        return measurement ? `${value} ${measurement}` : value;
    }
  };

  const renderPopups = (key: string) => {
    const assignedDevice = currentAsset?.deviceDtos?.map(item => item.name) ?? [];
    const currentAssetDevices = currentAsset?.deviceDtos;
    switch (popup.type) {
      case ("update"):
        return <AssetForm closeFunc={closePopup} initialValues={getEditInitialValue(currentAsset)} submitForm={editSubmit}
                          isEdit={true} tags={allTags} sites={sites ?? []} currentDevices={currentAssetDevices} />
      case ("delete"):
        return <AssetDeleteForm
          deviceList={assignedDevice}
          roomName={currentAsset?.name ?? ''}
          submitFunc={deleteSubmit}
          closeFunc={closePopup} />
      default:
        return <AssetForm closeFunc={closePopup} initialValues={initialValues} submitForm={addSubmit} tags={allTags} sites={sites ?? []} />
    }
  }

  const submitForm = (values: AssetFormValues, onComplete: () => void, createBool: boolean) => {
    const site = parseInt(values.siteId);
    const tags = keyListToTags(allTags, values.tags);
    if (createBool) {
      let asset: NewAsset = {
        name: values.name,
        customerId: customerId ?? 3,
        siteId: site,
        tags: tags
      };
      addAsset(asset, onComplete, values.devices);
    } else if (currentAsset) {
      let asset = { ...currentAsset, name: values.name, siteId: site, tags: tags };
      editAsset(asset, onComplete, values.devices);
    }
  };

  const onCompleteSubmit = async () => {
    setTimeout(() => {
      if (filterStatus) {
        savedFilter && submitFilter(savedFilter);
        closePopup();
      } else {
        assetService.getAllAssets({ sortingParameter: 'id', sortingType: 'DESC' }).subscribe();
        closePopup();
      }
    }, 1000);
  }

  const addSubmit = (values: AssetFormValues) => submitForm(values, onCompleteSubmit, true);
  const editSubmit = (values: AssetFormValues) => submitForm(values, onCompleteSubmit, false);
  const deleteSubmit = () => deleteAssetFlow(currentAsset?.id, currentAsset?.name ?? '', currentAsset?.deviceDtos ?? [], onCompleteSubmit);

  const openDeleteModal = (asset: Asset) => {
    setCurrentAsset(asset);
    setPopup({ type: "delete", isOpen: true });
  };

  const openEditModal = (asset: Asset) => {
    setCurrentAsset(asset);
    setPopup({ type: "update", isOpen: true });
  };

  const getEditInitialValue = (item: Asset|null): AssetFormValues => {
    if (item) {
      const tagsKeyList = getKeyList(item.tags);
      const site = item.siteId?.toString() || "";
      const assignedDevice = item?.deviceDtos?.map(item => item.deviceEUI);
      return { ...item, tags: tagsKeyList, devices: assignedDevice, siteId: site }
    }
    else return initialValues;
  }

  const submitFilter = (data: FilterValue) => {
    setFilterStatus(true);
    setSavedFilter(data);
    assetService.updateAssetListStore(null);
    const { search, tags } = data;
    const activeField = activeFilterField(data);

    let filterData = {
      customerId: customerId,
      search: search.length > 0 ? search : null,
      siteId: sites?.map((item) => item.id) ?? null,
      queryParameters: Object.keys(activeField).length > 0 ? activeField : null,
      tagFilterJson: tags?.length > 0 ? tagFilterObject(tags) : null,
    };
    assetService.getFilteredData(filterData).subscribe({
      error: (error) => {
        console.log(error)
      },
    });
  };

  const clearFilterState = () => {
    setFilterStatus(false);
    setSavedFilter(null);
  };

  const { assetTableToDisplay, assetColumnsToDisplay } = settings;

  return (
    <>
      <BreadCrumbs
        currentPage={"List of rooms"}
        currentItem={null}
        buttonName={"room"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
        filterPanel={<FilterPanel searchFunc={submitFilter} filterData={filterData} defaultDataFunc={clearFilterState} />}
      />
      <div className="container">
          { !assets ? <Progress /> : assets.length > 0 ? <CustomTable
            pageType={'asset'}
            staticTableHeads={[...assetTableToDisplay, ...assetColumnsToDisplay]}
            data={assets}
            dynamicColumnsSrc={"payload"}
            displayFunc={displayFunc}
            editItem={openEditModal}
            deleteItem={openDeleteModal}
            filterParameters={deviceParameters}
            friendlyNames={tableDeviceNames}
            settings={settings}
            reloadData={onCompleteSubmit}
          /> : <div className="empty-text">No matching records were found</div>
        }
      </div>
      {popup.isOpen &&
        <CustomPopup title={"Room"} titlePrefix={popup.type} closeFunc={closePopup}>
          { renderPopups(popup.type) }
        </CustomPopup>}
    </>
  )
}