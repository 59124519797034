import React, { FunctionComponent, memo } from "react";
import { FormikProps } from "formik";
import { object as yupObject, array as yupArray } from "yup";
import CustomForm from '../CustomForm'
import { CustomMultiSelect } from '../CustomSelect'
import { FilterFormInterface } from "../../../interfaces/form.interface"
import { FilterFormValues } from "./interfaces/filter-form.interface"

const validationSchema = () => yupObject().shape({
  filterItems: yupArray()
    .min(1)
});

const FilterForm: FunctionComponent<FilterFormInterface> = memo(({
                                                                   filterItems,
                                                                   initialValues,
                                                                   resetData,
                                                                   search,
                                                                   filterCount,
                                                                   ...props }) => {

  const renderForm = ({
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
  }: FormikProps<FilterFormValues>) => {
    const onChange = (name: string, values: string[]) => {
      setFieldValue(name, values);
      handleSubmit();
    };
    const clearForm = async () => {
      await resetData();
      let resetObjet: any = {}
      Object.keys(initialValues).forEach((item) => {
        resetObjet[item] = []
      });
      resetForm({ values: resetObjet });
    };

    return (

      <form className="filter-panel" onSubmit={handleSubmit} >
        <div className="filter-panel_content">
          <div className="filters">
            {filterItems.map((filterItem, index) => {
              return <CustomMultiSelect key={index}
                                        name={filterItem.name}
                                        placeholder={filterItem.friendlyName}
                                        options={filterItem.options}
                                        values={values[filterItem.name] || []}
                                        onChange={onChange}
                                        className="filter-select"
                                        withCheckbox={true}
                                        closeOptions={false}
              />
            })}
          </div>
        <button className="btn table-btn clear-filter-btn" onClick={clearForm}>Clear</button>
        </div>
      </form>

    )
  };
  return <CustomForm renderForm={renderForm} validationSchema={validationSchema} initialValues={initialValues} {...props} />
});

export default FilterForm;