import React, {FunctionComponent, useEffect, useState} from "react";
import { Device } from "../../../../interfaces/device.interface";
import { Asset } from "../../../../interfaces/asset.interface";
import {indoorMapService} from "../../../../services";

interface Props {
  mapName: string | null;
  mapId: number;
  assignDevices: Device[] | [];
  assignRooms: Asset[] | [];
  closeFunc: () => void;
  deleteMap: () => void;
}

const IndoorMapDeleteForm: FunctionComponent<Props> = ({
                                                         mapName,
                                                         mapId,
                                                         assignDevices,
                                                         assignRooms,
                                                         closeFunc,
                                                         deleteMap
}) => {
  const [step, setStep] = useState<string>("first");
  const [unpair, setUnpair] = useState<boolean>(false);

  useEffect(() => {
    if (assignDevices.length > 0 || assignRooms.length > 0) {
      setStep('first');
    } else {
      setStep('second');
    }
  }, [assignDevices, assignRooms]);

  const unpairField = () => {
    indoorMapService.unpairFields(mapId).subscribe({
      next: () => setUnpair(true),
      error: (error) => setUnpair(false),
    })
  };

  const renderFirstStep = () => {
    return (
      <div className="delete-popup_assigned-devices">
        <p>You want to delete the {mapName} that has {assignRooms.length > 0 ? assignRooms.length : 0} rooms assigned and {assignDevices.length > 0 ? assignDevices.length : 0} devices located on it. You should remove rooms and devices before proceeding with deletion.</p>
        {
          assignRooms.length > 0 ?
            <div className={`devices-list ${unpair ? "disabled" : ''}`}>
              <p>Rooms on this map:</p>
              {
                assignRooms.map((item: Asset, index: number) => {
                  return (
                    <p key={index} className="assigned-device">{item.name}</p>
                  )
                })
              }
            </div> : null
        }
        {
          assignDevices.length > 0 ?
            <div className={`devices-list ${unpair ? "disabled" : ''}`}>
              <p>Devices on this map:</p>
              {
                assignDevices.map((item: Device, index: number) => {
                  return (
                    <p key={index} className="assigned-device">{item.name}</p>
                  )
                })
              }
            </div> : null
        }
        <div className="form-btn">
          {
            !unpair ?
            <button type="submit" className="btn delete-red-btn" onClick={unpairField}>unassign all</button>
            : <p className='green-text'>All rooms and devices are removed from this map</p>
          }
        </div>
        <div className="form-btn">
          <button type="submit" disabled={!unpair} className="btn next-blue-btn" onClick={() => setStep("second")}>next</button>
          <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button>
        </div>
      </div>
    )
  };

  const renderSecondStep = () => {
    return (
      <div className="delete-popup_assigned-devices center">
        <p>All paired rooms and devices are unassigned.</p>
        <p>Are you sure you want to delete the map?</p>
        <div className="form-btn">
          <button type="submit" className="btn delete-red-btn" onClick={deleteMap}>delete</button>
          <button type="button" className="btn cancel-btn" onClick={closeFunc} >Cancel</button>
        </div>
      </div>
    )
  }

  return (
    <div className="delete-popup">
      {
        step === "first" ? renderFirstStep() : renderSecondStep()
      }
    </div>
  )
}

export default IndoorMapDeleteForm;