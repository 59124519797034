import React, { FunctionComponent, memo, useEffect, useState } from "react";
import BreadCrumbs from "../../../BreadCrumbs";
import StaticTable from "../../../StaticTable";
import empty from "../../../../../style/assets/img/empty.png"
import IndoorMapForm from "../IndoorMapForm"
import CustomPopup from "../../../CustomPopup";
import { IndoorMap } from "../../../../../interfaces/indoor-map.interface";
import { errorService, indoorMapService, messageService } from "../../../../../services";
// import useSitesByCustomer from "../../../../../hooks/useSitesByCustomer";
import { MapFormValues } from "../interfaces/map-form.interface";
import IndoorMapDeleteForm from "../IndoorMapDeleteForm";
import FilterPanel from "../../../FilterPanel";
import { makeSelectOptions } from "../../../CustomSelect";
import Progress from "../../../ProgressBar";
import useUserInfo from "../../../../../hooks/useUserInfo";
import { FilterValue } from "../../../../../interfaces/device.interface";
import { activeFilterField } from "../../../../../helpers/filterHelper";
import {generateLayoutForGraph} from "../../../../../helpers/graphFunctions";
import {Device} from "../../../../../interfaces/device.interface";
import Plot from "react-plotly.js";
import {renderCurrentImgForPin} from "../../../../../helpers/imagePin";
import graphLegend from "../../../GraphLegend";

const friendlyNamesForTableHead = {
  name: "Name",
  siteName: "Site name"
}

interface Pin {
  x: number[];
  y: number[]
}

const IndoorMapList: FunctionComponent = memo( () => {
  const [mapList, setMapList] = useState<IndoorMap[] | null>(null);
  const [filterParams, setFilterParams] = useState<any>(null);
  const [currentMap, setCurrentMap] = useState<IndoorMap|null>(null);
  const [clickedMap, setClickedMap] = useState<IndoorMap|null>(null);
  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [layout, setLayout] = useState<any>({});
  const [pin, SetPin] = useState<Pin>({x: [], y: []});
  const [filterData, setFilterData] = useState<any>([]);
  const [savedFilter, setSavedFilter] = useState<FilterValue | null>(null);
  const [filterStatus, setFilterStatus] = useState<boolean>( false);
  // eslint-disable-next-line
  const [config, setConfig] = useState<any>({displayModeBar: false});
  const [showGraph, setShowGraph] = useState<boolean>(false);
  const { sites, customerId } = useUserInfo();

  const uploadMapListData = () => indoorMapService.getAllMaps({ sortingParameter: 'id', sortingType: 'DESC' }).subscribe(setMapList);
  const uploadFilterParams = () => indoorMapService.getFiltersParams().subscribe(setFilterParams);

  useEffect(() => {
    uploadMapListData();
    uploadFilterParams();
  }, []);

  useEffect(() => {
    uploadFilterData();
    // eslint-disable-next-line
  }, [filterParams]);

  const uploadFilterData = () => {
    const filterData = [
      {
        name: "siteName",
        friendlyName: "Sites",
        options: filterParams?.sites && filterParams.sites.length > 1 ? makeSelectOptions(filterParams.sites, "name", "name") : []
      },
    ];
    setFilterData(filterData);
  };

  useEffect(() => {
    if (clickedMap?.deviceDtos && clickedMap?.deviceDtos?.length > 0) {
      const layoutData = generateLayoutForGraph(
        {width: 1100, height: 800},
        {xLine: clickedMap?.graphValue.x ?? 0, yLine: clickedMap?.graphValue.y ?? 0},
        clickedMap?.imageUrl ?? empty);
      let xAxis: number[] = [],
        yAxis: number[] = [],
        images: any = [
          ...layoutData.images,
        ];
      clickedMap?.deviceDtos?.forEach((item: Device) => {
        if (item.position?.x > 0 && item.position?.y > 0) {
          xAxis.push(item.position.x);
          yAxis.push(item.position.y);
          images.push({
            source: renderCurrentImgForPin(item.status),
            xref: "x",
            yref: "y",
            x: item.position.x,
            y: item.position.y,
            sizex: 80,
            sizey: 80,
            xanchor: "center",
            yanchor: "middle",
            layer: "above",
          })
        }
      });
      const newLayout = { ...layoutData, images: [...images]}
      SetPin({x: xAxis, y: yAxis});
      setLayout(newLayout);
    }
    setShowGraph(true);
  }, [clickedMap]);

  const initialValuesFunc = () => {
    if (popup.type === 'create') {
      return {
        customerId: customerId ?? 3,
        siteId: sites && sites.length === 1 ? sites[0].id.toString() : "",
        name: "",
        file: null,
      }
    } else {
      return {
        customerId: currentMap?.customerId,
        siteId: currentMap?.siteId,
        name: currentMap?.name,
        file: {
          name: currentMap?.fileName,
        },
      }
    }
  };

  const showClickedMap = (map: IndoorMap) => {
    setClickedMap(map);
    setShowGraph(false);
  }

  const openEditModal = (map: IndoorMap) => {
    setClickedMap(null);
    setCurrentMap(map);
    setPopup({ type: "update", isOpen: true });
  };

  const addSubmit = (values: MapFormValues) => submitForm(values, completeFunc, true);
  const editSubmit = (values: MapFormValues) => submitForm(values, completeFunc, false);

  const completeFunc = () => {
    setTimeout(() => {
      if (filterStatus) {
        savedFilter && submitFilter(savedFilter);
      } else {
        uploadMapListData();
      }
      closePopup();
    }, 1000);
  };

  const submitForm = (values: MapFormValues, onComplete: () => void, createBool: boolean) => {
    const data = {
      customerId: values.customerId,
      siteId: +values.siteId,
      name: values.name,
    }
    let formData = new FormData();
    formData.append('file', values.file);
    if (createBool) {
      indoorMapService.createMap(data).subscribe({
        next: (newMap: any) => {
          indoorMapService.uploadFile(newMap.id, formData).subscribe({
            error: (error) => {
              errorService.addError({ message: `Failed to save img! ` + error.messageText });
              closePopup();
            },
            complete: () => {
              messageService.addMessage(`${newMap.name} has been successfully added`);
              onComplete();
            }
          });
        },
        error: (error) => {
          errorService.addError({ message: `Failed to create map! ` + error.messageText });
          closePopup();
        }
      })
    } else {
      const updatedMap = {
        ...currentMap,
        ...data,
        fileName: values.file.type ? null : currentMap?.fileName,
        imageUrl: values.file.type ? null : currentMap?.imageUrl,
      };
      indoorMapService.updateMap(updatedMap).subscribe({
        next: (currentMap: any) => {
          values.file.type ? indoorMapService.uploadFile(currentMap.id, formData).subscribe({
            error: (error) => {
              errorService.addError({ message: `Failed to save img! ` + error.messageText });
              closePopup();
            },
            complete: () => {
              messageService.addMessage(`${currentMap.name} has been successfully updated`);
              onComplete();
            }
          }) :
            messageService.addMessage(`${currentMap.name} has been successfully updated`);
            onComplete();
        },
        error: (error) => {
          errorService.addError({ message: `Failed to update map! ` + error.messageText });
          closePopup();
        }
      })
    }
  };

  const closePopup = () => setPopup({ ...popup, isOpen: false });

  const deleteMap = (id: number, name: string) => {
    indoorMapService.deleteMapFlow(id).subscribe({
      next: () => {
        messageService.addMessage(`Map ${name} was removed successfully`);
        id === clickedMap?.id && setClickedMap(null);
        completeFunc();
      },
      error: (error) => {
        errorService.addError({ message: `Failed to remove map! ` + error.messageText });
        closePopup();
      }
    })
  };

  const openDeleteModal = (map: IndoorMap) => {
    setCurrentMap(map);
    setPopup({ type: "delete", isOpen: true });
  };

  const deleteMapFlow = () => {
    if (currentMap?.id && currentMap?.name) {
      currentMap?.imageUrl ? deleteMap(currentMap?.id, currentMap?.name ?? '-') : indoorMapService.deleteMap(currentMap?.id).subscribe({
        next: () => {
          messageService.addMessage(`Map ${currentMap?.name} was removed successfully`);
          completeFunc();
          currentMap?.id === clickedMap?.id && setClickedMap(null);
        },
        error: (error) => {
          errorService.addError({ message: `Failed to remove map! ` + error.messageText });
          closePopup();
        }
      });
    }
  };

  const renderPopups = () => {
    switch (popup.type) {
      case ("update"):
        return <IndoorMapForm closeFunc={closePopup}  initialValues={initialValuesFunc()} submitForm={editSubmit} sites={sites ?? []} assignedDevices={currentMap?.deviceDtos ?? undefined} />
      case ("delete"):
        return <IndoorMapDeleteForm
          mapName={currentMap?.name ?? null}
          mapId={currentMap?.id ?? 0}
          assignDevices={currentMap?.deviceDtos ?? []}
          assignRooms={currentMap?.assetDtos ?? []}
          deleteMap={ deleteMapFlow }
          closeFunc={ closePopup }  />
      default:
        return <IndoorMapForm closeFunc={closePopup}  initialValues={initialValuesFunc()} submitForm={addSubmit} sites={sites ?? []} />
    }
  };

  const renderCurrentMap = () => {
    return (
      <div className="map-img-block">
        <p>{clickedMap?.name}</p>
        {
          clickedMap?.deviceDtos && clickedMap?.deviceDtos?.length > 0 ? renderMap()
            : <img src={clickedMap?.imageUrl ? clickedMap?.imageUrl : empty} alt="map"/>
        }
      </div>
    )
  };

  const renderMap = () => {
    const {x, y} = pin;
    return (
      <div>
        <Plot
          divId="myPlot"
          data={[
            {
              x: x,
              y: y,
              type: 'scatter',
              mode: 'markers',
              marker: {
                color: 'transparent',
                size: 20,
              },
              name: '',
              hovertemplate: clickedMap?.deviceDtos?.filter((item: Device) => item?.position?.x > 0 && item?.position?.y > 0)
                .map((item: Device) => {
                  let payload = item.payload && JSON.parse(item.payload);
                  if (payload) {
                    return `<br><b>${item.name}</b><br>` +
                      `<br>CO2: ${payload?.co2_pressure_compensated ?? '-'} ppm<br>` +
                      `Battery: ${payload?.rem_batt_capacity_sensor ?? '-'} %<br>`
                  } else {
                    return `<br><b>${item.name}</b><br>`
                  }
                }) ?? [],
              hoverlabel: {
                bgcolor: '#F4F6FA',
                bordercolor: 'transparent',
                font: {
                  size: 12,
                  color: '#42454E',
                }
              }
            }
          ]}
          layout={ layout }
          config={ config }
        />
        { (x.length > 0 && y.length > 0) && graphLegend() }
      </div>
    )
  };

  const renderEmptyImg = () => {
    return (
      <div className="empty-block">
        <img src={empty} alt="empty-map"/>
      </div>
    )
  };

  const submitFilter = (data: FilterValue) => {
    setFilterStatus(true);
    setMapList(null);
    setSavedFilter(data);
    const { search } = data;
    const activeField = activeFilterField(data);

    let filterData = {
      customerId: customerId,
      search: search.length > 0 ? search : null,
      siteId: sites?.map((item) => item.id) ?? null,
      queryParameters: Object.keys(activeField).length > 0 ? activeField : null,
      tagFilterJson: null,
    };
    indoorMapService.getFilteredData(filterData).subscribe(setMapList);
  };

  return (
    <>
      <BreadCrumbs
        currentPage={"indoor Maps"}
        currentItem={null}
        buttonName={"Map"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
        filterPanel={<FilterPanel searchFunc={submitFilter} filterData={filterData} defaultDataFunc={uploadMapListData} />}
      />
      <div className="container">
        {!mapList ? <Progress /> : mapList.length > 0 ? <div className="custom-width-block сustom-map-width">
          <StaticTable
            className="indoor-map"
            friendlyTableHeadNames={friendlyNamesForTableHead}
            data={mapList}
            editItem={openEditModal}
            deleteItem={openDeleteModal}
            onClick={showClickedMap}
            activeElementId={clickedMap?.id ?? null}
          />
          {clickedMap && showGraph ? renderCurrentMap() : renderEmptyImg()}
        </div> : <div className="empty-text">No matching records were found</div>}
      </div>
      {popup.isOpen &&
      <CustomPopup title={"Map"} titlePrefix={popup.type} classes={"map-popup"} closeFunc={closePopup}>
        { renderPopups() }
      </CustomPopup>}
    </>
  )
});

export default IndoorMapList;