import React, { FunctionComponent, memo } from "react";
import { FormikProps } from "formik";
import { TagFormInterface } from "../../../../interfaces/form.interface";
import { TagFormValues } from "./interfaces/tag-form.interface";
import { object as yupObject, string as yupString } from "yup";
import { validation } from "../../../../constants/errors/errors.constant";
import CustomInput from '../../CustomInput'
import { CustomSelect, makeSelectOptions } from '../../CustomSelect'
import CustomForm from '../../CustomForm'

const validationSchema = () => yupObject().shape({
    keyFriendlyName: yupString()
        .min(3, validation.minLength(3))
        .max(100, validation.maxLength(100))
        .required(validation.defaultRequired)
        .trim(),
    tagColor: yupString()
        .required(validation.defaultRequired)
        .trim(),
    siteId: yupString()
        .required(validation.defaultRequired),
});

const TagForm: FunctionComponent<TagFormInterface> = memo(({ closeFunc, isEdit, sites, ...props }) => {

    const siteOptions = makeSelectOptions(sites, "id", "name");

    const renderForm = ({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
        isValid,
        dirty
    }: FormikProps<TagFormValues>) => {
        const onBlur = (name: string) => () => setFieldTouched(name);
        const onChangeText = (name: string, value: string) => setFieldValue(name, value);
        const colorOptions = [
            {
                value: '#B9EDE4',
                label: 'Sky-blue'
            },
            {
                value: '#FFBFBC',
                label: 'Pink'
            },
            {
                value: '#DCDCFF',
                label: 'Violet'
            },
            {
                value: '#F0F1BD',
                label: 'Yellow'
            },
            {
                value: '#FFD89E',
                label: 'Orange'
            },
        ];

        return (
            <>
                <div className="popup-form_item" >
                    <p>Tag name</p>
                    <CustomInput
                        type={"text"}
                        placeholder={"Tag name"}
                        name={"keyFriendlyName"}
                        onChange={onChangeText}
                        onBlur={onBlur('keyFriendlyName')}
                        value={values.keyFriendlyName}
                        error={Boolean(touched.keyFriendlyName && errors.keyFriendlyName)}
                        errorMessage={(touched.keyFriendlyName && errors.keyFriendlyName) ? errors.keyFriendlyName : undefined}
                        disabled={isSubmitting}
                        required={true}
                    />
                </div>
                <div className="popup-form_item" >
                    <p>Tag color</p>
                    <CustomSelect
                        name={"tagColor"}
                        placeholder={"Choose color"}
                        value={values.tagColor}
                        options={colorOptions}
                        onChange={onChangeText}
                        onBlur={onBlur('tagColor')}
                        error={Boolean(touched.tagColor && errors.tagColor)}
                        errorMessage={(touched.tagColor && errors.tagColor) ? errors.tagColor : undefined}
                        disabled={isSubmitting}
                        required={true}
                    />
                </div>
                <div className="popup-form_item" >
                    <p>Site</p>
                    <CustomSelect
                      options={siteOptions}
                      placeholder={"Site"}
                      name={"siteId"}
                      onChange={onChangeText}
                      onBlur={onBlur('siteId')}
                      value={values.siteId?.toString()}
                      error={Boolean(touched.siteId && errors.siteId)}
                      errorMessage={(touched.siteId && errors.siteId) ? errors.siteId : undefined}
                      disabled={siteOptions.length === 1 || isSubmitting}
                      required={true}
                    />
                </div>
                <div className="form-btn">
                    <button type="submit" disabled={!dirty || isSubmitting || !isValid} onClick={() => handleSubmit()} className="btn save-btn" >Save</button>
                    {/* <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button> */}
                </div>
            </>
        )
    };

    return <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />
});

export default TagForm;
