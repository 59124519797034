import React, { FunctionComponent, KeyboardEvent } from "react";
import { object as yupObject, array as yupArray } from 'yup';
import { FormikProps } from "formik";
import { FormWithCloseFunc } from "../../../../interfaces/form.interface";
import { tableDeviceNames, defaultDeviceTableGeneralNames, defaultTableStateNames } from "../../../../constants/table-names.constant"
import { SettingsFormValues } from "./interface/settings-form.interface";
import CustomForm from "../../CustomForm";
import CustomCheckbox from "../../CustomCheckbox";

const SettingsForm: FunctionComponent<FormWithCloseFunc> = ({ closeFunc, ...props }) => {

    const validationSchema = () => yupObject().shape({
        generalParameters: yupArray()
            .min(2, 'Please choose at leat 2 general parameters'),
        // stateParameters: yupArray()
    });

    const renderForm = ({
        values,
        setFieldValue,
        errors,
        isSubmitting,
        isValid,
        dirty,
        handleSubmit
    }: FormikProps<SettingsFormValues>) => {
        const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
            if (event.key === 'Enter') {
                handleSubmit();
            }
        };
        const onChangeCheckbox = (event: any) => {
            const name = event.target.name;
            const value = event.target.value;
            let parameterList = [...values[name as keyof SettingsFormValues]];
            if (event.target.checked) {
                parameterList.push(value);
            }
            else {
                const index = parameterList.indexOf(value);
                if (index > -1) parameterList.splice(index, 1);
            }
            setFieldValue(name, parameterList);
        }

        return (
            <form className="settings-popup_body" onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown} >
                <div className="setting-popup_inputs">
                    <div className="popup-group">
                        <p className="popup-group_title">General fields:</p>
                        <div className="popup-group_body">
                            {defaultDeviceTableGeneralNames.map((key, index) =>
                                <CustomCheckbox label={tableDeviceNames[key]} value={key} key={index} name="generalParameters"
                                    checked={values.generalParameters.includes(key)}
                                    onChange={onChangeCheckbox}
                                />)}
                        </div>
                        {errors.generalParameters && <div className="popup-group_error-message">{errors.generalParameters}</div>}
                    </div>
                    <div className="popup-group">
                        <p className="popup-group_title">Device fields:</p>
                        <div className="popup-group_body">
                            {defaultTableStateNames.map((key, index) =>
                                <CustomCheckbox label={tableDeviceNames[key]} value={key} key={index} name="stateParameters"
                                    checked={values.stateParameters.includes(key)}
                                    onChange={onChangeCheckbox}
                                />)}
                        </div>
                        {errors.stateParameters && <div className="popup-group_error-message">{errors.stateParameters}</div>}
                    </div>

                </div>
                <div className="form-btn">
                    <button type="submit" disabled={!dirty || isSubmitting || !isValid} className="btn save-btn" >Save</button>
                    {/* <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button> */}
                </div>
            </form>
        )
    };

    return <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />;
}

export default SettingsForm;
