import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import CustomTable from "../../../CustomTable";
import CustomPopup from "../../../CustomPopup";
import DeviceForm from "../DeviceForm";
import useDevice from "../../../../../hooks/useDevice";
import useTags from "../../../../../hooks/useTags";
import { Device, DeviceFilterData, FilterValue } from "../../../../../interfaces/device.interface";
import { deviceService } from "../../../../../services";
import { DeviceFormValues } from "../interfaces/device-form.interface"
import { deviceParameters, tableDeviceNames } from "../../../../../constants/table-names.constant"
import { keyListToTags, getKeyList } from "../../../../../utils/tags"
import useDevicePoll from "../../../../../hooks/useDevicePoll";
import useSettings from "../../../../../hooks/useSettings";
import useSettingsStore from "../../../../../hooks/useSettingsStore";
import { addDevice, updateDevice, deleteDevice } from "../devices";
import FilterPanel from "../../../FilterPanel";
import { makeSelectOptions } from "../../../CustomSelect";
import useUserInfo from "../../../../../hooks/useUserInfo";
import Progress from "../../../ProgressBar";
import { activeFilterField, tagFilterObject } from "../../../../../helpers/filterHelper";
import { displayTags, displayBatteryLevel, displayCO2Level, displayDeviceStatus } from "../../../displayFunctions";
import AssignConfiguration from "../../Configurations/AssignConfiguration";
import { canShow } from "../../../../../helpers/userHelper";
import useUserRole from "../../../../../hooks/useUserRole";

export default function DeviceAssetList() {

  const [filterStatus, setFilterStatus] = useState<boolean>(false);
  useSettings();
  useDevicePoll(filterStatus);
  const location = useLocation();
  const permission = useUserRole();

  const { sites, customerId } = useUserInfo();
  const allDevicesByCustomerId = useDevice();
  const allTags = useTags() || [];
  const settings = useSettingsStore();

  const [filterParams, setFilterParams] = useState<DeviceFilterData | null>(null);
  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [devices, setDevices] = useState<Device[] | null>(null);
  const [editDevice, setEditDevice] = useState<Device>();
  const [filterData, setFilterData] = useState<any>([]);
  const [savedFilter, setSavedFilter] = useState<FilterValue | null>(null);
  const [clickedDevices, setClickedDevices] = useState<object[]>([]);
  const [confirmPopup, setConfirmPopup] = useState<boolean>(false);

  useEffect(() => {
    setDevices(allDevicesByCustomerId);
  }, [allDevicesByCustomerId]);

  useEffect(() => {
    const sub = deviceService.getFiltersParams().subscribe(setFilterParams);

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    uploadFilterData();
    // eslint-disable-next-line
  }, [filterParams]);

  const uploadFilterData = () => {
    const filterData = [
      {
        name: "siteName",
        friendlyName: "Sites",
        options: filterParams?.sites && filterParams.sites.length > 1 ? makeSelectOptions(filterParams.sites, "name", "name") : []
      },
      {
        name: "status",
        friendlyName: "Status",
        options: (filterParams?.statuses && filterParams.statuses.length > 0) ? filterParams.statuses.map((item, index) => {
          return {
            value: item,
            label: item,
          }
        }) : []
      },
      {
        name: "deviceType",
        friendlyName: "Device Type",
        options: (filterParams?.deviceTypes && filterParams.deviceTypes.length > 0) ? filterParams.deviceTypes.map((item, index) => {
          return {
            value: item,
            label: item,
          }
        }) : []
      },
      {
        name: "tags",
        friendlyName: "Tags",
        options: filterParams?.tags && filterParams?.tags.length > 0 ? makeSelectOptions(filterParams.tags, "keyName", "keyFriendlyName") : []
      },
    ];
    setFilterData(filterData);
  };

  const initialValues = {
    customerId: customerId,
    deviceEUI: "",
    name: "",
    deviceType: "",
    siteId: sites && sites.length === 1 ? sites[0].id.toString() : "",
    tags: [],
    inactivityTimeout: 300
  };

  const closePopup = () => {
    !savedFilter && deviceService.getDeviceByCustomer({ sortingParameter: 'installDate', sortingType: 'DESC' }).subscribe({
      error: (error) => {
        console.log(error)
      }
    });
    setPopup({ ...popup, isOpen: false });
    setClickedDevices([]);
  };

  const displayFunc = (key: string, value: any, measurement: string | null) => {
    switch (key) {
      case "tags":
        return displayTags(value, allTags);
      case "status":
        return displayDeviceStatus(value);
      case "rem_batt_capacity_sensor":
      case "rem_batt_capacity_display":
        return displayBatteryLevel(+value, measurement || "%");
      case "co2_pressure_compensated":
        return displayCO2Level(+value, measurement);
      default:
        return measurement ? `${value} ${measurement}` : value;
    }
  }

  const handleEditClick = (item: Device) => {
    setPopup({ isOpen: true, type: "edit" });
    setEditDevice(item);
  }

  const handleConfigureClick = () => {
    closeConfirmPopup();
    setPopup({isOpen: true, type: "configuration"});
  };

  const getEditInitialValue = (item?: Device): DeviceFormValues => {
    if (item) {
      let tagsKeyList = getKeyList(item.tags);
      let site = item.siteId?.toString() || "";
      return { ...item, tags: tagsKeyList, siteId: site }
    }
    else return initialValues;
  }

  /*const updateData = (onComplete: () => void) => {
    deviceService.getDeviceByCustomer({ sortingParameter: 'installDate', sortingType: 'DESC' }).subscribe({
      error: (error) => {
        console.log(error)
        onComplete();
      },
      complete: () => onComplete()
    })
  };*/

  const submitForm = (values: DeviceFormValues, onComplete: () => void, createBool: boolean) => {
    let device = {
      ...values,
      deviceType: values.deviceType ? values.deviceType : 'BREEZE',
      customerId: customerId ?? 1,
      siteId: parseInt(values.siteId),
      tags: keyListToTags(allTags, values.tags)
    };
    if (createBool) {
      addDevice(device, onComplete);
    } else if (editDevice) {
      updateDevice(device, onComplete);
    }
  };

  const onCompleteSubmit = () => {
    if (filterStatus) {
      savedFilter && submitFilter(savedFilter);
      closePopup();
    } else {
      closePopup();
    }
  };

  const addSubmit = (values: DeviceFormValues) => submitForm(values, onCompleteSubmit, true);
  const editSubmit = (values: DeviceFormValues) => submitForm(values, onCompleteSubmit, false);
  const deleteSubmit = (device: Device) => deleteDevice(device, onCompleteSubmit)

  const submitFilter = (data: FilterValue) => {
    setFilterStatus(true);
    setSavedFilter(data);
    deviceService.updateDevicesStore(null);
    const { search, tags } = data;
    const activeField = activeFilterField(data);

    let filterData = {
      customerId: customerId,
      search: search.length > 0 ? search : null,
      siteId: sites?.map((item) => item.id) ?? null,
      queryParameters: Object.keys(activeField).length > 0 ? activeField : null,
      tagFilterJson: tags?.length > 0 ? tagFilterObject(tags) : null,
    };
    deviceService.getFilteredData(filterData).subscribe({
      error: (error) => {
        console.log(error)
      },
    });
  };

  const clearFilterState = () => {
    setFilterStatus(false);
    setSavedFilter(null);
  };

  const changeClickedDevices = (item: object[]) => {
    setClickedDevices(item);
    setConfirmPopup(true);
    item.length > 0 ? setFilterStatus(true) : setFilterStatus(false);
  };

  const closeConfirmPopup = () => setConfirmPopup(false);

  const { deviceColumnsToDisplay, deviceTableToDisplay } = settings;

  const renderPopup = () => {
    switch (popup.type) {
      case "create":
        return (<CustomPopup title={"Device"} titlePrefix={popup.type} closeFunc={closePopup} classes='device-popup'>
          <DeviceForm closeFunc={closePopup} initialValues={initialValues} submitForm={addSubmit} tags={allTags} sites={sites ?? []} />
        </CustomPopup>)
      case "edit":
        return (<CustomPopup title={"Device"} titlePrefix={popup.type} closeFunc={closePopup} classes='device-popup'>
          <DeviceForm closeFunc={closePopup} initialValues={getEditInitialValue(editDevice)} submitForm={editSubmit}
            isEdit={true} tags={allTags} sites={sites ?? []} />
        </CustomPopup>)
      case "configuration":
        return (<CustomPopup title={"Configure Devices"} closeFunc={closePopup} classes='configurations-popup'>
          <AssignConfiguration closeFunc={closePopup} initialValues={{ configuration: "", devices: clickedDevices }} />
        </CustomPopup>)
    }
  }

  const renderConfirmForSelectedDevice = () => {
    const deviceTypeArr = clickedDevices.map((item: any) => item.deviceType);
    const uniqueTypes = Array.from(new Set(deviceTypeArr));
    const renderTypes = () => uniqueTypes.map((item, index) => index === 0 ? item : ` and ${item}`);
    if (uniqueTypes?.length > 1) {
      return (
        <div className="confirmed-popup confirmed-popup-warning">
          <div className="confirmed-popup_body">
            <div className="info-block">
              <p className="text">You have selected devices <span className="numbers">{clickedDevices.length}</span> of different types: {renderTypes()}. Please make sure your selection is correct, otherwise applying the same configuration to different types of devices might cause unexpected behavior.</p>
            </div>
            <div className="btn-block">
              <button type='button' className="btn save-btn" onClick={handleConfigureClick}>configure</button>
              <button className="btn close-btn" onClick={closeConfirmPopup}/>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="confirmed-popup">
          <div className="confirmed-popup_body">
            <div className="info-block">
              <p className="numbers">{clickedDevices.length}</p>
              <p className="text">items selected</p>
            </div>
            <div className="btn-block">
              <button type='button' className="btn save-btn" onClick={handleConfigureClick}>configure</button>
              <button className="btn close-btn" onClick={closeConfirmPopup}/>
            </div>
          </div>
        </div>
      )
    }
  };

  return (
    <>
      <BreadCrumbs
        currentPage={"Device List"}
        currentItem={null}
        buttonName={"Device"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
        filterPanel={<FilterPanel searchFunc={submitFilter} filterData={filterData} defaultDataFunc={clearFilterState} />}
      />
      {
        canShow(permission) && <div className="tabs">
          <span className="border-line"/>
          <NavLink to={'/device'} className={(navData) => ((location.pathname === '/' || location.pathname === '/device') ? 'active tabs_link' : 'tabs_link')}>Device List</NavLink>
          <NavLink to={'/configurations'} className={(navData) => (navData.isActive ? 'active tabs_link' : 'tabs_link')}>Configurations</NavLink>
        </div>
      }
      <div className="container">
        {
          !devices ? <Progress /> : devices.length > 0 ? <CustomTable
            pageType={'device'}
            staticTableHeads={[...deviceTableToDisplay, ...deviceColumnsToDisplay]}
            dynamicColumnsSrc={"payload"}
            data={devices}
            childrenRoute={"/device"}
            displayFunc={displayFunc}
            editItem={handleEditClick}
            deleteItem={deleteSubmit}
            filterParameters={deviceParameters}
            friendlyNames={tableDeviceNames}
            settings={settings}
            reloadData={onCompleteSubmit}
            setClickedDevices={changeClickedDevices}
            clickedDevices={clickedDevices}
          /> : <div className="empty-text">No matching records were found</div>
        }
      </div>
      {popup.isOpen && renderPopup()}
      {confirmPopup && clickedDevices.length > 0 && renderConfirmForSelectedDevice()}
    </>
  )
}
