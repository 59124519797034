import React, { FunctionComponent, memo, useState } from "react";
import { FormikProps } from "formik";
import { object as yupObject, string as yupString } from 'yup';
import { validation, validationRegExps } from "../../../../constants/errors/errors.constant";
import { SiteViewerFormValues } from "./interfaces/site-viewer-form.interface"
import { SiteViewerFormInterface } from "../../../../interfaces/form.interface";
import CustomForm from "../../CustomForm";
import CustomInput from '../../CustomInput'
import { makeSelectOptions, CustomSelect } from '../../CustomSelect'
// import { siteService } from "../../../../services";

const validationSchema = () => yupObject().shape({
    userName: yupString()
        .required(validation.nameRequired)
        .min(3, validation.minLength(3))
        .max(100, validation.maxLength(100))
        .matches(validationRegExps.emptyField, validation.notEmptyField),
    email: yupString()
        .email(validation.invalidEmailFormat)
        .required(validation.emailRequired),
    siteId: yupString()
        .required(validation.isRequired("site")),
    customerId: yupString()
        .required(validation.isRequired("customer")),
});

const SiteViewerForm: FunctionComponent<SiteViewerFormInterface> = memo(({ closeFunc, isEdit, customers, sites, skipFields, ...props }) => {

    // eslint-disable-next-line
    const [activeCustomerIdValue, setActiveCustomerIdValue] = useState<string>(props.initialValues.customerId);
    const siteOptions = makeSelectOptions(sites, "id", "name");
    // const [siteOptions, setSiteOptions] = useState<SelectOption[]>([]);

    /*useEffect(() => {
        let customerId = parseInt(activeCustomerIdValue);
        if (customerId) {
            siteService.getSitesByCustomer(customerId).subscribe(
                (sites) => {
                    setSiteOptions(makeSelectOptions(sites, "id", "name"));
                });
        }
    }, [activeCustomerIdValue]);*/

    const customerOptions = !skipFields?.includes("customerId") ? makeSelectOptions(customers, "id", "name") : [];

    const renderForm = ({
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
        isValid,
        dirty,
        handleSubmit
    }: FormikProps<SiteViewerFormValues>) => {
        const onBlur = (name: string) => () => setFieldTouched(name);
        const onChangeText = (name: string, value: string) => setFieldValue(name, value);

        const onChangeCustomer = (name: string, value: string) => {
            onChangeText(name, value);
            setActiveCustomerIdValue(value);
            if (isEdit) {
                setFieldTouched("siteId");
            }
            onChangeText("siteId", "");
        };

        return (
            <>
                <div className="popup-form_item" >
                    <p>Name</p>
                    <CustomInput
                        type={"text"}
                        placeholder={"Name"}
                        name={"userName"}
                        onChange={onChangeText}
                        onBlur={onBlur('userName')}
                        value={values.userName}
                        error={Boolean(touched.userName && errors.userName)}
                        errorMessage={(touched.userName && errors.userName) ? errors.userName : undefined}
                        disabled={isSubmitting}
                        required={true}
                    />
                </div>
                <div className="popup-form_item" >
                    <p>Email</p>
                    <CustomInput
                        type={"email"}
                        placeholder={"Email"}
                        name={"email"}
                        onChange={onChangeText}
                        onBlur={onBlur('email')}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        errorMessage={(touched.email && errors.email) ? errors.email : undefined}
                        disabled={isEdit ? true : isSubmitting}
                        required={true}
                    />
                </div>
                {!skipFields?.includes("customerId") &&
                    <div className="popup-form_item" >
                        <p>Customer</p>
                        <CustomSelect
                            options={customerOptions}
                            placeholder={"Customer"}
                            name={"customerId"}
                            onChange={onChangeCustomer}
                            onBlur={onBlur('customerId')}
                            value={values.customerId}
                            error={Boolean(touched.customerId && errors.customerId)}
                            errorMessage={(touched.customerId && errors.customerId) ? errors.customerId : undefined}
                            disabled={isSubmitting}
                            required={true}
                        />
                    </div>
                }
                <div className="popup-form_item" >
                    <p>Site</p>
                    <CustomSelect
                        options={siteOptions}
                        placeholder={"Site"}
                        name={"siteId"}
                        onChange={onChangeText}
                        onBlur={onBlur('siteId')}
                        value={values.siteId}
                        error={Boolean(touched.siteId && errors.siteId)}
                        errorMessage={(touched.siteId && errors.siteId) ? errors.siteId : undefined}
                        disabled={!values.customerId || siteOptions.length === 1 || isSubmitting}
                        required={true}
                    />
                </div>
                <div className="form-btn">
                    <button type="submit" onClick={() => handleSubmit()} disabled={!dirty || isSubmitting || !isValid} className="btn save-btn">Save</button>
                    <button className="btn cancel-btn" disabled={isSubmitting} onClick={closeFunc}>cancel</button>
                </div >
            </>
        )
    };

    return (
        <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />
    )
});

export default SiteViewerForm;
