import React, {FunctionComponent, memo, useState} from "react";
import { object as yupObject, string as yupString } from 'yup';
import { authService } from "../../../../services";
import { CognitoUserExtended } from "../../../../constants/cognito-user";
import { take } from "rxjs";
import { Formik, FormikProps } from "formik";
import { useNavigate, useLocation } from 'react-router-dom'
import CustomInput from "../../CustomInput";
import LoginForm from "../../LoginForm";
import { validation } from "../../../../constants/errors/errors.constant";
import { LoginFormInterface } from "./interfaces/login-form.interface";
import { formSubmission } from "../../../../utils/form-submission";
import eye from "../../../../style/assets/img/eye.png";
import eyeOff from "../../../../style/assets/img/eye-off.png";

interface LocationProps {
  state: {
    from: Location;
  };
}

const validationSchema = () => yupObject().shape({
  email: yupString()
    .email(validation.invalidEmailFormat)
    .required(validation.emailRequired),
  pass: yupString()
    .min(8, validation.minLength(8))
    .required(validation.passwordRequired)
});

const LoginPage: FunctionComponent = memo(() => {
  const [isPassVisible, setVisibility] = useState<boolean>(false);
  const history = useNavigate();
  const location = useLocation() as LocationProps;
  const submitForm = (values: LoginFormInterface, onComplete: () => void) => {
    const { email, pass } = values;

    authService.signInProcess(email, pass).pipe(
      take(1)
    ).subscribe({
      next: (user: CognitoUserExtended | null) => {
        if (user) {
          authService.storeUser(user);
          history('/confirm-password');
        } else {
          history(location.state?.from?.pathname || '/');
        }
      },
      error: () => onComplete(),
      complete: () => {
        onComplete();
      }
    });
  };

  const handleForgotPass = () => {
    history('/reset-password');
  }

  const renderForm = ({
                        values,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        errors,
                        setFieldTouched,
                        isSubmitting,
                        isValid
                      }: FormikProps<LoginFormInterface>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string, value: string) => setFieldValue(name, value);

    return (
      <>
        <div className="popup-form_item" >
          <p>Email</p>
          <CustomInput
            type={"email"}
            placeholder={"Email"}
            name={"email"}
            onChange={onChangeText}
            onBlur={onBlur('email')}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            errorMessage={(touched.email && errors.email) ? errors.email : undefined}
            disabled={isSubmitting}
            required={true}
          />
        </div>

        <div className="popup-form_item" >
          <p>Password</p>
          <CustomInput
            type={isPassVisible ? 'text' : 'password'}
            placeholder={"Password"}
            name={"pass"}
            onChange={onChangeText}
            onBlur={onBlur('pass')}
            value={values.pass}
            error={Boolean(touched.pass && errors.pass)}
            errorMessage={(touched.pass && errors.pass) ? errors.pass : undefined}
            disabled={isSubmitting}
            required={true}
          />
          <div className="eye-block" onClick={() => setVisibility(!isPassVisible)}>
            <img src={isPassVisible ? eye : eyeOff} alt="eye"/>
          </div>
        </div>
        <div className="additional-text" onClick={handleForgotPass}>
          <p>Forgot password?</p>
        </div>
        <div className="form-btn">
          <button type={'submit'} disabled={isSubmitting || !isValid} onClick={() => handleSubmit()} className="btn save-btn" >Log in</button>
        </div>
      </>
    )};


  return (
    <LoginForm>
      <div className="popup-body_title">
        <p>Login</p>
      </div>
      <div className="popup-form">
        <Formik
          initialValues={{ email: '', pass: '' }}
          onSubmit={formSubmission<LoginFormInterface>(submitForm)}
          validationSchema={validationSchema()}
        >
          {renderForm}
        </Formik>
      </div>
    </LoginForm>
  );
});

export default LoginPage;