import React, {FunctionComponent, memo, useEffect, useState} from "react";
import moment from 'moment';
import { NavLink } from "react-router-dom";
import { Configuration, ConfigurationFilterData } from "../../../../../interfaces/configurations.interface";
import { deviceConfigurationService } from "../../../../../services";
import FilterPanel from "../../../FilterPanel";
import BreadCrumbs from "../../../BreadCrumbs";
import Progress from "../../../ProgressBar";
import StaticTable from "../../../StaticTable";
import useTags from "../../../../../hooks/useTags";
import useUserInfo from "../../../../../hooks/useUserInfo";
import { displayTags } from "../../../displayFunctions";
import { keyListToTags, getKeyList } from "../../../../../utils/tags"
import CustomPopup from "../../../CustomPopup";
import DeletePopup from "../../../DeletePopup";
import DeviceConfigurationForm from "../DeviceConfigurationForm";
import { DeviceConfigurationFormValues } from "../interfaces/device-configuration-form.interface";
import { addConfiguration, updateConfiguration, deleteConfiguration } from "../configurations";
import { makeSelectOptions } from "../../../CustomSelect";
import { FilterValue } from "../../../../../interfaces/device.interface";
import { activeFilterField, tagFilterObject } from "../../../../../helpers/filterHelper";

const friendlyNamesForTableHead = {
  configurationName: "Configuration Name",
  deviceType: "Device Type",
  createDate: "Create Date",
  port: "Port",
  downLink: "Downlink",
  tags: "Tags",
}

const ConfigurationsList: FunctionComponent = memo(() => {

  const allTags = useTags() || [];
  const { sites, customerId } = useUserInfo();
  const [configurationsList, setConfigurationsList] = useState<Configuration[] | null>(null);
  const [filterData, setFilterData] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<ConfigurationFilterData | null>(null);
  const [savedFilter, setSavedFilter] = useState<FilterValue | null>(null);
  const [filterStatus, setFilterStatus] = useState<boolean>( false);
  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [currentConfiguration, setCurrentConfiguration] = useState<Configuration>();

  const uploadConfigurationData = () =>
    deviceConfigurationService.getConfigurations({ sortingParameter: 'id', sortingType: 'DESC' }).subscribe(setConfigurationsList);
  const uploadFilterParams = () => deviceConfigurationService.getFiltersParams().subscribe(setFilterParams);
  const defaultDataFunction = () => {
    setFilterStatus(false);
    uploadConfigurationData();
  }

  const initialValues = {
    configurationName: "",
    deviceType: "",
    downLink: "",
    port: -1,
    tags: []
  };

  const closePopup = () => setPopup({ ...popup, isOpen: false });

  const displayFunc = (key: string, value: any) => {
    switch (key) {
      case "createDate":
        return moment(value).format("DD.MM.YYYY hh:mm A");
      case "tags":
        return displayTags(value, allTags);
      default:
        return value;
    }
  };

  const uploadFilterData = () => {
    const filterData = [
      {
        name: "deviceType",
        friendlyName: "Device Type",
        options: (filterParams?.deviceTypes && filterParams.deviceTypes.length > 0) ? filterParams.deviceTypes.map((item, index) => {
          return {
            value: item,
            label: item,
          }
        }) : []
      },
      {
        name: "port",
        friendlyName: "Port",
        options: (filterParams?.ports && filterParams.ports.length > 0) ? filterParams.ports.map((item, index) => {
          return {
            value: item,
            label: item,
          }
        }) : []
      },
      {
        name: "tags",
        friendlyName: "Tags",
        options: filterParams?.tags && filterParams?.tags.length > 0 ? makeSelectOptions(filterParams.tags, "keyName", "keyFriendlyName") : []
      },
    ];
    setFilterData(filterData);
  };

  useEffect(() => {
    uploadConfigurationData();
    uploadFilterParams();
  }, []);

  useEffect(() => {
    uploadFilterData();
    // eslint-disable-next-line
  }, [filterParams]);

  const submitFilter = (data: FilterValue) => {
    setFilterStatus(true);
    setSavedFilter(data);
    const { search, tags } = data;
    const activeField = activeFilterField(data);

    let filterData = {
      customerId: customerId,
      search: search?.length > 0 ? search : null,
      siteId: sites?.map((item) => item.id) ?? null,
      queryParameters: Object.keys(activeField)?.length > 0 ? activeField : null,
      tagFilterJson: tags?.length > 0 ? tagFilterObject(tags) : null,
    };
    deviceConfigurationService.getFilteredData(filterData).subscribe(setConfigurationsList);
  };

  const submitForm = (values: DeviceConfigurationFormValues, onComplete: () => void, createBool: boolean) => {
    if (createBool) {
      let configuration = {
        ...values,
        customerId: customerId ?? 1,
        tags: keyListToTags(allTags, values.tags)
      };
      addConfiguration(configuration, onComplete);
    } else if (currentConfiguration) {
      let configuration = {
        ...currentConfiguration,
        ...values,
        tags: keyListToTags(allTags, values.tags)
      };
      updateConfiguration(configuration, onComplete)
    }
  };

  const onCompleteSubmit = () => {
    if (filterStatus) {
      savedFilter && submitFilter(savedFilter);
    } else {
      uploadConfigurationData();
    }
    closePopup();
  };

  const addSubmit = (values: DeviceConfigurationFormValues) => submitForm(values, onCompleteSubmit, true);
  const editSubmit = (values: DeviceConfigurationFormValues) => submitForm(values, onCompleteSubmit, false);
  const deleteSubmit = () => deleteConfiguration(currentConfiguration?.id, onCompleteSubmit);

  const handleEditClick = (item: Configuration) => {
    setPopup({ isOpen: true, type: "edit" });
    setCurrentConfiguration(item);
  }

  const handleDeleteClick = (item: Configuration) => {
    setPopup({ isOpen: true, type: "delete" });
    setCurrentConfiguration(item);
  }

  const getEditInitialValue = (item?: Configuration): DeviceConfigurationFormValues => {
    if (item) {
      let tagsKeyList = getKeyList(item.tags);
      return { ...item, tags: tagsKeyList }
    }
    else return initialValues;
  }

  const renderPopup = () => {
    switch (popup.type) {
      case "create":
        return (<CustomPopup title={"configuration"} titlePrefix={"create"} closeFunc={closePopup}>
          <DeviceConfigurationForm closeFunc={closePopup} initialValues={initialValues} submitForm={addSubmit} tags={allTags} />
        </CustomPopup>)
      case "edit":
        return (<CustomPopup title={"configuration"} titlePrefix={"edit"} closeFunc={closePopup}>
          <DeviceConfigurationForm closeFunc={closePopup} initialValues={getEditInitialValue(currentConfiguration)} submitForm={editSubmit} isEdit={true} tags={allTags} />
        </CustomPopup>)
      case "delete":
        return (<DeletePopup itemType="configuration" itemName={currentConfiguration?.configurationName} handleSubmit={deleteSubmit} handleCancel={closePopup}/>)
    }
  }

  return (
    <>
      <BreadCrumbs
        currentPage={"CONFIGURATIONS"}
        currentItem={null}
        buttonName={"Config"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
        filterPanel={<FilterPanel searchFunc={submitFilter} filterData={filterData} defaultDataFunc={defaultDataFunction} />}
      />
      <div className="tabs">
        <span className="border-line"/>
        <NavLink to={'/device'} className={(navData) => (navData.isActive ? 'active tabs_link' : 'tabs_link')}>Device List</NavLink>
        <NavLink to={'/configurations'} className={(navData) => (navData.isActive ? 'active tabs_link' : 'tabs_link')}>Configurations</NavLink>
      </div>
      <div className="container">
        {!configurationsList ? <Progress /> : configurationsList.length > 0 ? <div className="custom-width-block">
          <StaticTable
            className="configurations"
            friendlyTableHeadNames={friendlyNamesForTableHead}
            data={configurationsList}
            displayFunc={displayFunc}
            editItem={handleEditClick}
            deleteItem={handleDeleteClick}
          />
        </div> : <div className="empty-text">No matching records were found</div>}
      </div>
      {popup.isOpen && renderPopup()}
    </>
  )
});

export default ConfigurationsList;