import React, { FunctionComponent, memo } from "react";
import { Alert, Snackbar } from '@mui/material';
import useError from "../../../hooks/useError";

const ErrorDisplay: FunctionComponent = memo(() => {
  const [error, setError] = useError();
  const closeMessage = () => setError(null);

  return (
    <Snackbar
      open={Boolean(error)}
      onClose={ closeMessage }
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={closeMessage} severity="error" sx={{ width: '100%' }}>
        {error?.message ?? ''}
      </Alert>
    </Snackbar>
  );
});

export default ErrorDisplay;