import React, { useState, FunctionComponent, memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import AssetInfo from "../../../AssetInfo";
import { Outlet } from "react-router-dom";
import AssetForm from "../AssetForm";
import AssetDeleteForm from "../AssetDeleteForm";
import CustomPopup from "../../../CustomPopup";
import useTags from "../../../../../hooks/useTags";
// import useSitesByCustomer from "../../../../../hooks/useSitesByCustomer";
import useCurrentAsset from "../../../../../hooks/useCurrentAsset";
import DeviceTagForm from "../../Devices/DeviceTagForm";
import { AssetFormValues } from "../interfaces/asset-form.interface";
import { addAsset, editAsset, deleteAssetFlow } from "../assets";
import { assetService } from "../../../../../services";
import AssetLocation from "../AssetLocation";
import { keyListToTags, getKeyList } from "../../../../../utils/tags";
import useUserInfo from "../../../../../hooks/useUserInfo";
import Progress from "../../../ProgressBar";

const AssetPage: FunctionComponent = memo(() => {
  const allTags = useTags() || [];
  const { sites, customerId } = useUserInfo();
  // const sites = useSitesByCustomer(customerId ?? 3);
  const { assetId } = useParams();
  let currentAsset = useCurrentAsset();
  const navigate = useNavigate();

  const [popup, setPopup] = useState({ isOpen: false, type: "create" });

  useEffect(() => {
    assetService.getCurrentAsset(assetId ? parseInt(assetId) : 1).subscribe({});

    return () => assetService.updateAssetStore(null);
  }, [assetId])

  const closePopup = () => setPopup({ ...popup, isOpen: false })

  const initialValues = {
    customerId: customerId ?? 3,
    siteId: sites && sites.length === 1 ? sites[0].id.toString() : "",
    name: "",
    devices: [],
    tags: [],
  };

  const submitForm = (values: AssetFormValues, onComplete: () => void, createBool: boolean) => {
    const site = parseInt(values.siteId);
    const tags = keyListToTags(allTags, values.tags);
    if (createBool) {
      let newAsset = {
        name: values.name,
        customerId: customerId ?? 3,
        siteId: site,
        tags: tags
      };
      addAsset(newAsset, onComplete, values.devices);
    } else if (currentAsset) {
      let newAsset = {
        ...currentAsset,
        name: values.name,
        siteId: site,
        tags: tags
      };
      editAsset(newAsset, onComplete, values.devices);
    }
  };

  const getEditInitialValue = (): AssetFormValues => {
    if (currentAsset) {
      const tagsKeyList = getKeyList(currentAsset.tags);
      const site = currentAsset.siteId?.toString() || "";
      const assignedDevice = currentAsset?.deviceDtos?.map(item => item.deviceEUI);
      return { name: currentAsset.name, tags: tagsKeyList, customerId: currentAsset.customerId, devices: assignedDevice, siteId: site }
    }
    else return initialValues;
  }

  const onCompleteSubmit = () => {
    closePopup();
    currentAsset && assetService.getCurrentAsset(currentAsset.id).subscribe();
  }
  const onCompleteDelete = () => navigate("/asset", { replace: true });

  const addSubmit = (values: AssetFormValues) => submitForm(values, onCompleteSubmit, true);
  const editSubmit = (values: AssetFormValues) => submitForm(values, onCompleteSubmit, false);
  const deleteSubmit = () => currentAsset && deleteAssetFlow(currentAsset.id, currentAsset.name, currentAsset.deviceDtos, onCompleteDelete);

  const editTag = () => setPopup({ isOpen: true, type: "editTags" });

  const editFunc = () => setPopup({ isOpen: true, type: "edit" });
  const deleteFunc = () => setPopup({ isOpen: true, type: "delete" });

  const renderPopup = () => {
    switch (popup.type) {
      case "create":
        return <CustomPopup title={"Asset"} titlePrefix={popup.type} closeFunc={closePopup}>
          <AssetForm closeFunc={closePopup} initialValues={initialValues} submitForm={addSubmit} tags={allTags} sites={sites ?? []} />
        </CustomPopup>
      case "edit":
        return <CustomPopup title={"Asset"} titlePrefix={popup.type} closeFunc={closePopup}>
          <AssetForm closeFunc={closePopup} initialValues={getEditInitialValue()} submitForm={editSubmit} tags={allTags} sites={sites ?? []} currentDevices={currentAsset?.deviceDtos} />
        </CustomPopup>
      case "editTags":
        return <CustomPopup title={"Tag"} titlePrefix={"edit"} closeFunc={closePopup}>
          <DeviceTagForm closeFunc={closePopup} initialValues={getEditInitialValue()} submitForm={editSubmit}
            isEdit={true} tags={allTags} sites={currentAsset?.siteId ?? 1} />
        </CustomPopup>
      case "delete":
        const assignedDevice = currentAsset?.deviceDtos?.map(item => item.name) ?? [];
        return <CustomPopup title={"Asset"} titlePrefix={"delete"} closeFunc={closePopup}>
          <AssetDeleteForm
            deviceList={assignedDevice}
            roomName={currentAsset?.name ?? ''}
            submitFunc={deleteSubmit}
            closeFunc={closePopup} />
        </CustomPopup>
    }
  }

  return (
    <>
      <BreadCrumbs
        currentPage={'List of rooms'}
        currentItem={currentAsset?.name ?? "Room Name"}
        buttonName={"room"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
      />
      {currentAsset
        ? <div className="container device-page">
          <AssetInfo asset={currentAsset} editFunc={editFunc} editTag={editTag} deleteFunc={deleteFunc} />
          <AssetLocation mapId={currentAsset.mapId} asset={currentAsset} />
        </div>
        : <Progress /> }
      <Outlet />
      {popup.isOpen && renderPopup()}
    </>
  )
});

export default AssetPage;