import { Subject } from "rxjs";
const MessageService = () => {
  const store$ = new Subject<string>();

  const addMessage = (message: string): void => {
    store$.next(message);
  };

  return {
    store: store$.asObservable(),
    addMessage,
  };
};

const singleton = MessageService();
export default Object.freeze(singleton);

