import { useEffect, useState } from "react";
import { Device } from "../../interfaces/device.interface";
import { deviceService } from "../../services";

const useCurrentDevice = () => {
  const [state, setState] = useState<Device | null>(null);

  useEffect(() => {
    const sub = deviceService.device.subscribe(setState)

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useCurrentDevice;