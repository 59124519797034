import React, {FunctionComponent, memo, useState} from 'react'
import { Formik, FormikProps } from "formik";
import { object as yupObject, string as yupString, ref } from 'yup';
import { take } from "rxjs";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../LoginForm";
import CustomInput from "../../CustomInput";
import { formSubmission } from "../../../../utils/form-submission";
import { ConfirmPassFormInterface } from "./interface/confirm-pass-form.interface";
import { validation } from "../../../../constants/errors/errors.constant";
import { authService } from "../../../../services";
import eye from "../../../../style/assets/img/eye.png";
import eyeOff from "../../../../style/assets/img/eye-off.png";
import {userRole} from "../../../../utils/user-role";

const validationSchema = () => yupObject().shape({
  pass: yupString()
    .min(8, validation.passwordMinLength)
    .required(validation.passwordRequired),
  confirmPass: yupString().when('pass', {
    is: (val: string) => val && val.length > 0,
    then: yupString()
      .oneOf([ref('pass')], validation.passwordMatch)
      .required(validation.defaultRequired)
  }),
});

const ConfirmPassword: FunctionComponent = memo(() => {
  const [isPassVisiblePass, setVisibilityPass] = useState<boolean>(true);
  const [isConfirmPassVisible, setVisibilityConfirmPass] = useState<boolean>(true);
  const history = useNavigate();

  const submitForm = (values: ConfirmPassFormInterface, onComplete: () => void) => {
    const password = values.pass;

    authService.confirmPass(password, {}).pipe(
      take(1)
    ).subscribe({
      next: (user) => {
        const device = user.challengeParam.userAttributes["custom:device"];
        const site = user.challengeParam.userAttributes["custom:site"];
        const customer = user.challengeParam.userAttributes["custom:customer"];
        const userRoleStatus = userRole(device, site, customer);
        if (userRoleStatus === 'Viewer') {
          history('/settings');
          onComplete();
        } else {
          history('/');
          onComplete();
        }
      },
      error: (e) => {
        console.error(e)
        onComplete()
      }
    });
  };

  const renderForm = ({
                        values,
                        handleSubmit,
                        setFieldValue,
                        touched,
                        errors,
                        setFieldTouched,
                        isSubmitting,
                        isValid
                      }: FormikProps<ConfirmPassFormInterface>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string, value: string) => setFieldValue(name, value);
    return (
      <>
        <div className="popup-form_item" >
          <p>New Password</p>
          <CustomInput
            type={isPassVisiblePass ? 'password' : 'text'}
            placeholder={"New Password"}
            name={"pass"}
            onChange={onChangeText}
            onBlur={onBlur('pass')}
            value={values.pass}
            error={Boolean(touched.pass && errors.pass)}
            errorMessage={(touched.pass && errors.pass) ? errors.pass : undefined}
            disabled={isSubmitting}
            required={true}
          />
          <div className="eye-block" onClick={() => setVisibilityPass(!isPassVisiblePass)}>
            <img src={isPassVisiblePass ? eye : eyeOff} alt="eye"/>
          </div>
        </div>
        <div className="popup-form_item" >
          <p>Confirm New Password</p>
          <CustomInput
            type={isConfirmPassVisible ? 'password' : 'text'}
            placeholder={"Confirm New Password"}
            name={"confirmPass"}
            onChange={onChangeText}
            onBlur={onBlur('confirmPass')}
            value={values.confirmPass}
            error={Boolean(touched.confirmPass && errors.confirmPass)}
            errorMessage={(touched.confirmPass && errors.confirmPass) ? errors.confirmPass : undefined}
            disabled={isSubmitting}
            required={true}
          />
          <div className="eye-block" onClick={() => setVisibilityConfirmPass(!isConfirmPassVisible)}>
            <img src={isConfirmPassVisible ? eye : eyeOff} alt="eye"/>
          </div>
        </div>
        <div className="form-btn">
          <button
            disabled={isSubmitting || !isValid}
            onClick={() => handleSubmit()}
            className="btn save-btn" >Submit new password</button>
        </div>
      </>
    )
  };

  return (
    <LoginForm>
      <div className="popup-body_title">
        <p>Confirm Password</p>
      </div>
      <div className="popup-form">
        <Formik
          initialValues={{ pass: '', confirmPass: '' }}
          onSubmit={formSubmission<ConfirmPassFormInterface>(submitForm)}
          validationSchema={validationSchema()}
        >
          {renderForm}
        </Formik>
      </div>
    </LoginForm>
  )
});

export default ConfirmPassword;