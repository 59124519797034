import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { tap } from "rxjs/operators";
import { Api } from "../../api/api";
import { errorService } from "../index";
import {Configuration, NewConfiguration, SendConfiguration} from "../../interfaces/configurations.interface";
import { FilterQuery } from "../../interfaces/device.interface";

const DeviceConfigurationService = () => {
  const configurations$ = new BehaviorSubject<Configuration[] | null>(null);
  const configuration$ = new BehaviorSubject<Configuration | null>(null);

  const updateConfigurationsStore = (param: Configuration[] | null) => {
    configurations$.next(param);
  };

  const updateConfigurationStore = (param: Configuration | null) => {
    configuration$.next(param);
  };

  const createConfiguration = (body: NewConfiguration): Observable<any> => {
    return Api.post(API_ROUTES.DEVICE_CONFIGURATION, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const getConfigurations = (options?: any): Observable<Configuration[] | null> => {
    const urlParams = { ...options };
    return Api.get(API_ROUTES.DEVICE_CONFIGURATION,
      urlParams ? { urlParams } : undefined
    ).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((configurations: Configuration[]) => {
        return configurations || null
      }),
      tap(updateConfigurationsStore)
    );
  };

  const getConfigurationById = (id: number): Observable<Configuration | null> => {
    return Api.get(`${API_ROUTES.DEVICE_CONFIGURATION}/${id}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((configuration: Configuration) => {
        return configuration || null
      }),
      tap(updateConfigurationStore)
    );
  };

  const getConfigurationsByType = (options?: any): Observable<Configuration[] | null> => {
    const urlParams = { ...options };
    return Api.get(`${API_ROUTES.DEVICE_CONFIGURATION}/by-device-type`,
      urlParams ? { urlParams } : undefined
    ).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((configurations: Configuration[]) => {
        return configurations || null
      }),
      tap(updateConfigurationsStore)
    );
  };

  const getFiltersParams = (): Observable<any> => {
    return Api.get(`${API_ROUTES.DEVICE_CONFIGURATION}/get-filter-parameters`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    )
  };

  const getFilteredData = (params: FilterQuery): Observable<any> => {
    return Api.post(`${API_ROUTES.DEVICE_CONFIGURATION}/filter`, params).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const updateConfiguration = (body: NewConfiguration): Observable<any> => {
    return Api.put(API_ROUTES.DEVICE_CONFIGURATION, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      tap(() => updateConfigurationStore(null))
    );
  };

  const deleteConfiguration = (id: number): Observable<any> => {
    return Api.delete(`${API_ROUTES.DEVICE_CONFIGURATION}/${id}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const sendConfiguration = (body: SendConfiguration): Observable<any> => {
    return Api.post(API_ROUTES.NETWORK_SERVER_CONFIGURE, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  }

  return {
    configurations: configurations$.asObservable(),
    createConfiguration,
    getConfigurations,
    getConfigurationsByType,
    getConfigurationById,
    getFiltersParams,
    getFilteredData,
    updateConfiguration,
    sendConfiguration,
    deleteConfiguration,
  }
};

const singleton = DeviceConfigurationService();
export default Object.freeze(singleton);