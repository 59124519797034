import { Tag, SimplifiedTag } from "../interfaces/tag.interface"

export const getTagColorByName = (tags: Tag[], tagName: string) => tags.find(tag => tag.keyName === tagName)?.tagColor;
export const getTagColorById = (tags: Tag[], tagId: number) => tags.find(tag => tag.id === tagId)?.tagColor;

export const listToTags = (tags: string[]) => {
    if (tags.length > 0) {
        let tagsObj: any = {};
        tags.map(tag => tagsObj[tag] = "80");
        return JSON.stringify(tagsObj);
    }
    else return ""
}

export const keyListToTags = (allTags: Tag[], keyList: string[]) => {
    let tags: SimplifiedTag[] = [];
    keyList.map(keyName => {
        let tag = allTags.find(tag => tag.keyName === keyName);
        tag && tags.push({
            keyName: keyName,
            keyFriendlyName: tag.keyFriendlyName,
            value: "80"
        })
        return null;
    })
    return tags;
}

export const tagsToList = (tagStr: string) => tagStr ? Object.keys(JSON.parse(tagStr)) : [];
export const getKeyList = (tags: SimplifiedTag[]) => tags.map((tag) => tag.keyName);