import React, { FunctionComponent, useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { IndoorMap } from "../../../../../interfaces/indoor-map.interface";
import { deviceService, indoorMapService } from "../../../../../services";
import { Device } from "../../../../../interfaces/device.interface";
import { generateLayoutForGraph } from "../../../../../helpers/graphFunctions";
import empty from "../../../../../style/assets/img/empty.png";
import SetBeaconGraphs from "../../../Graphs/SetBeaconGraphs";
import { updateDevice } from "../devices";
import useUserRole from "../../../../../hooks/useUserRole";
import { canShow } from "../../../../../helpers/userHelper";
import { renderCurrentImgForPin } from "../../../../../helpers/imagePin";
import graphLegend from "../../../GraphLegend";

interface Props {
  mapId: number | null;
  addPin: boolean;
  setPin: (item: boolean) => void;
  device: Device;
}
interface Pin {
  x: number[];
  y: number[]
}

const DeviceLocation:FunctionComponent<Props> = ({mapId, addPin, setPin, device}) => {
  const [assignedMap, setAssignMap] = useState<IndoorMap | null>(null);
  const permission = useUserRole();
  const [layout, setLayout] = useState<any>({});
  const [pin, SetPin] = useState<Pin>({x: [], y: []});
  // eslint-disable-next-line
  const [config, setConfig] = useState<any>({
    modeBarButtonsToRemove:
      [
        'sendDataToCloud',
        'autoScale2d',
        'zoomOut2d',
        'zoomIn2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'lasso2d',
        'select2d',
        'toImage',
        'zoom2d',
        'pan2d',
        'resetScale2d'
      ],
    displaylogo: false,
  });

  useEffect(() => {
    //TODO: check why we get old map id
    if (mapId) {
      indoorMapService.getMapById(mapId).subscribe(setAssignMap)
    }

    return () => {
      setAssignMap(null);
      SetPin({x: [], y: []});
    }
  }, [mapId]);

  useEffect(() => {
    const layoutData = generateLayoutForGraph(
      {width: 1100, height: 800},
      { xLine: assignedMap?.graphValue.x ?? 0, yLine: assignedMap?.graphValue.y ?? 0 },
      assignedMap?.imageUrl ?? empty);
    const { position, status } = device;
    if (position?.x > 0 && position?.y > 0) {
      let xAxis = [],
        yAxis = [];
      xAxis.push(position.x);
      yAxis.push(position.y);
      let updatedLayout = {
        ...layoutData,
        images: [
          ...layoutData.images,
          {
            source: renderCurrentImgForPin(status),
            xref: "x",
            yref: "y",
            x: position.x,
            y: position.y,
            sizex: 80,
            sizey: 80,
            xanchor: "center",
            yanchor: "middle",
            layer: "above",
          }
        ]
      }
      SetPin({x: xAxis, y: yAxis});
      setLayout(updatedLayout);
    } else {
      setLayout(layoutData);
    }
    return () => SetPin({x: [], y: []});
  }, [assignedMap, device]);


  const savePin = (e: any) => {
    e.stopPropagation();
    const newDevice = { ...device, position: { x: pin.x[0], y: pin.y[0] }}
    updateDevice(newDevice, () => deviceService.getCurrentDevice(device.deviceEUI).subscribe(), `${device.name} location has been successfully added`);
    setPin(!addPin)
  };

  const revertChanges = () => {
    deviceService.getCurrentDevice(device.deviceEUI).subscribe({
      complete: () => {
        if (device.position?.x > 0 && device.position?.y > 0) {
          SetPin({ x: [device.position?.x], y: [device.position?.y] });
        } else {
          SetPin({ x: [], y: [] });
        }
        setPin(false);
      }
    });
  };

  const unassignPin = () => {
    const newDevice = { ...device, position: { x: 0, y: 0 }};
    updateDevice(newDevice, () => {
      SetPin({x: [], y: []});
      deviceService.getCurrentDevice(device.deviceEUI).subscribe();
    }, `${device.name} location has been successfully unassign`);
  };

  const renderGraphWithMap = () => {
    const {x, y} = pin;
    return (
      <Plot
        divId="myPlot"
        data={[
          {
            x: x,
            y: y,
            type: 'scatter',
            mode: 'markers',
            marker: {
              color: 'transparent',
              size: 20,
            },
            name: device.name,
            hoverinfo: 'name',
          }
        ]}
        layout={ layout }
        config={config}
      />
    )
  };

  const renderMap = () => {
    return (
      <div className="device-location">
        <div className="map-title-block">
          <p className="title">{assignedMap?.name}</p>
          {
            canShow(permission) ? <div className="btns-block">
              {
                addPin ? <>
                    <button className="btn cancel-pin-btn" onClick={revertChanges}/>
                    <button className="btn save-pin-btn" disabled={pin.x.length <= 0} onClick={ (e) => savePin(e)}/>
                  </> :
                  (pin.x.length > 0 && pin.y.length) > 0 ? <>
                    <button className="btn save-btn change-pin-btn" onClick={() => setPin(!addPin)}>edit pin</button>
                    <button className="btn save-btn unassign-btn" onClick={unassignPin}>unpin</button>
                  </> : <button className="btn save-btn add-pin-btn" onClick={() => setPin(!addPin)}>add pin</button>
              }
            </div> : null
          }
        </div>
        <div className="img-block">
          {!addPin ? renderGraphWithMap() : <SetBeaconGraphs
            graphMetrics={{ xLine: assignedMap?.graphValue.x ?? 0, yLine: assignedMap?.graphValue.y ?? 0 }}
            imgUrl={assignedMap?.imageUrl}
            savePin={SetPin}
            revertChanges={revertChanges}
          />
          }
        </div>
        { (pin.x.length > 0 && pin.y.length > 0 && !addPin) && graphLegend() }
      </div>
    )
  };

  return (
    <>
      {
        assignedMap && mapId ? renderMap() : <div className="empty-block">
          <img src={empty} alt="empty" />
        </div>
      }
    </>
  )
}

export default DeviceLocation;