import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { Api } from "../../api/api";
import { errorService } from "../index";
import { Site } from "../../interfaces/site.interface";

const SiteService = () => {

    const sites$ = new BehaviorSubject<any[] | null>(null);

    const getSitesByCustomer = (customerId: number): Observable<Site[]> => {
        return Api.get(`${API_ROUTES.SITE.GET_SITES_BY_CUSTOMER}/${customerId}`).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            })
        );
    };

    const getSiteById = (id: number): Observable<Site> => {
        return Api.get(`${API_ROUTES.SITE.GET_SITE_BY_ID}/${id}`).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            })
        );
    };

    return {
        sites: sites$.asObservable(),
        getSitesByCustomer,
        getSiteById
    }
};

const singleton = SiteService();
export default Object.freeze(singleton);
