import React, { FunctionComponent, KeyboardEvent } from "react";
import CustomInput from '../../CustomInput'
import CustomForm from '../../CustomForm'
import { AssetFormInterface } from "../../../../interfaces/form.interface";
import { FormikProps } from "formik";
import { CustomMultiSelect, CustomSelect, makeSelectOptions } from "../../CustomSelect";
import { getTagColorByName } from "../../../../utils/tags";
import { AssetFormValues } from "./interfaces/asset-form.interface";
import useGetFreeDevices from "../../../../hooks/useGetFreeDevices";
import { object as yupObject, string as yupString } from "yup";
import { validation } from "../../../../constants/errors/errors.constant";
import { Tag } from "../../../../interfaces/tag.interface";

const validationSchema = () => yupObject().shape({
  name: yupString()
    .required(validation.isRequired("name"))
    .trim()
    .min(3, validation.minLength(3))
    .max(32, validation.maxLength(32)),
  siteId: yupString()
    .required(validation.isRequired("site")),
});

const AssetForm: FunctionComponent<AssetFormInterface> = ({ closeFunc, isEdit, tags, sites, currentDevices, ...props }) => {
  const freeDevices = useGetFreeDevices();
  const siteOptions = makeSelectOptions(sites, "id", "name");
  const deviceOptions = freeDevices &&
    makeSelectOptions(currentDevices ? [...freeDevices, ...currentDevices] : freeDevices, "deviceEUI", "name");
  const tagOptions = (tagArray?: any[]) => {
    if (tagArray) {
      return makeSelectOptions(tagArray, "keyName", "keyFriendlyName");
    }  else {
      return makeSelectOptions(tags, "keyName", "keyFriendlyName");
    }
  };
  const renderForm = ({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        setFieldTouched,
                        isSubmitting,
                        isValid,
                        dirty,
                        handleSubmit,
                      }: FormikProps<AssetFormValues>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string, value: string) => setFieldValue(name, value);
    const onChangeTags = (name: string, values: string[]) => setFieldValue(name, values);
    const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }};
    const getTagOptionsBySiteId = (siteId: number, currentTag: string[]) => {
      const data = tags.filter((item: Tag) => item.siteId === siteId || item.keyName === currentTag[0]);
      return tagOptions(data);
    }
    return (
      <form className="map-popup_inputs" onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
        <div className="popup-form_item">
          <p>Room Name</p>
          <CustomInput
            name={"name"}
            value={values.name}
            onChange={onChangeText}
            onBlur={onBlur('name')}
            error={Boolean(touched.name && errors.name)}
            errorMessage={(touched.name && errors.name) ? errors.name : undefined}
            disabled={isSubmitting}
            required={true}
          />
        </div>
        <div className="popup-form_item">
          <p>Assigned devices</p>
          <CustomMultiSelect name="devices"
                             placeholder="Assigned devices"
                             selectedClassName={`tags-item`}
                             options={deviceOptions ?? []}
                             values={values.devices}
                             onChange={onChangeTags}
                             onBlur={onBlur('devices')}
                             error={Boolean(touched.devices && errors.devices)}
                             errorMessage={(touched.devices && errors.devices && typeof errors.devices === "string") ? errors.devices : undefined}
                             disabled={isSubmitting}
          />
        </div>
        <div className="popup-form_item">
          <p>Site</p>
          <CustomSelect
            options={siteOptions}
            placeholder={"Site"}
            name={"siteId"}
            onChange={onChangeText}
            onBlur={onBlur('siteId')}
            value={values.siteId?.toString()}
            error={Boolean(touched.siteId && errors.siteId)}
            errorMessage={(touched.siteId && errors.siteId) ? errors.siteId : undefined}
            disabled={siteOptions.length === 1 || isSubmitting}
            required={true}
          />
        </div>
        <div className="popup-form_item">
          <p>Tags</p>
          <CustomMultiSelect name="tags"
                             placeholder="Tags"
                             selectedClassName={`tags-item`}
                             options={getTagOptionsBySiteId(+values.siteId, values.tags)}
                             values={values.tags}
                             onChange={onChangeTags}
                             onBlur={onBlur('tags')}
                             error={Boolean(touched.tags && errors.tags)}
                             errorMessage={(touched.tags && errors.tags && typeof errors.tags === "string") ? errors.tags : undefined}
                             disabled={isSubmitting || !values.siteId}
                             getColor={(name) => getTagColorByName(tags, name)}
          />
        </div>
        <div className="form-btn">
          <button type="submit" disabled={!dirty || isSubmitting || !isValid} className="btn save-btn" >Save</button>
          <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button>
        </div>
      </form>
    )
  };

    return <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />
}

export default AssetForm;
