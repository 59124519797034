const readType = "READ";
const writeType = "WRITE";
const readWriteType = "READWRITE"

export const userRole = (device: string, site: string, customer: string) => {
  switch (true) {
    case customer === writeType:
      return 'SolutionAdmin';
    case (site === writeType && customer === readType):
      return 'SuperAdmin';
    case (device === readWriteType && site === readType):
      return 'SiteAdmin';
    default:
      return 'Viewer'
  }
};