export const validationRegExps = {
  passwordRegExp: /^[0-9a-zA-Z^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]*$/g,
  formatRegExp: /^[0-9a-zA-Z^$*. [\]{}()?"!@#%&/\\,><':;|_~`=+-]*$/g,
  lowerCaseRegExp: /^.*[a-z].*$/g,
  upperCaseRegExp: /^.*[A-Z].*$/g,
  digitRegExp: /^.*[0-9].*$/g,
  specialCharRegExp: /^.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-].*$/g,
  emptyField: /^(?!\s+$)/g,
  base64RegExp: /^[A-Za-z0-9+/\r\n]+={0,2}$/g,
}

export const validation = {
  passwordLength: 'min 8 symbols',
  passwordDigits: 'digits',
  passwordLetters: 'letters',
  invalidEmailFormat: 'a valid email can only contain latin letters, numbers, "@" and "."',
  invalidEUIFormats: 'a valid EUI can only contain numbers and letters ABCDEF',
  invalidEUIstart: `EUI should start with TEKTELIC code '647'`,
  invalidPassFormat: 'password can only contain latin letters, numbers and special characters',
  invalidFormat: (name: string) => `a ${name} can only contain latin letters, numbers, special characters and space`,
  emailRequired: 'an email address is required',
  nameRequired: 'a name is required',
  passwordRequired: 'a password is required',
  oldPasswordRequired: 'old password is required',
  passwordMinLength: 'a secure password must be at least 8 characters long',
  passwordMaxLength: 'a password must be maximum 64 characters long',
  passwordMatch: 'passwords should match',
  defaultRequired: 'field is required',
  notEmptyField: 'field can not be empty',
  shouldContain: (name: string, item: string) => `a ${name} should contain at least 1 ${item}`,
  isRequired: (name: string) => `a ${name} is required`,
  minLength: (num: number) => `Input should be minimum ${num} characters long`,
  maxLength: (num: number) => `Input should be maximum ${num} characters long`,
  exactLength: (name: string, num: number) => `${name} should be exactly ${num} characters long`,
  minNumber: (num: number) => `Value should be minimum ${num}`,
  maxNumber: (num: number) => `Value should be maximum ${num}`,
  hexOrBase64Format: "Please use hex or base64 format" 
};
