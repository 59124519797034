export const urlCreator = (url: string, params?: any): string => {
  if (params) {
    const keys = Object.keys(params);
    const urlParams = keys.reduce((a, b, i) => {
      const param = `${b}=${params[b]}${i !== keys.length - 1 ? '&' : ''}`;
      return a.concat(param);
    }, '');
    return `${url}?${urlParams}`;
  }
  return url;
}
