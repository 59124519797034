import React, {FunctionComponent, memo, KeyboardEvent, useState, useEffect} from "react";
import { FormikProps } from "formik";
import { object as yupObject, number as yupNumber } from "yup";
import { validation } from "../../../../constants/errors/errors.constant";
import CustomForm from '../../CustomForm'
import { makeSelectOptions, SearchSelect } from '../../CustomSelect';
import { AssignConfigurationFormValues } from "./interfaces/device-configuration-form.interface";
import { FormWithCloseFunc } from "../../../../interfaces/form.interface";
import {Configuration, SendConfiguration} from "../../../../interfaces/configurations.interface";
import { deviceConfigurationService } from "../../../../services";
import ButtonDelete from "../../Buttons/ButtonDelete";
import Progress from "../../ProgressBar";
import {Link} from "react-router-dom";

const validationSchema = () => yupObject().shape({
  configuration: yupNumber()
    .required(validation.isRequired("configuration")),
});

interface ErrorCodes {
  code: string;
  message: string;
  messageText: string;
}

const AssignConfiguration: FunctionComponent<FormWithCloseFunc> = memo(({ closeFunc, ...props }) => {

  const [deviceConfigurations, setDeviceConfigurations] = useState<Configuration[] | null>(null);
  const [savedRowCount, setSavedRowCount] = useState<number | null>(null);
  const [errorMessageCodes, setErrorMessageCodes] = useState<null | ErrorCodes[]>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const configurationOptions = makeSelectOptions(deviceConfigurations ?? [], "id", "configurationName");

  const submitFunc = (values: AssignConfigurationFormValues) => {
    if (savedRowCount !== null && errorMessageCodes === null) {
      return closeFunc();
    }
    setLoader(true);
    const deviceEUI = values?.devices?.map((item: any) => item.deviceEUI);
    const configurations: SendConfiguration = {
      configurationId: +values.configuration,
      deviceEuis: deviceEUI,
    };
    deviceConfigurationService.sendConfiguration(configurations).subscribe({
      next: (res: any) => {
        const { errorMessageCodes, savedRowCount } = res;
        setErrorMessageCodes(errorMessageCodes);
        setSavedRowCount(savedRowCount);
      },
      error: (error: any) => {
        setLoader(false);
      },
      complete: () => setLoader(false),
    })
  };

  const renderBtnName = () => {
    if (savedRowCount !== null && errorMessageCodes === null) {
      return "Finish";
    } else if ((savedRowCount !== null && errorMessageCodes !== null) || (savedRowCount === null && errorMessageCodes !== null)) {
      return "Retry"
    } else {
      return "Save"
    }
  };

  const renderCloseBtn = () => {
    if (savedRowCount !== null && errorMessageCodes !== null) {
      return true;
    } else if (savedRowCount === null && errorMessageCodes === null) {
      return true;
    } else if (savedRowCount === null && errorMessageCodes !== null) {
      return true;
    }
  }

  useEffect(() => {
    deviceConfigurationService.getConfigurations({ sortingParameter: 'id', sortingType: 'DESC' }).subscribe(setDeviceConfigurations);
  }, []);

  const renderForm = ({
      values,
      setFieldValue,
      touched,
      errors,
      setFieldTouched,
      isSubmitting,
      isValid,
      dirty,
      handleSubmit,
  }: FormikProps<AssignConfigurationFormValues>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeConfiguration = (name: string, values: string) => setFieldValue(name, values);
    const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    };
    const onChangeDevices = (deviceEUI: string) => {
      const index = values.devices.findIndex((value: any) => value.deviceEUI === deviceEUI);
      if (index > -1) {
        let newClickedDevicesList = [...values.devices];
        newClickedDevicesList.splice(index, 1);
        setFieldValue('devices', newClickedDevicesList);
      }
    };

    return (
      <form className="map-popup-body" onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
        <div className="main-content">
          <div className="popup-form_item">
            <p>Configuration*</p>
            <SearchSelect
              className="search-select"
              options={configurationOptions}
              placeholder={"Configuration Name"}
              name={"configuration"}
              onChange={onChangeConfiguration}
              onBlur={onBlur('configuration')}
              value={values.configuration?.toString()}
              error={Boolean(touched.configuration && errors.configuration)}
              errorMessage={(touched.configuration && errors.configuration) ? errors.configuration : undefined}
              disabled={isSubmitting}
              required={true}
            />
          </div>
        </div>
        {
          (savedRowCount === null && errorMessageCodes === null) && <div className="map-popup_inputs__configuration-devices">
          <p className="text">You are applying the configuration to the following devices
            ({values?.devices?.length ?? 0}):</p>
          {
            (values.devices && values.devices.length > 0) && values.devices.map((item: any, index: number) => {
              return (
                <div className="map-popup_inputs__file" key={index}>
                  <div className="map-popup_inputs__file__name">
                    <p>{item.name}</p>
                    <p>, {item.deviceType}</p>
                  </div>
                  <ButtonDelete onClick={() => onChangeDevices(item.deviceEUI)}/>
                </div>
              )
            })
          }
        </div>}
        {
          savedRowCount && <div className="successful-block">
            <p>The configuration has been successfully applied to {savedRowCount} device(s)</p>
          </div>
        }
        {
          errorMessageCodes && <div className="errors-block">
            <p>Failed to configure the following devices. Please try again or  contact <Link to={'https://support.tektelic.com/portal/en/signin'} target="_blank" className="link">TEKTELIC Support</Link></p>
            {
              errorMessageCodes.map((item, index) => {
                return (
                  <div className="error-code" key={index}>
                    <p>{item.messageText}</p>
                  </div>
                )
              })
            }
          </div>
        }
        <div className="form-btn">
          <button type="submit" disabled={!dirty || !isValid || values.devices.length < 1 || loader} className="btn save-btn" >{loader ? <Progress size={20} /> : renderBtnName()}</button>
          {(renderCloseBtn()) && <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button>}
        </div>
      </form>
    )
  };
  return <CustomForm renderForm={renderForm} validationSchema={validationSchema} submitForm={submitFunc} {...props} />

});

export default AssignConfiguration;