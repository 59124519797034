import React, { FunctionComponent, memo, useState, KeyboardEvent } from 'react'
import { Formik, FormikProps } from "formik";
import { object as yupObject, string as yupString, ref } from 'yup';
import { take, mergeMap } from "rxjs";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../LoginForm";
import CustomInput from "../../CustomInput";
import { formSubmission } from "../../../../utils/form-submission";
import { ResetPassFormInterface, ForgotPassFormInterface } from "./interface/reset-pass-form.interface";
import { validation, validationRegExps } from "../../../../constants/errors/errors.constant";
import { authService, messageService, errorService } from "../../../../services";
import eye from "../../../../style/assets/img/eye.png";
import eyeOff from "../../../../style/assets/img/eye-off.png";

const validationForgotPassSchema = () => yupObject().shape({
    email: yupString()
        .email(validation.invalidEmailFormat)
        .required(validation.emailRequired)
});

const validationResetPassSchema = () => yupObject().shape({
    code: yupString()
        .min(6, validation.exactLength("code", 6))
        .max(6, validation.exactLength("code", 6))
        .required(validation.isRequired("code")),
    password: yupString()
        .min(8, validation.passwordMinLength)
        .max(64, validation.passwordMaxLength)
        .required(validation.passwordRequired)
        .matches(validationRegExps.passwordRegExp, validation.invalidPassFormat)
        .matches(validationRegExps.digitRegExp, validation.shouldContain("password", "number"))
        .matches(validationRegExps.lowerCaseRegExp, validation.shouldContain("password", "lower case letter"))
        .matches(validationRegExps.upperCaseRegExp, validation.shouldContain("password", "upper case letter"))
        .matches(validationRegExps.specialCharRegExp, validation.shouldContain("password", "special character")),
    confirmPassword: yupString().when('password', {
        is: (val: string) => val && val.length > 0,
        then: yupString()
            .oneOf([ref('password')], validation.passwordMatch)
            .required(validation.defaultRequired)
    }),
});

const ResetPassword: FunctionComponent = memo(() => {
    const history = useNavigate();

    const [isPassVisiblePass, setVisibilityPass] = useState<boolean>(false);
    const [isConfirmPassVisible, setVisibilityConfirmPass] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(false);

    const goToLogin = () => history('/sign-in');

    const submitForgotPassForm = (values: ForgotPassFormInterface, onComplete: () => void) => {
        const { email } = values;
        authService.forgotPassword(email).pipe(
            take(1)
        ).subscribe({
            next: () => {
                setEmail(email);
                setIsCodeSent(true);
                messageService.addMessage("Verification code has been sent successfully. Please check your mailbox")
            },
            error: (e) => {
                console.error(e);
                onComplete();
            }
        });
    };

    const submitResetPassForm = (values: ResetPassFormInterface, onComplete: () => void) => {
        const { code, password, } = values;
        authService.forgotPasswordSubmit(email, code, password).pipe(
            mergeMap(() => authService.signInProcess(email, password).pipe(take(1)))
        ).subscribe({
            next: () => {
                messageService.addMessage("New password has been set successfully")
                history('/');
                onComplete();
            },
            error: (e) => {
                if (e.code === "CodeMismatchException") {
                    errorService.addError({ message: "You've entered the incorrect verification code. Please check and try again." });
                }
                onComplete()
            }
        });
    };

    const renderForgotPasswordForm = ({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
        isValid,
        dirty
    }: FormikProps<ForgotPassFormInterface>) => {
        const onBlur = (name: string) => () => setFieldTouched(name);
        const onChangeText = (name: string, value: string) => setFieldValue(name, value);
        const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
            if (event.key === 'Enter') {
                handleSubmit()
            }
        };
        return (
            <form onKeyDown={handleEnterKeyDown} onSubmit={handleSubmit}>
                <fieldset disabled={isCodeSent}>
                    <div className={`popup-form_item`} >
                        <p>Email</p>
                        <CustomInput
                            type="email"
                            placeholder={"Email"}
                            name={"email"}
                            onChange={onChangeText}
                            onBlur={onBlur('email')}
                            value={values.email}
                            error={Boolean(touched.email && errors.email)}
                            errorMessage={(touched.email && errors.email) ? errors.email : undefined}
                            disabled={isSubmitting}
                            required={true}
                            autoFocus={true}
                        />
                    </div>
                </fieldset>
                <div className="form-btn inner-form-btn">
                    <button type="submit" disabled={isCodeSent || !dirty || !isValid}
                        className="btn save-btn">Send</button>
                </div>
            </form>
        )
    };

    const renderResetPasswordForm = ({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
        isValid,
        dirty
    }: FormikProps<ResetPassFormInterface>) => {
        const onBlur = (name: string) => () => setFieldTouched(name);
        const onChangeText = (name: string, value: string) => setFieldValue(name, value);
        const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
            if (event.key === 'Enter') {
                handleSubmit()
            }
        }
        return (
            <form onKeyDown={handleEnterKeyDown} onSubmit={handleSubmit}>
                <fieldset disabled={!isCodeSent}>
                    <div className="popup-form_item" >
                        <p>Enter code</p>
                        <CustomInput
                            type="text"
                            placeholder={"Code"}
                            name={"code"}
                            onChange={onChangeText}
                            onBlur={onBlur('code')}
                            value={values.code}
                            error={Boolean(touched.code && errors.code)}
                            errorMessage={(touched.code && errors.code) ? errors.code : undefined}
                            disabled={isSubmitting}
                            required={true}
                        />
                    </div>
                    <div className="popup-form_item">
                        <p>New Password</p>
                        <CustomInput
                            type={isPassVisiblePass ? 'text' : 'password'}
                            placeholder={"New Password"}
                            name={"password"}
                            onChange={onChangeText}
                            onBlur={onBlur('password')}
                            value={values.password}
                            error={Boolean(touched.password && errors.password)}
                            errorMessage={(touched.password && errors.password) ? errors.password : undefined}
                            disabled={isSubmitting}
                            required={true}
                            disableCopyPaste={true}
                        />
                        <div className="eye-block" onClick={() => setVisibilityPass(!isPassVisiblePass)}>
                            <img src={isPassVisiblePass ? eye : eyeOff} alt="eye" />
                        </div>
                    </div>
                    <div className="popup-form_item">
                        <p>Confirm New Password</p>
                        <CustomInput
                            type={isConfirmPassVisible ? 'text' : 'password'}
                            placeholder={"Confirm New Password"}
                            name={"confirmPassword"}
                            onChange={onChangeText}
                            onBlur={onBlur('confirmPassword')}
                            value={values.confirmPassword}
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            errorMessage={(touched.confirmPassword && errors.confirmPassword) ? errors.confirmPassword : undefined}
                            disabled={isSubmitting}
                            required={true}
                            disableCopyPaste={true}
                        />
                        <div className="eye-block" onClick={() => setVisibilityConfirmPass(!isConfirmPassVisible)}>
                            <img src={isConfirmPassVisible ? eye : eyeOff} alt="eye" />
                        </div>
                    </div>
                </fieldset>
                <div className="form-btn">
                    <button type="submit"
                        disabled={!isCodeSent || isSubmitting || !isValid || !dirty}
                        className="btn save-btn">Submit</button>
                    <button className="btn cancel-btn" onClick={goToLogin}>Cancel</button>
                </div>
            </form>
        )
    };

    return (
        // TODO: Can replace LoginForm with inner divs by CustomPopup
        <LoginForm>
            <div className="popup-body_title">
                <p>Forgot Password</p>
            </div>
            <div className="popup-form">
                <Formik
                    initialValues={{ email: '' }}
                    onSubmit={formSubmission<ForgotPassFormInterface>(submitForgotPassForm)}
                    validationSchema={validationForgotPassSchema()}
                >
                    {renderForgotPasswordForm}
                </Formik>
            </div>
            <div className="popup-form">
                <Formik
                    initialValues={{ code: '', password: '', confirmPassword: '' }}
                    onSubmit={formSubmission<ResetPassFormInterface>(submitResetPassForm)}
                    validationSchema={validationResetPassSchema()}
                >
                    {renderResetPasswordForm}
                </Formik>
            </div>
        </LoginForm>
    )
});

export default ResetPassword;