import React, {FunctionComponent, memo} from 'react';
interface Props {
  children: any
}

const LoginForm: FunctionComponent<Props> = memo(({ children }) => {
  return (
    <div className='login popup'>
      <div className="login-form_block">
        { children }
      </div>
    </div>
  );
});

export default LoginForm;