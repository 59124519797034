import React, {FunctionComponent, memo, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import CustomPopup from "../../../CustomPopup";
import DeviceInfo from "../../../DeviceInfo";
import DeviceForm from "../DeviceForm";
import useCurrentDevice from "../../../../../hooks/useCurrentDevice";
import { DeviceFormValues } from "../interfaces/device-form.interface";
import { keyListToTags, getKeyList } from "../../../../../utils/tags";
import { deviceService } from "../../../../../services";
import { Device}  from "../../../../../interfaces/device.interface";
import useTags from "../../../../../hooks/useTags";
import useDevicePagePoll from "../../../../../hooks/useDevicePagePoll";
import DeviceTagForm from "../DeviceTagForm";
import { addDevice, updateDevice, deleteDevice } from "../devices";
import DeviceLocation from "../DeviceLocation";
import Progress from "../../../ProgressBar";
import useUserInfo from "../../../../../hooks/useUserInfo";
import AssignConfiguration from "../../Configurations/AssignConfiguration";

const DevicePage: FunctionComponent = memo(() => {
  // const customerId = 3;
  // const assetId = 1;
  const { sites, customerId } = useUserInfo();
  const navigate = useNavigate();
  const { deviceId } = useParams();

  useDevicePagePoll(deviceId);

  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [editDevice, setEditDevice] = useState<Device>();
  const [editTagState, setEditTag] = useState<boolean>(false);
  const [createPin, setCreatePin] = useState<boolean>(false);
  const [createConfig, setCreateConfig] = useState<boolean>(false);
  let device = useCurrentDevice();
  const allTags = useTags() || [];
  // const sites = useSitesByCustomer(customerId);

  useEffect(() => {

    return () => deviceService.updateDeviceStore(null);
  }, [])

  const updateData = (onComplete: () => void) => {
    deviceId && deviceService.getCurrentDevice(deviceId).subscribe({
      error: (error) => {
        console.error(error);
        onComplete();
      },
      // complete: () => setRefreshing(false)
      complete: () => onComplete()
    })
  };


  const initialValues = {
    customerId: customerId,
    deviceEUI: "",
    name: "",
    deviceType: "",
    siteId: sites && sites.length === 1 ? sites[0].id.toString() : "",
    tags: [],
    inactivityTimeout: 300
  };
  const onCompleteUpdate = () => updateData(() => closePopup());
  const onCompleteDelete = () => navigate("/device", { replace: true });

  const addSubmit = (values: DeviceFormValues) => submitForm(values, closePopup, true);
  const editSubmit = (values: DeviceFormValues) => submitForm(values, onCompleteUpdate, false);
  const deleteSubmit = () => device && deleteDevice(device, onCompleteDelete);

  const submitForm = (values: DeviceFormValues, onComplete: () => void, createBool: boolean) => {
    const { deviceEUI, deviceType, name, siteId, tags, inactivityTimeout } = values;
    const simplifiedTags = keyListToTags(allTags, tags);
    if (createBool) {
      const site = parseInt(siteId);
      let device = { name, deviceType, customerId: customerId ?? 3, deviceEUI, tags: simplifiedTags, siteId: site, inactivityTimeout }
      addDevice(device, onComplete)
    } else if (editTagState && editDevice) {
      let device = { ...editDevice, tags: simplifiedTags }
      updateDevice(device, onComplete)
    } else if (editDevice) {
      const site = parseInt(siteId);
      let device = { ...editDevice, deviceEUI, name, tags: simplifiedTags, siteId: site, inactivityTimeout }
      updateDevice(device, onComplete);
    }
  };

  const getEditInitialValue = (item?: Device): DeviceFormValues => {
    if (item) {
      let tagsKeyList = getKeyList(item.tags);
      let site = item.siteId?.toString() || "";
      return { ...item, tags: tagsKeyList, siteId: site }
    }
    else return initialValues;
  }

  const editFunc = () => {
    setPopup({ isOpen: true, type: "edit" });
    device && setEditDevice(device);
  };

  const editTag = () => {
    device && setEditDevice(device);
    setEditTag(true);
  };

  const closePopup = () => {
    setPopup({ ...popup, isOpen: false });
    setEditTag(false);
    setCreateConfig(false);
  };


  return (
    <>
      <BreadCrumbs
        currentPage={'Device List'}
        currentItem={device?.name ?? "Device Name"}
        buttonName={"Device"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
      />
      {
        device ? <div className="device-page">
          <DeviceInfo device={device} editFunc={editFunc} deleteDevice={deleteSubmit} editTag={editTag} createConfig={() => setCreateConfig(true)} />
          <DeviceLocation device={device} addPin={createPin} setPin={setCreatePin} mapId={device.mapId} />
          {/*<Location device={true} />*/}
        </div>
          : <Progress/>
      }
      {popup.isOpen &&
        <CustomPopup title={"Device"} titlePrefix={popup.type} closeFunc={closePopup} classes='device-popup'>
          {(popup.type === "create")
            ? <DeviceForm closeFunc={closePopup} initialValues={initialValues} submitForm={addSubmit} tags={allTags} sites={sites ?? []} />
            : <DeviceForm closeFunc={closePopup} initialValues={getEditInitialValue(editDevice)} submitForm={editSubmit}
              isEdit={true} tags={allTags} sites={sites ?? []} />
          }
        </CustomPopup>}
      {editTagState &&
        <CustomPopup title={"Tag"} titlePrefix={"edit"} closeFunc={closePopup}>
          <DeviceTagForm closeFunc={closePopup} initialValues={getEditInitialValue(editDevice)} submitForm={editSubmit}
            isEdit={true} tags={allTags} sites={device?.siteId ?? 1} />
        </CustomPopup>
      }
      {createConfig &&
      <CustomPopup title={"Configure Devices"} closeFunc={closePopup} classes='configurations-popup'>
        <AssignConfiguration closeFunc={closePopup} initialValues={{ configuration: "", devices: device ? [device] : [] }} />
      </CustomPopup>
      }

    </>
  )
});

export default DevicePage;