import { catchError, map, Observable, throwError } from "rxjs";
import { ajax } from "rxjs/ajax";
import { switchMap } from "rxjs/operators";
import { urlCreator } from "../utils/url-creator";
import { HttpOptions } from "../interfaces/http-options";
import { authService } from "../services";

const DOMAIN = process.env.REACT_APP_API_DOMAIN;

const config = (token: string, path: string, urlParams?: {[key: string]: string}) => ({
  url: urlCreator(`${DOMAIN}${path}`, urlParams),
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  },
  crossDomain: true,
  createXHR: function () {
    return new XMLHttpRequest();
  },
});
const fileConfig = (token: string, path: string, urlParams?: {[key: string]: string}) => ({
  url: urlCreator(`${DOMAIN}${path}`, urlParams),
  headers: {
    'Authorization': `Bearer ${token}`,
  },
  crossDomain: true,
  createXHR: function () {
    return new XMLHttpRequest();
  },
});

export const Api = {
  get: (path: string, options?: HttpOptions): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        return ajax({...config(token, path, options?.urlParams)})
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },
  post: (path: string,  body: any, file?: boolean): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        const ajaxConfig = !file ? {...config(token, path),
          method: 'POST',
          body } : {
          ...fileConfig(token, path),
          method: 'POST',
          body
        }
        return ajax(ajaxConfig)
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },
  put: (path: string,  body: any, options?: any): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        return ajax({
          ...config(token, path),
          method: 'PUT',
          body
        })
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },
  delete: (path: string, options?: HttpOptions): Observable<any> => {
    return authService.getSession().pipe(
      switchMap(data => {
        const token = data.getIdToken().getJwtToken();
        return ajax({
          ...config(token, path, options?.urlParams),
          method: 'DELETE',
        })
      }),
      catchError(e => {
        const error = e.response;
        return throwError(error || e);
      }),
      map(result => result.response)
    );
  },
};