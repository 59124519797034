import {BehaviorSubject, catchError, Observable, switchMap, throwError} from "rxjs";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { Api } from "../../api/api";
import { errorService } from "../index";
import { Settings } from "../../interfaces/settings.interface";
import {tap} from "rxjs/operators";
import {defaultUserSettingsConstant} from "../../constants/default-user-settings.constant";

const SettingsService = () => {
  const settings$ = new BehaviorSubject<Settings>(defaultUserSettingsConstant);

  const addSettings = (body: Settings): Observable<any> => {
    return Api.post(API_ROUTES.SETTINGS, body).pipe(
      switchMap(() => getSettings()),
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  }

  const updateSettings = (body: Settings): Observable<any> => {
    return Api.put(API_ROUTES.SETTINGS, body).pipe(
      switchMap(() => getSettings()),
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  }

  const getSettings = (): Observable<Settings> => {
    return Api.get(API_ROUTES.SETTINGS).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      tap((settings) => settings$.next(settings))
    );
  };

  return {
    settings: settings$.asObservable(),
    getSettings,
    addSettings,
    updateSettings,
  }
};

const singleton = SettingsService();
export default Object.freeze(singleton);
