import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { errorService } from '../services';
import useAuthState from '../hooks/useAuthState'

interface PrivateRouteInterface {
    expectedStatus: string,
    redirectTo: string,
    errorMessage?: string,
}
const PrivateRoute = ({ expectedStatus, redirectTo, errorMessage }: PrivateRouteInterface) => {
    const state = useAuthState();
    const location = useLocation();

    const render = (state: string) => {
        if (state === expectedStatus) {
            return <Outlet />
        }
        else {
            errorMessage && errorService.addError({ message: errorMessage });
            return <Navigate to={redirectTo} replace state={{ from: location }} />
        }
    }

    return state && render(state)
};

export default PrivateRoute;