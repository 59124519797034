import { CognitoUser } from "amazon-cognito-identity-js";
import {BehaviorSubject, catchError, from, map, Observable, of, take, throwError} from "rxjs";
import { VERIFICATION_STATE } from "../../constants/verification-state.enum";
import { AuthApi } from "../../api/auth.api";
import { CognitoUserExtended } from "../../constants/cognito-user";
import { CHALLANGE_NAME } from "../../constants/challange-names.enum";
import { errorService } from "../index";
import { switchMap, tap } from "rxjs/operators";
import { Api } from "../../api/api";
import { API_ROUTES } from "../../constants/api-routes.constant";


const AuthService = () => {
  const store$ = new BehaviorSubject<string>(VERIFICATION_STATE.PENDING);
  const storedUser$ = new BehaviorSubject<CognitoUser|null>(null);
  const storedUserRole$ = new BehaviorSubject<string|null>(null);

  const updateStatus = (status: string) => {
    store$.next(status);
  };

  const storedUserRole = (role: string | null) => storedUserRole$.next(role);

  const signIn = (email: string, password: string): Observable<CognitoUserExtended | any> => {
    return from(AuthApi.signIn(email, password)).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  };

  const signInProcess = (email: string, password: string): Observable<CognitoUserExtended | null> => {
    return signIn(email, password).pipe(
      map((user: CognitoUserExtended) => {
        if (user.challengeName === CHALLANGE_NAME.NEW_PASSWORD_REQUIRED) {
          return user;
        }
        /*console.log("signInProcess", user);
        const device = user.attributes["custom:device"];
        const site = user.attributes["custom:site"];
        const customer = user.attributes["custom:customer"];*/
        // console.log(user.signInUserSession.idToken.jwtToken);
        // const userRoleStatus = userRole(device, site, customer);
        // storedUserRole(userRoleStatus);
        updateStatus(VERIFICATION_STATE.AUTHORIZED);
        return null;
      })
    );
  };

  const getSession = (): Observable<any> => {
    return from(AuthApi.currentSession()).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  };

  const getCurrentUser = (): Observable<any> => {
    return Api.get(`${API_ROUTES.USER.CURRENT_USER}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      tap((user) => storedUserRole(user.permission || null))
    );
  };

  const getStatus = (): Observable<any> => {
    return getSession().pipe(
      catchError(e => of(false)),
      tap(value => {
        updateStatus(value ? VERIFICATION_STATE.AUTHORIZED : VERIFICATION_STATE.NOT_AUTHORIZED)
      }),
      switchMap(() => store$.asObservable())
    )
  };

  const confirmPass = (password: string, requiredAttributes: any): Observable<CognitoUser | any> => {
    return storedUser$.asObservable().pipe(
      take(1),
      switchMap((user: CognitoUser|null) => {
        return user ?
          from(AuthApi.completeNewPassword(user, password, requiredAttributes))
          : throwError({})
      }),
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
      tap(() => {
        storedUser$.next(null);
        updateStatus(VERIFICATION_STATE.AUTHORIZED)
      })
    );
  };

  const signOut = (): Observable<any> => {
    return from(AuthApi.signOut()).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  };

  const signOutProcess = (): Observable<any> => {
    return signOut().pipe(
      tap(() => updateStatus(VERIFICATION_STATE.NOT_AUTHORIZED)),
      tap(() => {
        storedUserRole(null);
      }),
    );
  };

  const forgotPassword = (email: string): Observable<any> => {
    return from(AuthApi.forgotPassword(email)).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  };

  const forgotPasswordSubmit = (email: string, code: string, password: string): Observable<any> => {
    return from(AuthApi.forgotPasswordSubmit(email, code, password)).pipe(
      take(1),
      catchError(error => {
        errorService.addError(error);
        return throwError(error);
      }),
    );
  };

  const storeUser = (user: CognitoUser) => storedUser$.next(user);

  return {
    store: store$.asObservable(),
    storedUserRole: storedUserRole$.asObservable(),
    signIn,
    storeUser,
    signInProcess,
    signOut,
    signOutProcess,
    getSession,
    getCurrentUser,
    confirmPass,
    getStatus,
    forgotPassword,
    forgotPasswordSubmit
  };
};

const singleton = AuthService();
export default Object.freeze(singleton);