import React, { FunctionComponent, memo } from "react";
import { Formik } from "formik";
import { FormInterface } from "../../../interfaces/form.interface";
import { formSubmission } from "../../../utils/form-submission";

const CustomForm: FunctionComponent<FormInterface> = memo((
    { validationSchema, submitForm, initialValues, renderForm }) => {
    return (
        <div className="popup-form">
            <Formik
                initialValues={initialValues}
                onSubmit={formSubmission<any>(submitForm)}
                validationSchema={validationSchema && validationSchema()}
            >
                {renderForm}
            </Formik>
        </div>
    )
});

export default CustomForm;