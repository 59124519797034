import React, { FunctionComponent } from "react";
import { CircularProgress } from "@mui/material";

interface Props {
    isFullScreen?: boolean;
    size?: number;
}

const Progress: FunctionComponent<Props> = ({ isFullScreen, size }) => {
    return (
        <div className={`progress-bar ${isFullScreen && 'progress-bar_full'}`}>
            <CircularProgress color="inherit" size={ size ?? 40 } />
        </div>
    );
};

export default Progress;