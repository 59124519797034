import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useUserRole from '../hooks/useUserRole';
import NotFoundPage from '../Pages/components/pages/NotFoundPage';
import { canSeePage } from "../helpers/userHelper";
import useUserInfo from "../hooks/useUserInfo";
import Progress from "../Pages/components/ProgressBar";

interface ProtectedRouteInterface {
    role?: string
}

const ProtectedRoute = ({ role }: ProtectedRouteInterface) => {
    useUserInfo();
    const permission = useUserRole();
    const location = useLocation();
    return permission
        ? !canSeePage(permission, location.pathname)
            ? <NotFoundPage />
            : <Outlet />
        : <Progress isFullScreen/>
};

export default ProtectedRoute;