import React, { FunctionComponent } from "react";
import { AssetDeleteFormInterface } from "../../../../interfaces/form.interface";

const AssetDeleteForm: FunctionComponent<AssetDeleteFormInterface> = ({ deviceList, roomName, closeFunc, submitFunc }) => {
  return (
    <div className="delete-popup">
      {deviceList.length > 0 ?
        <div className="delete-popup_assigned-devices">
          <p>You want to delete the {roomName} that is paired with {deviceList.length > 0 ? deviceList.length : 0} devices. Are you sure to unassign paired devices and delete the room?</p>
          <div className="devices-list">
            {
              deviceList.map((item, index) => {
                return (
                  <p key={index} className="assigned-device">{item}</p>
                )
              })
            }
          </div>
          <div className="form-btn">
            <button type="submit" className="btn delete-red-btn" onClick={submitFunc}>Unassign all devices</button>
            <button type="button" className="btn cancel-btn" onClick={closeFunc} >Cancel</button>
          </div>
        </div>
        :
        <div className="delete-popup_assigned-devices">
          <p>Are you sure you want to delete the room?</p>
          <div className="form-btn">
            <button type="submit" className="btn delete-red-btn" onClick={submitFunc}>delete</button>
            <button type="button" className="btn cancel-btn" onClick={closeFunc} >Cancel</button>
          </div>
        </div>
      }
    </div>
  )
}

export default AssetDeleteForm;