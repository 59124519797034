import React, { FunctionComponent, memo } from "react";
import { FormikProps } from "formik";
import CustomForm from '../../CustomForm'
import { CustomMultiSelect, makeSelectOptions } from '../../CustomSelect'
import { DeviceFormValues } from "./interfaces/device-form.interface";
import { DeviceAssetTagFormInterface } from "../../../../interfaces/form.interface";
import { getTagColorByName } from "../../../../utils/tags"
import { Tag } from "../../../../interfaces/tag.interface";


const DeviceTagForm: FunctionComponent<DeviceAssetTagFormInterface> = memo(({ closeFunc, isEdit, tags, sites, ...props }) => {
    const tagOptions = (tagArray?: any[]) => {
      if (tagArray) {
        return makeSelectOptions(tagArray, "keyName", "keyFriendlyName");
      }  else {
        return makeSelectOptions(tags, "keyName", "keyFriendlyName");
      }
    };

    const renderForm = ({
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
        isValid,
        dirty,
        handleSubmit,
    }: FormikProps<DeviceFormValues>) => {
        const onBlur = (name: string) => () => setFieldTouched(name);
        const onChangeTags = (name: string, values: string[]) => setFieldValue(name, values);
        const getTagOptionsBySiteId = (siteId: number, currentTag: string[]) => {
          const data = tags.filter((item: Tag) => item.siteId === siteId || item.keyName === currentTag[0]);
          return tagOptions(data);
        }
      return (
          <div className="map-popup_inputs">
            <div className="popup-form_item">
              <p>Tags</p>
              <CustomMultiSelect name="tags"
                                 placeholder="Tags"
                                 selectedClassName={`tags-item`}
                                 options={getTagOptionsBySiteId(sites, values.tags)}
                                 values={values.tags}
                                 onChange={onChangeTags}
                                 onBlur={onBlur('tags')}
                                 error={Boolean(touched.tags && errors.tags)}
                                 errorMessage={(touched.tags && errors.tags && typeof errors.tags === "string") ? errors.tags : undefined}
                                 disabled={isSubmitting}
                                 getColor={(name) => getTagColorByName(tags, name)}
              />
            </div>
            <div className="form-btn">
              <button type="submit" disabled={!dirty || isSubmitting || !isValid} onClick={() => handleSubmit()} className="btn save-btn" >Save</button>
              <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button>
            </div>
          </div>
        )
    };
    return <CustomForm renderForm={renderForm} {...props} />
});

export default DeviceTagForm;