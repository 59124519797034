import React from "react";
import plus from '../../../../style/assets/img/plus.png'

interface Button {
  onClick: (item: any) => void,
  value: string;
}

export default function ButtonAdd ({onClick, value}: Button) {
  const renderText = (value: string) => `add new ${value}`
  return (
    <button onClick={onClick} className='btn-add'>
      {renderText(value)}
      <img src={plus} alt="plus"/>
    </button>
  )
}