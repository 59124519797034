import { Configuration, NewConfiguration } from "../../../../interfaces/configurations.interface";
import { deviceConfigurationService, messageService, errorService } from "../../../../services";

export const addConfiguration = (configuration: NewConfiguration, onComplete: () => void) => {
    deviceConfigurationService.createConfiguration(configuration).subscribe({
        next: () => {
            messageService.addMessage(`Configuration was created!`);
        },
        error: (error) => {
            errorService.addError({ message: `Failed to add configuration! ` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
}

export const updateConfiguration = (configuration: Configuration, onComplete: () => void) => {
    deviceConfigurationService.updateConfiguration(configuration).subscribe({
        next: () => {
            messageService.addMessage(`Configuration was updated!`);
        },
        error: (error) => {
            errorService.addError({ message: `Failed to update configuration!` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
}

export const deleteConfiguration = (configurationId: number | undefined, onComplete: () => void) => {
    configurationId && deviceConfigurationService.deleteConfiguration(configurationId).subscribe({
        next: () => {
            messageService.addMessage(`Configuration was removed successfully!`);
        },
        error: (error) => {
            errorService.addError({ message: `Failed to remove configuration!` + error.messageText });
            onComplete();
        },
        complete: () => onComplete()
    });
}