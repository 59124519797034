import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { Api } from "../../api/api";
import { errorService } from "../index";
import { Customer } from "../../interfaces/customer.interface";

const CustomerService = () => {

    const customers$ = new BehaviorSubject<any[] | null>(null);

    const getAllCustomers = (): Observable<Customer[]> => {
        return Api.get(API_ROUTES.CUSTOMER.GET_ALL_CUSTOMERS).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            })
        );
    };

    const getCustomerById = (id: number): Observable<Customer> => {
        return Api.get(`${API_ROUTES.CUSTOMER.GET_CUSTOMER_BY_ID}/${id}`).pipe(
            catchError(e => {
                errorService.addError(e);
                return throwError(e);
            })
        );
    };

    return {
        customers: customers$.asObservable(),
        getAllCustomers,
        getCustomerById
    }
};

const singleton = CustomerService();

export default Object.freeze(singleton);