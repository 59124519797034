import React from "react";
import CustomTooltip from "./CustomTooltip";
import { Tag, SimplifiedTag } from "../../interfaces/tag.interface";
import { getTagColorByName } from "../../utils/tags"

const CO2_OK_MAX = 1000;
const CO2_CAUTION_MAX = 1800;

export const displayTags = (tags: SimplifiedTag[], allTags: Tag[]) => {
    if (tags.length > 0) {
        return (
            tags.map((tag, index) =>
              <div className={`tag`} style={{background: `${getTagColorByName(allTags, tag.keyName)}`}} key={index}>{tag.keyFriendlyName}</div>
            )
        )
    } else {
        return "-"
    }
};

export const displayDeviceStatus = (value: string) => {
    return <CustomTooltip title={value}>
        <div className={`status-img ${value}`} />
    </CustomTooltip>
}

export const displayBatteryLevel = (value: number, measurement: string) => {
    const getBatteryLevelClass = () => {
        if (value > 75) return "battery-full"
        else if (value > 50) return "battery-medium-high"
        else if (value > 25) return "battery-medium-low"
        else if (value >= 0) return "battery-empty"
        else return "";
    }
    return <CustomTooltip title={`${value}${measurement} battery level`}>
        <div className={`battery-level-img ${getBatteryLevelClass()}`} />
    </CustomTooltip>
}

export const getCO2LevelClass = (value: number) => {
    if (value >= CO2_CAUTION_MAX) return "hazard-level";
    else if (value >= CO2_OK_MAX) return "caution-level";
    else if (value >= 0) return "ok-level";
    else return "";
}

export const displayCO2Level = (value: number, measurement: string | null) => {
    return <div className="co2-value">
        <div className={`co2-level-img ${getCO2LevelClass(value)}`} />
        <span>{value}{measurement || ""}</span>
    </div>
}
