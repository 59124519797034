import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { switchMap, tap } from "rxjs/operators";
import { Api } from "../../api/api";
import { errorService } from "../index";
import { Device, FilterQuery, NewDevice } from "../../interfaces/device.interface";

const DeviceService = () => {
  const device$ = new BehaviorSubject<Device | null>(null);
  const devices$ = new BehaviorSubject<Device[] | null>(null);

  const updateDeviceStore = (param: Device | null) => {
    device$.next(param);
  };

  const updateDevicesStore = (param: Device[] | null) => {
    devices$.next(param);
  };

  const createDevice = (body: NewDevice): Observable<any> => {
    return Api.post(API_ROUTES.DEVICE.CREATE_DEVICE, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const createDeviceByCSV = (file: any): Observable<any> => {
    return Api.post(`${API_ROUTES.DEVICE.CREATE_DEVICE}/file`, file, true).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const getDeviceByCustomer = (options?: any): Observable<Device[] | null> => {
    const urlParams = { ...options };
    return Api.get(API_ROUTES.DEVICE.GET_DEVICE_BY_CUSTOMER,
      urlParams ? { urlParams } : undefined
    ).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((devices: Device[]) => {
        return devices || null
      }),
      tap(updateDevicesStore)
    );
  };

  const getCurrentDevice = (deviceEUI: string): Observable<Device | null> => {
    return Api.get(`${API_ROUTES.DEVICE.GET_CURRENT_DEVICE}/${deviceEUI}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((device: Device) => {
        return device || null
      }),
      tap(updateDeviceStore)
    );
  };

  const getFreeDevices = (): Observable<any> => {
    return Api.get(API_ROUTES.DEVICE.GET_FREE_DEVICES).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    )
  };

  const getFiltersParams = (): Observable<any> => {
    return Api.get(API_ROUTES.DEVICE.GET_FILTER_PARAMS).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    )
  };

  const getFilteredData = (params: FilterQuery): Observable<any> => {
    return Api.post(API_ROUTES.DEVICE.GET_FILTER_DATA, params).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      map((devices: Device[]) => {
      return devices || null
      }),
      tap(updateDevicesStore)
    );
  };

  const updateDevice = (body: NewDevice): Observable<any> => {
    return Api.put(API_ROUTES.DEVICE.UPDATE_DEVICE, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
      tap(() => updateDeviceStore(null))
    );
  };

  const updateDeviceFlow = (body: NewDevice): Observable<any> => {
    return updateDevice(body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const deleteDevice = (id: string): Observable<any> => {
    return Api.delete(`${API_ROUTES.DEVICE.DELETE_DEVICE}/${id}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const deleteDeviceFlow = (id: string): Observable<any> => {
    return deleteDevice(id).pipe(
      switchMap(() => getDeviceByCustomer({ sortingParameter: 'installDate', sortingType: 'DESC' })),
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  return {
    device: device$.asObservable(),
    devices: devices$.asObservable(),
    createDevice,
    createDeviceByCSV,
    getDeviceByCustomer,
    getCurrentDevice,
    getFreeDevices,
    getFiltersParams,
    getFilteredData,
    updateDevice,
    updateDeviceStore,
    updateDevicesStore,
    updateDeviceFlow,
    deleteDevice,
    deleteDeviceFlow,
  }
};

const singleton = DeviceService();
export default Object.freeze(singleton);