import { Subject } from "rxjs";

const ErrorService = () => {
  const store$ = new Subject<any>();

  const addError = (error: any): void => {
    store$.next(error);
  };

  return {
    store: store$.asObservable(),
    addError,
  };
};

const singleton = ErrorService();
export default Object.freeze(singleton);