import React, {FunctionComponent, memo} from "react";
import location from "../../../style/assets/img/location.png";

interface Props {
  device: boolean;
}

const Location: FunctionComponent<Props> = memo(({device}) => {
  return (
    <div className="device-location">
      <p className="title">{device ? "Device location" : "Room location"}</p>
      <img src={location} alt="location"/>
    </div>
  )
});

export default Location;