import React, { FunctionComponent, memo, useState, useEffect } from "react";
import CustomInput from "../CustomInput"
import FilterForm from "./FilterForm";
import { FilterItem } from "../../../interfaces/filter.interface"
import { FilterFormValues } from "./interfaces/filter-form.interface"
import { FilterValue } from "../../../interfaces/device.interface";
import close from "../../../style/assets/img/close-icon.png";
import CustomTooltip from "../CustomTooltip";

interface Props {
  searchFunc: (data: FilterValue) => void;
  filterData: FilterItem[];
  defaultDataFunc: () => void;
}

const createInitialValues = (filterData: FilterItem[]) => {
  let initialValues: { [key: string]: string[] } = {};
  filterData.map(item => initialValues[item.name] = [])
  return initialValues;
};

const defaultFilterItem = (filterData: FilterItem[]) => {
  let newData = [];
  for (const filterItem of filterData) {
    if (filterItem.options.length > 0) {
      newData.push(filterItem);
    }
  }
  return newData;
};

const FilterPanel: FunctionComponent<Props> = memo(({ searchFunc, filterData, defaultDataFunc }) => {
  const [search, setSearch] = useState<string>("");
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
  const [filterObject, setFilterObject] = useState<any>({});
  const [filterCount, setFilterCount] = useState(0);
  const [isDisable, setDisable] = useState<boolean>(true);

  useEffect(() => {
    let newData = defaultFilterItem(filterData);
    setFilterItems(newData);
    setFilterObject(createInitialValues(newData));
    setFilterCount(0);
  }, [filterData]);

  useEffect(() => {
    const data = Object.keys(filterObject);
    const savedValue = data.map((item) => filterObject[item].length > 0);
    if (savedValue.includes(true)) {
      setDisable(false);
    } else if (search.length > 2) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [filterObject, search]);

  const searchChangeFunc = (name: string, value: string) => {
    const data = Object.keys(filterObject);
    const savedValue = data.map((item) => filterObject[item].length > 0);
    setSearch(value);
    if (value.length === 0 && !savedValue.includes(true)) {
      setTimeout(() => {
        defaultDataFunc();
      }, 2000)
    }
  }

  const toggleFilterPanel = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const submitFilterForm = (values: FilterFormValues) => {
    setFilterObject(values);
    let count = 0;
    Object.keys(values).map(key => values[key].length > 0 && count++);
    setFilterCount(count);
  };

  const clearData = () => {
    setSearch('');
    setFilterCount(0);
    let newData = defaultFilterItem(filterData);
    setFilterObject(createInitialValues(newData));
    defaultDataFunc();
  };

  const submitFunc = () => searchFunc({ ...filterObject, search: search });

  return (
    <div className={`search-panel ${isOpenFilter ? "search-panel_active-filter" : ""}`}>
      <div className="search-block">
        <CustomInput
          type="search"
          name={"search"}
          placeholder={"Search"}
          value={search}
          onChange={searchChangeFunc}
          className="column-search"
          required={true} />
        <div className="search-block_buttons">
          {filterItems.length > 0
            ? !isOpenFilter
              ? <button className={`btn filter-btn filter-btn__open ${filterCount > 0 ? 'filter-btn_active' : ''}`} onClick={toggleFilterPanel} >
                Filters
                { filterCount > 0 && <span className="filter-count">{filterCount}</span> }
              </button>
              : <button className="btn filter-btn filter-btn__close" onClick={toggleFilterPanel} >
                Close Filter
                <img className="filter-btn_icon" src={close} alt="close filter" />
              </button>
            : null
          }
          <div className="pipe"/>
          <CustomTooltip title="SEARCH" position="right" withArrow={false}>
            <button className="btn search-btn" disabled={isDisable} onClick={submitFunc}/>
          </CustomTooltip>
        </div>
      </div>
      <FilterForm
        filterItems={filterItems}
        initialValues={filterObject}
        submitForm={submitFilterForm}
        resetData={clearData}
        search={search}
        filterCount={filterCount}
      />
    </div>
  )
});

export default FilterPanel;