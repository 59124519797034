import { useEffect, useState } from "react";
import { tagService } from "../../services";
import { Tag } from "../../interfaces/tag.interface";

const useTags = () => {
  const [state, setState] = useState<Tag[] | null>(null);

  useEffect(() => {
    const sub = tagService.getTagsByCustomer({
      pageNumber: 0,
      pageSize: 100,
      sortingParameter: 'id',
      sortingType: 'DESC'
    }).subscribe(setState);
    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useTags;