import { useEffect, useState } from "react";
import { settingsService } from "../../services";
import { Settings } from "../../interfaces/settings.interface";
import { defaultUserSettingsConstant } from "../../constants/default-user-settings.constant";

const useSettingsStore = () => {
  const [state, setState] = useState<Settings>(defaultUserSettingsConstant);

  useEffect(() => {
    const sub = settingsService.settings.subscribe(setState)

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useSettingsStore;