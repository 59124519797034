import React, { FunctionComponent, memo, FocusEvent, useState } from "react";
import { SelectOption } from "../select.interface"

interface Props {
    name: string;
    value: string;
    options: SelectOption[],
    placeholder?: string;
    className?: string;
    onChange: (name: string, value: string) => void;
    onBlur?: (event: FocusEvent<HTMLElement>) => void;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    errorMessage?: string;
}

const CustomSelect: FunctionComponent<Props> = memo(({
    name, value, options, placeholder, onChange, onBlur,
    className, disabled, required, error, errorMessage }) => {

    const [openOptions, setOpenOptions] = useState(false);
    const closeOptions = () => { setOpenOptions(false) }

    const handleChange = (event: any, value: string) => {
        event.stopPropagation();
        onChange(name, value);
        closeOptions();
    }

    return (
        <div className={`custom-select-block ${disabled ? "disabled" : ""}`} tabIndex={0} onBlur={onBlur}>
            <div className={`custom-select ${className ? className : ""} ${error ? 'error' : ""}`} tabIndex={0}
                onClick={() => { setOpenOptions(!openOptions) }} onBlur={closeOptions}>
                {value ? <span>{options.find(option => option.value === value)?.label}</span> : <span>{placeholder}</span>}
                {openOptions &&
                    <div className="custom-select__options">
                        {options.length > 0
                            ? options.map((option, index) => {
                                return (
                                    <div className={`custom-select__option ${option.value === value} ? ":hover" : ""`}
                                        onClick={(e) => handleChange(e, option.value)} key={index}>
                                        <span>{option.label}</span>
                                    </div>
                                )
                            })
                            : <div className="custom-select__option"></div>
                        }
                    </div>}
            </div>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    )
});

export default CustomSelect;