import React, { FunctionComponent, memo } from "react";
import { SimplifiedTag, Tag } from "../../../interfaces/tag.interface";
import { getTagColorByName } from "../../../utils/tags";
import useUserRole from "../../../hooks/useUserRole";
import { canShow } from "../../../helpers/userHelper";

interface Props {
    tags: SimplifiedTag[];
    allTags: Tag[];
    editTagFunc: () => void;
}

const TagPanel: FunctionComponent<Props> = memo(({ tags, allTags, editTagFunc }) => {
    const permission = useUserRole();
    return (
        <div className="device-tags-block">
            {tags.map((tag, index) =>
                <div className={`tags-item`} style={{ background: `${getTagColorByName(allTags, tag.keyName)}` }} key={index}>{tag.keyFriendlyName}</div>
            )}
            {
                canShow(permission) ? <button className="btn add-tag-btn" onClick={editTagFunc} /> : null
            }
        </div>
    )
});

export default TagPanel;