import React, { FunctionComponent, memo, FocusEvent, ChangeEvent } from "react";

interface Props {
  value: string | number;
  onChange: (name: string, value: any) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  name: string;
  disableCopyPaste?: boolean;
  autoFocus?: boolean;
  onChangeWithEvent?: (event: any, name: string, value: any) => void;
}

const CustomInput: FunctionComponent<Props> = memo(({
  value, onChange, onBlur, type = 'text', placeholder, error, errorMessage, 
  disabled, required, className = 'form-input', name, disableCopyPaste, autoFocus, onChangeWithEvent
}) => {
  const valueChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeWithEvent ? onChangeWithEvent(event, event.target.name, type !== 'number' ? event.target.value : event.target.valueAsNumber)
      : onChange(event.target.name, type !== 'number' ? event.target.value : event.target.valueAsNumber)
  }
  const textareaValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.name, event.target.value);
  }
  const forbidAction = (e: any) => {
    if (disableCopyPaste) e.preventDefault();
  }
  // return <input type="text" className="form-input" placeholder="Value"/>
  return (
    <>
      {type === "textarea"
        ? <textarea
            className={`${className} ${error ? 'error' : ''}`}
            placeholder={placeholder ? placeholder : 'Value'}
            onChange={textareaValueChange}
            value={value}
            disabled={disabled}
            required={required}
            name={name}
            onBlur={onBlur}
            onCopy={forbidAction}
            onPaste={forbidAction}
            autoFocus={autoFocus}
          />
        : <input
            type={type ? type : 'text'}
            className={`${className} ${error ? 'error' : ''}`}
            placeholder={placeholder ? placeholder : 'Value'}
            onChange={valueChange}
            value={value}
            disabled={disabled}
            required={required}
            name={name}
            onBlur={onBlur}
            onCopy={forbidAction}
            onPaste={forbidAction}
            autoFocus={autoFocus}
        />}
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </>
  )
})

export default CustomInput;