import React, { FunctionComponent, KeyboardEvent, useEffect, useRef, useState } from "react";
import uploadImg from "../../../../style/assets/img/upload.png"
import errorTriangle from "../../../../style/assets/img/error-triangle.png"
import uploadSecondImg from "../../../../style/assets/img/upload-2.png"
import { FileUploader } from "react-drag-drop-files";
import CustomInput from '../../CustomInput'
import CustomForm from '../../CustomForm'
import { IndoorMapFormInterface } from "../../../../interfaces/form.interface";
import { object as yupObject, string as yupString, mixed as yupMixed } from "yup";
import { validation } from "../../../../constants/errors/errors.constant";
import { CustomSelect, makeSelectOptions } from "../../CustomSelect";
import { FormikProps } from "formik";
import { MapFormValues } from "./interfaces/map-form.interface";
import ButtonDelete from "../../Buttons/ButtonDelete";
import { Device } from "../../../../interfaces/device.interface";

const validationSchema = () => yupObject().shape({
  name: yupString()
    .required(validation.isRequired("name"))
    .trim()
    .min(3, validation.minLength(3))
    .max(32, validation.maxLength(32)),
  siteId: yupString()
    .required(validation.isRequired("site")),
  file: yupMixed().required(validation.isRequired("file")),
});

const IndoorMapForm: FunctionComponent<IndoorMapFormInterface> = ({ closeFunc, sites, assignedDevices, ...props }) => {
    const siteOptions = makeSelectOptions(sites, "id", "name");
    const fileTypes = ["JPG", "PNG", "JPEG"];
    const [uploadErrors, setUploadErrors] = useState({type: false, size: false});
    const [deviceWithPosition, setDeviceWithPosition] = useState<Device[] | []>([]);
    const key = useRef(0);

    useEffect(() => {
      let deviceArrWithPosition = assignedDevices?.filter?.((item) => (item?.position?.x > 0 && item?.position?.y > 0) ? item : null);
      setDeviceWithPosition(deviceArrWithPosition ?? []);
    }, [assignedDevices]);

    const renderChildren = () => {
      return (
        <div className="popup-map_children">
          { uploadErrors.type || uploadErrors.size
            ? <div className="popup-map_children_error">
                <img src={errorTriangle} alt="upload-img"/>
                <p className="popup-map_children__title">Image cannot be uploaded</p>
                {uploadErrors.type
                  ? <p className="popup-map_children__formats">{`Invalid image format`}</p>
                  : <p className="popup-map_children__formats">{`Invalid image size`}</p>}
              </div>
            : <img src={uploadImg} alt="upload-img"/>
          }
          <p className="popup-map_children__title">Drag and drop your file here</p>
          <p className="popup-map_children__formats">You can upload only 1 image</p>
          <p className="popup-map_children__subtitle">or</p>
          <div className="blue-block">
            <p>Select a file</p>
            <img src={uploadSecondImg} alt="upload-img"/>
          </div>
          <p className="popup-map_children__formats">{`Supported image formats: .jpg, .jpeg, .png`}</p>
          <p className="popup-map_children__formats size-format">{`Maximum file size: 2MB`}</p>
        </div>
      );
    }

    const renderForm = ({
                          values,
                          setFieldValue,
                          touched,
                          errors,
                          setFieldTouched,
                          isSubmitting,
                          isValid,
                          dirty,
                          handleSubmit,
                        }: FormikProps<MapFormValues>) => {
      const onBlur = (name: string) => () => setFieldTouched(name);
      const onChangeText = (name: string, value: string) => setFieldValue(name, value);
      const onChangeFile = (file: any) => {
        key.current = key.current + 1;
        setUploadErrors({type: false, size: false})
        setFieldValue("file", file);
      };
      const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
          handleSubmit();
        }};
      return (
            <>
                <form className="map-popup-body" onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
                  <div className="popup-form_item map-popup_inputs">
                    <p>Name</p>
                    <CustomInput
                      name={"name"}
                      value={values.name}
                      onChange={onChangeText}
                      onBlur={onBlur('name')}
                      error={Boolean(touched.name && errors.name)}
                      errorMessage={(touched.name && errors.name) ? errors.name : undefined}
                      disabled={isSubmitting}
                      required={true}
                    />
                  </div>
                  <div className="popup-form_item map-popup_inputs">
                    <p>Site</p>
                    <CustomSelect
                      options={siteOptions}
                      placeholder={"Site"}
                      name={"siteId"}
                      onChange={(name, value) => {
                        onChangeText(name, value);
                      }}
                      onBlur={onBlur('siteId')}
                      value={values.siteId?.toString()}
                      error={Boolean(touched.siteId && errors.siteId)}
                      errorMessage={(touched.siteId && errors.siteId) ? errors.siteId : undefined}
                      disabled={siteOptions.length === 1 || isSubmitting}
                      required={true}
                    />
                  </div>
                  <div className="popup-form_uploader">
                    <FileUploader
                        key={key.current}
                        handleChange={onChangeFile}
                        name="file"
                        types={fileTypes}
                        maxSize={2}
                        classes={`drop_area ${Boolean(touched.file && errors.file) ? "error" : ""}`}
                        children={renderChildren()}
                        onTypeError={() => setUploadErrors({type: true, size: false})}
                        onSizeError={() => setUploadErrors({type: false, size: true})}
                        disabled={isSubmitting}
                        onBlur={onBlur('file')}
                    />
                  </div>
                  {
                    (props?.initialValues?.file?.name !== values?.file?.name && deviceWithPosition.length > 0) && <div className="errors-map-block">
                      {
                        <div className="error-code">
                          <p>{`${deviceWithPosition.length} device pin(s) is set for this map. When changing the map image, the pin(s) will be unassigned automatically.`}</p>
                        </div>
                      }
                    </div>
                  }
                  {
                    values.file && <div className="map-popup_inputs__file">
                      <div className="map-popup_inputs__file__name">
                        <p>{values.file.name}</p>
                      </div>
                      <ButtonDelete onClick={() => onChangeFile(null)}/>
                    </div>
                  }
                  <div className="form-btn">
                    <button type="submit" disabled={!dirty || isSubmitting || !isValid} className="btn save-btn">Save</button>
                    <button className="btn cancel-btn" onClick={closeFunc}>Cancel</button>
                  </div>
                </form>
            </>
        )
    };
    return <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />
}

export default IndoorMapForm;