import React, { FunctionComponent, KeyboardEvent } from "react";
import { object as yupObject, string as yupString } from 'yup';
import { validation, validationRegExps } from "../../../constants/errors/errors.constant";
import CustomInput from '../CustomInput'
import CustomForm from '../CustomForm'
import {AttributeFormInterface} from "../../../interfaces/form.interface";
import {AttributeFormValue} from "./interfaces/attribute-form.interface"
import {FormikProps} from "formik";

const validationSchema = () => yupObject().shape({
    key: yupString()
      .max(32, validation.maxLength(32))
      .matches(validationRegExps.formatRegExp, validation.invalidFormat("key"))
      .required(validation.isRequired("key")),
    value: yupString()
      .max(32, validation.maxLength(32))
      .matches(validationRegExps.formatRegExp, validation.invalidFormat("value"))
      .required(validation.isRequired("value"))
  });

const AttributeForm: FunctionComponent<AttributeFormInterface> = ({ closeFunc, isEdit, ...props }) => {

  const renderForm = ({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                        setFieldTouched,
                        isSubmitting,
                        isValid,
                        dirty,
                        handleSubmit,
                      }: FormikProps<AttributeFormValue>) => {
    const onBlur = (name: string) => () => setFieldTouched(name);
    const onChangeText = (name: string, value: string) => setFieldValue(name, value);
    const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
      if (event.key === 'Enter') {
        handleSubmit();
      }};
    return (
      <form className="map-popup_inputs" onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
        <div className="popup-form_item">
          <p>Key</p>
          <CustomInput
            name={"key"}
            placeholder={"Key"}
            value={values.key}
            onChange={onChangeText}
            onBlur={onBlur('key')}
            error={Boolean(touched.key && errors.key)}
            errorMessage={(touched.key && errors.key) ? errors.key : undefined}
            disabled={isSubmitting}
            required={true}
          />
        </div>
        <div className="popup-form_item">
          <p>Value</p>
          <CustomInput
            name={"value"}
            placeholder={"Value"}
            value={values.value}
            onChange={onChangeText}
            onBlur={onBlur('value')}
            error={Boolean(touched.value && errors.value)}
            errorMessage={(touched.value && errors.value) ? errors.value : undefined}
            disabled={isSubmitting}
            required={true}
          />
        </div>
        <div className="form-btn">
          <button type="submit" disabled={!dirty || isSubmitting || !isValid} className="btn save-btn" >Save</button>
          <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button>
        </div>
      </form>
    )
  };

    return <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />
}

export default AttributeForm;