import React, { FunctionComponent, memo, useState, useEffect } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import StaticTable from "../../StaticTable";
import { SiteViewerFormValues } from "./interfaces/site-viewer-form.interface";
import { User, NewUser, UpdateUser } from "../../../../interfaces/user.interface";
import { userService, messageService, errorService, indoorMapService } from "../../../../services";
import { siteViewerTableNames } from "../../../../constants/table-names.constant";
import useUserInfo from "../../../../hooks/useUserInfo";
import useCustomers from "../../../../hooks/useCustomers";
import SiteViewerForm from "./SiteViewerForm";
import CustomPopup from "../../CustomPopup";
import DeletePopup from "../../DeletePopup";
import ProgressBar from "../../ProgressBar";
import FilterPanel from "../../FilterPanel";
import { FilterValue } from "../../../../interfaces/device.interface";
import { activeFilterField } from "../../../../helpers/filterHelper";
import { makeSelectOptions } from "../../CustomSelect";

const SiteViewerList: FunctionComponent = memo(() => {
  const { permission, sites, customerId } = useUserInfo();
  const allCustomers = useCustomers();
  const [siteViewers, setSiteViewers] = useState<User[] | null>(null);
  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [isInProgress, setIsInProgress] = useState(false);
  const [filterParams, setFilterParams] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>([]);
  const [savedFilter, setSavedFilter] = useState<FilterValue | null>(null);
  const [filterStatus, setFilterStatus] = useState<boolean>( false);

  const initialValues = {
    userName: '',
    email: '',
    customerId: permission === "SiteAdmin" && customerId ? customerId.toString() : '',
    siteId: permission === "SiteAdmin" && sites && sites.length === 1 ? sites[0].id.toString() : '',
  };
  const [editSiteViewerValues, setEditSiteViewerValues] = useState<SiteViewerFormValues>(initialValues);
  const [deleteSiteViewerValues, setDeleteSiteViewerValues] = useState<SiteViewerFormValues>(initialValues);

  const uploadSiteViewersData = () =>
      userService.getAllUsers({ sortingParameter: 'createDate', sortingType: 'DESC' }).subscribe((allUsers) =>
      { setSiteViewers(getSiteViewers(allUsers)) });
  const uploadData = () => {
    if (filterStatus) {
      savedFilter && submitFilter(savedFilter);
    } else {
      uploadSiteViewersData();
    }
  };
  const uploadFilterParams = () => indoorMapService.getFiltersParams().subscribe(setFilterParams);

  useEffect(() => {
    uploadFilterParams();
    uploadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  useEffect(() => {
    uploadFilterData();
    // eslint-disable-next-line
  }, [filterParams]);

  useEffect(() => {
    !filterStatus && uploadData();
    // eslint-disable-next-line
  }, [filterStatus])

  const uploadFilterData = () => {
    const filterData = [
      {
        name: "siteName",
        friendlyName: "Sites",
        options: filterParams?.sites && filterParams.sites.length > 1 ? makeSelectOptions(filterParams.sites, "name", "name") : []
      },
    ];
    setFilterData(filterData);
  };

  const viewerRole = "Viewer";
  const skipFormFields = (permission === "SiteAdmin") ? ["customerId"] : undefined

  const getSiteViewers = (users: User[]) => users.filter(user => user.permission === viewerRole);

  const closePopup = () => setPopup({ ...popup, isOpen: false });

  const addSiteViewer = (item: NewUser, onComplete: () => void) => {
    userService.createUser(item).pipe().subscribe({
      next: (allUsers: User[]) => {
        uploadData();
        messageService.addMessage(`Site Viewer was created!`);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to add Site Viewer! ` + error.messageText });
        onComplete();
      },
      complete: () => onComplete()
    });
  };

  const updateSiteViewer = (item: UpdateUser, onComplete: () => void) => {
    userService.updateUser(item).pipe().subscribe({
      next: (allUsers: User[]) => {
        uploadData();
        messageService.addMessage(`Site Viewer was updated!`);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to update Site Viewer! ` + error.messageText });
        onComplete();
      },
      complete: () => onComplete()
    });
  };

  const deleteSiteViewer = (email: string, onComplete: () => void) => {
    userService.deleteUser(email).pipe().subscribe({
      next: (allUsers: User[]) => {
        uploadData();
        messageService.addMessage(`Site Viewer was removed successfully!`);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to remove Site Viewer! ` + error.messageText });
        onComplete();
      },
      complete: () => onComplete()
    });
  }

  const submitForm = (values: SiteViewerFormValues, onComplete: () => void, createBool: boolean) => {
    let siteViewer = {
      ...values,
      siteId: [parseInt(values.siteId)],
      customerId: parseInt(values.customerId),
      permission: viewerRole
    };
    createBool ? addSiteViewer(siteViewer, onComplete) : updateSiteViewer(siteViewer, onComplete);
  };

  const addSubmit = (values: SiteViewerFormValues) => submitForm(values, closePopup, true);
  const editSubmit = (values: SiteViewerFormValues) => submitForm(values, closePopup, false);

  const deleteSubmit = () => {
    setIsInProgress(true);
    deleteSiteViewer(deleteSiteViewerValues.email, () => {
      closePopup();
      setIsInProgress(false);
    });
  }

  const displayFunc = (key: string, value: any) => {
    switch (key) {
      case "customerId":
        return allCustomers.find(customer => customer.id === value)?.name || "---";
      case "sites":
        return value?.[0]?.name || "---";
      default:
        return value;
    }
  }

  const handleEditClick = (item: User) => {
    let values = {
      ...item,
      siteId: item.sites?.[0].id.toString(),
      customerId: item.customerId.toString()
    };
    setPopup({ isOpen: true, type: "edit" });
    setEditSiteViewerValues(values);
  }

  const handleDeleteClick = (values: SiteViewerFormValues) => {
    setPopup({ isOpen: true, type: "delete" });
    setDeleteSiteViewerValues(values);
  }

  const createTableNames = () => {
    if (permission === "SiteAdmin") {
      let { customerId, ...siteAdminTableNames } = siteViewerTableNames;
      return siteAdminTableNames;
    }
    return siteViewerTableNames;
  }

  const showPopup = () => {
    switch (popup.type) {
      case "delete":
        return <DeletePopup
          itemType="user"
          itemName={deleteSiteViewerValues.userName}
          handleSubmit={deleteSubmit} handleCancel={closePopup}
          disabled={isInProgress} />
      case "create":
        return (
          <CustomPopup title={"Site Viewer"} titlePrefix={popup.type} closeFunc={closePopup}>
            <SiteViewerForm
              closeFunc={closePopup}
              initialValues={initialValues}
              submitForm={addSubmit}
              skipFields={skipFormFields}
              customers={allCustomers}
              sites={sites ?? []}
            />
          </CustomPopup>
        )
      case "edit":
        return (
          <CustomPopup title={"Site Viewer"} titlePrefix={popup.type} closeFunc={closePopup}>
            <SiteViewerForm
              closeFunc={closePopup}
              initialValues={editSiteViewerValues}
              submitForm={editSubmit}
              isEdit={true}
              skipFields={skipFormFields}
              customers={allCustomers}
              sites={sites ?? []}
            />
          </CustomPopup>
        )
    }
  };

  const submitFilter = (data: FilterValue) => {
    setSiteViewers(null);
    setFilterStatus(true);
    setSavedFilter(data);
    const { search } = data;
    const activeField = activeFilterField(data);

    let filterData = {
      customerId: customerId,
      search: search.length > 0 ? search : null,
      siteId: sites?.map((item) => item.id) ?? null,
      queryParameters: Object.keys(activeField).length > 0 ? {...activeField, permission: "Viewer"} : null,
      tagFilterJson: null,
    };
    userService.getFilteredData(filterData).subscribe(setSiteViewers);
  };

  const returnDefaultData = () => {
    setFilterStatus(false);
  };

  return (
    <>
      <BreadCrumbs
        currentPage={"Site Viewers"}
        currentItem={null}
        buttonName={"Site Viewer"}
        addClick={() => setPopup({ isOpen: true, type: "create" })}
        filterPanel={<FilterPanel searchFunc={submitFilter} filterData={filterData} defaultDataFunc={returnDefaultData} />}
      />
      <div className="container">
        {
          !siteViewers ? <ProgressBar />
          : siteViewers.length > 0 ? <StaticTable
                  friendlyTableHeadNames={createTableNames()}
                  data={siteViewers}
                  editItem={handleEditClick}
                  deleteItem={handleDeleteClick}
                  childrenRoute={""}
                  displayFunc={displayFunc}
              /> : <div className="empty-text">No matching records were found</div>
        }
      </div>
      {popup.isOpen && showPopup()}
    </>
  )
});

export default SiteViewerList;
