import React, { FunctionComponent } from "react";
import useMessage from "../../../hooks/useMessage";
import { Alert, Snackbar } from "@mui/material";


const MessageDisplay: FunctionComponent = () => {
  const [message, setMessage] = useMessage();

  const closeMessage = () => setMessage(null);

  return (
    <Snackbar
      open={Boolean(message)}
      onClose={closeMessage}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={closeMessage} severity="success" sx={{ width: '100%', maxWidth: 300 }}>
        {message ?? ''}
      </Alert>
    </Snackbar>
  );
};

export default MessageDisplay;