const API = 'api';
const TAG = `${API}/tag`;
const DEVICE = `${API}/device`;
const SITE = `${API}/site`;
const ASSET = `${API}/asset`
const SETTINGS = `${API}/settings`;
const USER = `${API}/user`;
const CUSTOMER = `${API}/customer`;
const INDOOR_MAP = `${API}/indoor-map`;
const DEVICE_CONFIGURATION = `${API}/device-configuration`;
const NETWORK_SERVER_CONFIGURE = `network-server/configure`;

export const API_ROUTES = {
  TAG: {
    CREATE_TAG: `/${TAG}`,
    UPDATE_TAG: `/${TAG}`,
    GET_ALL_TAGS: `/${TAG}`,
    GET_FILTER_PARAMS: `/${TAG}/get-filter-parameters`,
    GET_FILTER_DATA: `/${TAG}/filter`,
    DELETE_TAG: `/${TAG}`,
  },
  SITE: {
    GET_SITES_BY_CUSTOMER: `/${SITE}/by-customer`,
    GET_SITE_BY_ID: `/${SITE}`,
  },
  DEVICE: {
    CREATE_DEVICE: `/${DEVICE}`,
    UPDATE_DEVICE: `/${DEVICE}`,
    GET_DEVICE_BY_CUSTOMER: `/${DEVICE}`,
    GET_CURRENT_DEVICE: `/${DEVICE}`,
    GET_FREE_DEVICES: `/${DEVICE}/asset-available`,
    GET_FILTER_PARAMS: `/${DEVICE}/get-filter-parameters`,
    GET_FILTER_DATA: `/${DEVICE}/filter`,
    DELETE_DEVICE: `/${DEVICE}`
  },
  SETTINGS: `/${SETTINGS}`,
  ASSET: `/${ASSET}`,
  USER: {
    CURRENT_USER: `/${USER}/current`,
    CREATE_USER: `/${USER}/register`,
    UPDATE_USER: `/${USER}`,
    GET_ALL_USERS: `/${USER}`,
    GET_FILTER_PARAMS: `/${USER}/get-filter-parameters`,
    GET_FILTER_DATA: `/${USER}/filter`,
    DELETE_USER: `/${USER}/delete-by-email`,
  },
  CUSTOMER: {
    GET_ALL_CUSTOMERS: `/${CUSTOMER}`,
    GET_CUSTOMER_BY_ID: `/${CUSTOMER}`,
    },
  INDOOR_MAP: `/${INDOOR_MAP}`,
  DEVICE_CONFIGURATION: `/${DEVICE_CONFIGURATION}`,
  NETWORK_SERVER_CONFIGURE: `/${NETWORK_SERVER_CONFIGURE}`,
};
