import { useEffect } from "react";
import { assetService } from "../../services";
import { delay, mergeMap, of, repeat, take } from "rxjs";

const toMinutes = (minutes: number): number => 1000 * 60 * minutes;

const useAssetListPoll = (filterStatus: boolean) => {
  useEffect(() => {
    if (!filterStatus) {
      const sub = of({}).pipe(
        mergeMap(() => assetService.getAllAssets({ sortingParameter: 'id', sortingType: 'DESC' }).pipe(
          take(1)
        )),
        delay(toMinutes(1)),
        repeat()
      ).subscribe();

      return () => sub.unsubscribe();
    }
  }, [filterStatus]);
};

export default useAssetListPoll;