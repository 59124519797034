import React, { FunctionComponent, memo, KeyboardEvent } from "react";
import { FormikProps } from "formik";
import { object as yupObject, string as yupString, number as yupNumber } from "yup";
import { validation, validationRegExps } from "../../../../constants/errors/errors.constant";
import CustomForm from '../../CustomForm'
import CustomInput from '../../CustomInput'
import { CustomSelect, CustomMultiSelect, makeSelectOptions } from '../../CustomSelect';
import CustomTooltip from "../../CustomTooltip";
import { DeviceConfigurationFormValues } from "./interfaces/device-configuration-form.interface";
import { DeviceConfigurationFormInterface } from "../../../../interfaces/form.interface";
import { getTagColorByName } from "../../../../utils/tags"
import hint from '../../../../style/assets/img/hint-icon.svg';

const validationSchema = () => yupObject().shape({
  configurationName: yupString()
    .required(validation.isRequired("name"))
    .trim()
    .min(3, validation.minLength(3))
    .max(32, validation.maxLength(32)),
  deviceType: yupString()
    .required(validation.isRequired("type")),
  downLink: yupString()
    .required(validation.isRequired("downlink"))
    .matches(validationRegExps.base64RegExp, validation.hexOrBase64Format),
  port: yupNumber()
    .typeError(validation.isRequired("port"))
    .negative(validation.isRequired("port"))
    .integer().positive()
    .max(255)
});

const DeviceConfigurationForm: FunctionComponent<DeviceConfigurationFormInterface> = memo(({ closeFunc, isEdit, tags, ...props }) => {
    const deviceTypeOptions = [{ value: "BREEZE", label: "BREEZE" }, { value: "BREEZE-V", label: "BREEZE-V" }];
    const tagOptions = makeSelectOptions(tags, "keyName", "keyFriendlyName");

    const renderForm = ({
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
        isValid,
        dirty,
        handleSubmit,
    }: FormikProps<DeviceConfigurationFormValues>) => {
        const onBlur = (name: string) => () => setFieldTouched(name);
        const customBlur = (name: string, value: string) => {
          setFieldValue(name, value);
          setFieldTouched(name);
        }
        const onChangeText = (name: string, value: string) => {
          setFieldValue(name, value);
        };
        const onChangeNumber = (name: string, value:  number) => setFieldValue(name, value);
        const onChangeTags = (name: string, values: string[]) => setFieldValue(name, values);
        const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }};

      return (
        <form className="map-popup_inputs" onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
          <div className="main-content">
            <div className="popup-form_item">
              <p>Configuration Name*</p>
              <CustomInput
                name={"configurationName"}
                placeholder={"Configuration Name"}
                value={values.configurationName}
                onChange={onChangeText}
                onBlur={(event) => {
                  customBlur(event.target.name, event.target.value.trim());
                }}
                error={Boolean(touched.configurationName && errors.configurationName)}
                errorMessage={(touched.configurationName && errors.configurationName) ? errors.configurationName : undefined}
                disabled={isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item">
              <p>Device Type*</p>
              <CustomSelect
                options={deviceTypeOptions}
                placeholder={"Device Type"}
                name={"deviceType"}
                onChange={onChangeText}
                onBlur={onBlur('deviceType')}
                value={values.deviceType?.toString()}
                error={Boolean(touched.deviceType && errors.deviceType)}
                errorMessage={(touched.deviceType && errors.deviceType) ? errors.deviceType : undefined}
                disabled={isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item popup-form_multiline">
              <div className="popup-form_item-name-with-hint">
                <p>Downlink*</p>
                <CustomTooltip title="The system accepts Hex or Base64 downlink formats" position="right" withArrow={false}>
                    <img className="popup-form_item-hint" src={hint} alt="hint-icon"/>
                </CustomTooltip>
              </div>
              <CustomInput
                type="textarea"
                name={"downLink"}
                placeholder={"Downlink"}
                value={values.downLink}
                onChange={onChangeText}
                onBlur={onBlur('downLink')}
                error={Boolean(touched.downLink && errors.downLink)}
                errorMessage={(touched.downLink && errors.downLink) ? errors.downLink : undefined}
                disabled={isSubmitting}
                required={true}
              />
              <p className="popup-form_item-comment">
                You can generate downlink in <a href="https://www.atlas.tektelic.com/" target="_blank" rel="noreferrer">Kona Atlas</a>
              </p>
            </div>
            <div className="popup-form_item">
              <p>Port*</p>
              <CustomInput
                type="number"
                name={"port"}
                placeholder={"Port"}
                value={values.port === -1 ? "" : values.port}
                onChange={onChangeNumber}
                onBlur={onBlur('port')}
                error={Boolean(touched.port && errors.port)}
                errorMessage={(touched.port && errors.port) ? errors.port : undefined}
                disabled={isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item">
              <p>Tags</p>
              <CustomMultiSelect name="tags"
                                 placeholder="Tags"
                                 selectedClassName={`tags-item`}
                                 options={tagOptions}
                                 values={values.tags}
                                 onChange={onChangeTags}
                                 onBlur={onBlur('tags')}
                                 error={Boolean(touched.tags && errors.tags)}
                                 errorMessage={(touched.tags && errors.tags && typeof errors.tags === "string") ? errors.tags : undefined}
                                 disabled={isSubmitting}
                                 getColor={(name) => getTagColorByName(tags, name)}
              />
            </div>
          </div>
          <div className="form-btn">
            <button type="submit" disabled={!dirty || isSubmitting || !isValid} className="btn save-btn" >Save</button>
            <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button>
          </div>
        </form>
      )
    };
    return <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />

});

export default DeviceConfigurationForm;