import React from 'react';
import './style/main.scss';
import MainNavigator from "./navigator/MainNavigator";
import { Amplify } from "aws-amplify";
import { AWSConfig } from "./config/aws-exports";
import ErrorDisplay from "./Pages/components/ErrorDisplay";
import MessageDisplay from "./Pages/components/MessageDisplay";

Amplify.configure(AWSConfig);

function App() {
  return (
    <>
      <MainNavigator />
      <ErrorDisplay />
      <MessageDisplay />
    </>
  );
}

export default App;
