import React, { FunctionComponent, memo } from 'react';
import arrow from '../../../style/assets/img/right-arrow.png'
import ButtonAdd from "../Buttons/ButtonAdd";
import { useNavigate } from "react-router-dom";
import useUserRole from "../../../hooks/useUserRole";
import { canShow } from "../../../helpers/userHelper";

interface Props {
  currentPage: string;
  buttonName: string;
  currentItem: string | null;
  addClick: () => void;
  filterPanel?: any
}

const BreadCrumbs: FunctionComponent<Props> = memo(({ currentPage, currentItem, buttonName, addClick, filterPanel }) => {
  const navigate = useNavigate();
  const permission = useUserRole();

  const goBackFunc = () => currentItem ? navigate('..') : null;

  return (
    <div className='breadcrumbs'>
      <div className="title-block">
        <p className={`path ${currentItem ? "main-parent" : ""}`} onClick={goBackFunc}>{currentPage}</p>
        {currentItem && <img src={arrow} alt="arrow"/>}
        {currentItem && <p className='current-item'>{currentItem}</p>}
      </div>
      {filterPanel && filterPanel}
      <div className="button-block">
        {
          canShow(permission)
            ? <ButtonAdd onClick={addClick} value={buttonName} />
            : null
        }
      </div>
    </div>
  )
})
export default BreadCrumbs;
