import online from "../style/assets/img/online-pin.png";
import offline from "../style/assets/img/offline-pin.png";
import pending from "../style/assets/img/pending-pin.png";

export const renderCurrentImgForPin = (status: string) => {
  switch (true) {
    case (status === 'online'):
      return online;
    case (status === 'offline'):
      return offline;
    default:
      return pending;
  }
};