import {catchError, Observable, switchMap, throwError} from "rxjs";
import { IndoorMap, NewIndoorMap, UpdateIndoorMap } from "../../interfaces/indoor-map.interface";
import { Api } from "../../api/api";
import { API_ROUTES } from "../../constants/api-routes.constant";
import { errorService } from "../index";
import { FilterQuery } from "../../interfaces/device.interface";

const indoorMapService = () => {

  const getAllMaps = (options?: any): Observable<IndoorMap[]> => {
    const urlParams = { ...options };
    return Api.get(API_ROUTES.INDOOR_MAP,
      urlParams ? { urlParams } : undefined
    ).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const getMapById = (id: number): Observable<IndoorMap> => {
    return Api.get(`${API_ROUTES.INDOOR_MAP}/${id}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const getFiltersParams = (): Observable<any> => {
    return Api.get(`${API_ROUTES.INDOOR_MAP}/get-filter-parameters`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    )
  };

  const getFilteredData = (params: FilterQuery): Observable<any> => {
    return Api.post(`${API_ROUTES.INDOOR_MAP}/filter`, params).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const createMap = (body: NewIndoorMap): Observable<any> => {
    return Api.post(API_ROUTES.INDOOR_MAP, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const updateMap = (body: UpdateIndoorMap): Observable<any> => {
    return Api.put(API_ROUTES.INDOOR_MAP, body).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const uploadFile = (mapId: number, file: any): Observable<any> => {
    return Api.post(`${API_ROUTES.INDOOR_MAP}/file?mapId=${mapId}`, file, true).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };

  const unpairFields = (mapId: number): Observable<any> => {
    return Api.get(`${API_ROUTES.INDOOR_MAP}/unpair/${mapId}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      }),
    );
  };


  const deleteMap = (id: number): Observable<any> => {
    return Api.delete(`${API_ROUTES.INDOOR_MAP}/${id}`).pipe(
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    );
  };

  const deleteMapFlow = (id: number): Observable<any> => {
    return deleteMap(id).pipe(
      switchMap(() => getAllMaps()),
      catchError(e => {
        errorService.addError(e);
        return throwError(e);
      })
    )
  };

  return {
    getAllMaps,
    getMapById,
    getFiltersParams,
    getFilteredData,
    createMap,
    updateMap,
    uploadFile,
    unpairFields,
    deleteMap,
    deleteMapFlow,
  }
};

const singleton = indoorMapService();
export default Object.freeze(singleton);