import React, {useEffect, useState} from "react";
import CustomCheckbox from "../CustomCheckbox";
import ButtonEdit from "../Buttons/ButtonEdit";
import ButtonDelete from "../Buttons/ButtonDelete";
import { Link } from "react-router-dom";
import {
  defaultAssetTableGeneralNames,
  defaultDeviceTableGeneralNames,
  defaultTableStateNames,
  tableDeviceNames
} from "../../../constants/table-names.constant";
import { measurements } from "../../../constants/measurements.constant";
import { Settings } from "../../../interfaces/settings.interface";
import { messageService, settingsService } from "../../../services";
import useUserRole from "../../../hooks/useUserRole";
import { canShow } from "../../../helpers/userHelper";

interface Prop {
  staticTableHeads: string[];
  dynamicColumnsSrc?: string,
  childrenRoute?: string;
  displayFunc?: (key: string, value: any, measurement: string|null) => any;
  editItem: (item: any) => void;
  deleteItem: (item: any) => void;
  data: object[]|null;
  filterParameters?: string[],
  friendlyNames?: {
    [key: string]: string
  };
  pageType?: string;
  settings: Settings;
  reloadData: () => void;
  clickedDevices?: object[];
  setClickedDevices?: (item: any) => void;
}

export default function CustomTable({ staticTableHeads, dynamicColumnsSrc = "payload", data,
  childrenRoute, pageType, displayFunc, editItem, deleteItem, friendlyNames, settings, reloadData, clickedDevices, setClickedDevices }: Prop) {

  const [columnPopup, setColumnPopup] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [settingStore, setSettingStore] = useState<Settings>(settings);
  const [disableGeneralForm, setDisableGeneralForm] = useState<boolean>(true);
  const [disableStateForm, setDisableStateForm] = useState<boolean>(true);
  const permission = useUserRole();

  useEffect(() => {
    setSettingStore(settings);
  }, [settings])

  //TODO: rewrite custom table!!!!!!!!!!

  const closeColumnPopup = () => {
    setColumnPopup(false);
    setDisableGeneralForm(true);
    setDisableStateForm(true);
  }

  const handleEditClick = (event: any, item: any) => {
    event.preventDefault();
    editItem(item);
  }

  const handleDeleteClick = (event: any, item: any) => {
    event.preventDefault();
    deleteItem(item);
  }

  const handleClickDevice = (event: any, item: any) => {
    event.preventDefault();
    if (clickedDevices && setClickedDevices) {
      const index = clickedDevices.findIndex((value: any) => value.deviceEUI === item.deviceEUI);
      if (index > -1) {
        const newClickedDevicesList = [...clickedDevices];
        newClickedDevicesList.splice(index, 1);
        setClickedDevices(newClickedDevicesList);
      } else {
        setClickedDevices([...clickedDevices, item])
      }
    }
  }

  const handleClickSetAll = () => {
    if (clickedDevices && setClickedDevices) {
      if (data && data.length !== clickedDevices.length) {
        setClickedDevices([...data]);
      } else {
        setClickedDevices([]);
      }
    }
  }

  const renderTableHead = (tableHeads: string[]) => {
    return (
      <>
        { pageType === 'device' && canShow(permission) && <div className={`table-head_item select-all ${data && clickedDevices && data.length === clickedDevices.length && 'active'}`} onClick={handleClickSetAll}/> }
        {tableHeads.map((item: string, index) =>
          <div className={`table-head_item ${defaultTableStateNames.includes(item) ? 'state' : item}`} key={index}>
            {(friendlyNames && friendlyNames[item]) ?? item}
          </div>)}
        <div className={`table-head_item columns`} onClick={() => setColumnPopup(true)}/>
      </>)
  };

  const renderItem = (item: any, index: number) => {
    let payload = item.payload && JSON.parse(item.payload);

    return (
      <Link to={pageType === 'device' ? `/device/${item.deviceEUI}` : `/asset/${item.id}`} className={`${(pageType === 'device' && clickedDevices && clickedDevices.includes(item)) ? 'table-body_row active': 'table-body_row' }`} key={index}>
        <>
          { pageType === 'device' && canShow(permission) && <div className={`table-body_item select-item`} onClick={(event) => handleClickDevice(event, item)}>
            <CustomCheckbox label={item.deviceEUI} value={item.deviceEUI} key={index} name="device"
                            checked={clickedDevices && clickedDevices.includes(item)}
                            onChange={() => {}}
            />
          </div> }
          {staticTableHeads.map((key: string, index) => {
            return displayFunc
              ? <div className={`table-body_item ${defaultTableStateNames.includes(key) ? 'state' : `${key}`}`} key={index}>{payload && (payload[key] !== undefined) ? displayFunc(key, payload[key], measurements[key]) : item[key] ? displayFunc(key, item[key], null) : "-"}</div>
              : <div className={`table-body_item ${defaultTableStateNames.includes(key) ? 'state' : `${key}`}`} key={index}>{payload && (payload[key] !== undefined) ? payload[key] : item[key] ? item[key] : '-'}</div>
          })}
          {/*{renderDynamicColumns()}*/}
          <div className="table-body_item buttons columns">
            {
              canShow(permission) ? <>
                <ButtonEdit onClick={(event) => { handleEditClick(event, item) }} />
                <ButtonDelete onClick={(event) => handleDeleteClick(event, item)}/>
              </> : null
            }
          </div>
        </>
      </Link>
    )
  };

  const updateColumnForm = () => {
    setDisableGeneralForm(true);
    setDisableStateForm(true);
    settingsService.updateSettings(settingStore).subscribe(
      () => {
        reloadData();
        messageService.addMessage('Successful update table');
        closeColumnPopup();
      },
    )
  };

  const onChangeGeneralFormFunction = (event: any) => {
    event.stopPropagation();
    disableGeneralForm && setDisableGeneralForm(!disableGeneralForm);
    if (pageType === 'device') {
      const { deviceTableToDisplay } = settingStore;
      if (deviceTableToDisplay.includes(event.target.name)) {
        const generalStateValue = [...deviceTableToDisplay];
        const index = deviceTableToDisplay.indexOf(event.target.name);
        generalStateValue.splice(index, 1);
        setSettingStore({...settingStore, deviceTableToDisplay: generalStateValue} );
      } else {
        setSettingStore({...settingStore, deviceTableToDisplay: [...deviceTableToDisplay, event.target.name]});
      }
    } else {
      const { assetTableToDisplay } = settingStore;
      if (assetTableToDisplay.includes(event.target.name)) {
        const generalStateValue = [...assetTableToDisplay];
        const index = assetTableToDisplay.indexOf(event.target.name);
        generalStateValue.splice(index, 1);
        setSettingStore({...settingStore, assetTableToDisplay: generalStateValue} );
      } else {
        setSettingStore({...settingStore, assetTableToDisplay: [...assetTableToDisplay, event.target.name]});
      }
    }
  };

  const onChangeStateFormFunction = (event: any) => {
    event.stopPropagation();
    disableStateForm && setDisableStateForm(!disableStateForm);
    if (pageType === 'device') {
      const { deviceColumnsToDisplay } = settingStore;
      if (deviceColumnsToDisplay.includes(event.target.name)) {
        const generalStateValue = [...deviceColumnsToDisplay];
        const index = deviceColumnsToDisplay.indexOf(event.target.name);
        generalStateValue.splice(index, 1);
        setSettingStore({...settingStore, deviceColumnsToDisplay: generalStateValue} );
      } else {
        setSettingStore({...settingStore, deviceColumnsToDisplay: [...deviceColumnsToDisplay, event.target.name]});
      }
    } else {
      const { assetColumnsToDisplay } = settingStore;
      if (assetColumnsToDisplay.includes(event.target.name)) {
        const generalStateValue = [...assetColumnsToDisplay];
        const index = assetColumnsToDisplay.indexOf(event.target.name);
        generalStateValue.splice(index, 1);
        setSettingStore({...settingStore, assetColumnsToDisplay: generalStateValue} );
      } else {
        setSettingStore({...settingStore, assetColumnsToDisplay: [...assetColumnsToDisplay, event.target.name]});
      }
    }
  };

  const renderDeviceColumnPopup = () => {
    const { deviceTableToDisplay, deviceColumnsToDisplay } = settingStore;

    const renderGeneralForm = () => {
      return <>
        <div className="column-popup_main" role="group" aria-labelledby="checkbox-group">
          { defaultDeviceTableGeneralNames.map((name, index) =>
            <CustomCheckbox
              label={tableDeviceNames[name]}
              value={name}
              name={name}
              onChange={(e) => onChangeGeneralFormFunction(e)}
              defaultChecked={deviceTableToDisplay.includes(name)}
              checked={deviceTableToDisplay.includes(name)}
              key={index} />
          )}
        </div>
        <div className="btns-block">
          <button type="submit" className="btn save-btn" disabled={disableGeneralForm} onClick={updateColumnForm}>Done</button>
          <button className="btn cancel-btn" onClick={closeColumnPopup}>Cancel</button>
        </div>
      </>
    };

    const renderStateForm = () => {
      return <>
        <div className="column-popup_main">
          { defaultTableStateNames.map((name, index) =>
            <CustomCheckbox
              label={tableDeviceNames[name]}
              value={name}
              name={name}
              onChange={(e) => onChangeStateFormFunction(e)}
              checked={deviceColumnsToDisplay.includes(name)}
              defaultChecked={deviceColumnsToDisplay.includes(name)}
              key={index}
            />
          )}
        </div>
        <div className="btns-block">
          <button type="submit" className="btn save-btn" disabled={disableStateForm} onClick={updateColumnForm}>Done</button>
          <button className="btn cancel-btn" onClick={closeColumnPopup}>Cancel</button>
        </div>
      </>
    }
    return (
      <div className="column-popup">
        <div className="column-popup_body">
          <div className="column-popup-header">
            <div className={`tag ${isActive && "active"}`} onClick={() => setIsActive(true)}>General</div>
            <div className={`states ${!isActive && "active"}`} onClick={() => setIsActive(false)}>State</div>
          </div>
          {
            isActive ? renderGeneralForm() : renderStateForm()
          }
        </div>
      </div>
    )
  };
  const renderAssetColumnPopup = () => {
    const { assetTableToDisplay, assetColumnsToDisplay } = settingStore;

    const renderGeneralForm = () => {
      return <>
        <div className="column-popup_main" role="group" aria-labelledby="checkbox-group">
          { defaultAssetTableGeneralNames.map((name, index) =>
            <CustomCheckbox
              label={tableDeviceNames[name]}
              value={name}
              name={name}
              onChange={(e) => onChangeGeneralFormFunction(e)}
              defaultChecked={assetTableToDisplay.includes(name)}
              checked={assetTableToDisplay.includes(name)}
              key={index} />
          )}
        </div>
        <div className="btns-block">
          <button type="submit" className="btn save-btn" disabled={disableGeneralForm} onClick={updateColumnForm}>Done</button>
          <button className="btn cancel-btn" onClick={closeColumnPopup}>Cancel</button>
        </div>
      </>
    };

    const renderStateForm = () => {
      return <>
        <div className="column-popup_main">
          { defaultTableStateNames.map((name, index) =>
            <CustomCheckbox
              label={tableDeviceNames[name]}
              value={name}
              name={name}
              onChange={(e) => onChangeStateFormFunction(e)}
              checked={assetColumnsToDisplay.includes(name)}
              defaultChecked={assetColumnsToDisplay.includes(name)}
              key={index}
            />
          )}
        </div>
        <div className="btns-block">
          <button type="submit" className="btn save-btn" disabled={disableStateForm} onClick={updateColumnForm}>Done</button>
          <button className="btn cancel-btn" onClick={closeColumnPopup}>Cancel</button>
        </div>
      </>
    }
    return (
      <div className="column-popup">
        <div className="column-popup_body">
          <div className="column-popup-header">
            <div className={`tag ${isActive && "active"}`} onClick={() => setIsActive(true)}>General</div>
            <div className={`states ${!isActive && "active"}`} onClick={() => setIsActive(false)}>State</div>
          </div>
          {
            isActive ? renderGeneralForm() : renderStateForm()
          }
        </div>
      </div>
    )
  };

  return (
    <div className="table">
      <div className="table-head">
        {renderTableHead(staticTableHeads)}
        {columnPopup ? pageType === 'device' ? renderDeviceColumnPopup() : renderAssetColumnPopup() : null}
      </div>
      <div className="table-body">
        {data?.map((item: any, index: number) => renderItem(item, index))}
      </div>
    </div>
  )
}
