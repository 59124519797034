import React from "react";
import { useNavigate } from "react-router-dom";

import back from '../../../../style/assets/img/back.png'
import error from '../../../../style/assets/img/404.png'

export default function NotFoundPage() {

    const navigate = useNavigate();

    const goHomeFunc = () => {
        navigate('/');
    };

    return (
        <div className="not-found-page">
            <div className="not-found-content">
                <img className="not-found-error" src={error} alt="404 error" />
                <p className="not-found-error-message">Page not found</p>
                <button className="btn go-home-btn" onClick={goHomeFunc}>
                    <span>Back home</span>
                    <img src={back} alt="back" /></button>
            </div>
        </div>
    )
}