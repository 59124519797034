import React, { useEffect, useState } from 'react'
import logo from '../../../style/assets/img/logo.svg';
import breeze from '../../../style/assets/img/breeze.svg';
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { authService } from "../../../services";
import useUserRole from '../../../hooks/useUserRole';
import { canSeePage } from "../../../helpers/userHelper";
import CustomTooltip from "../CustomTooltip";
// import insights from '../../../style/assets/img/insights.svg';
import devices from '../../../style/assets/img/devices.svg';
import rooms from '../../../style/assets/img/rooms.svg';
import maps from '../../../style/assets/img/maps.svg';
// import sites from '../../../style/assets/img/sites.svg';
import users from '../../../style/assets/img/users.svg';
import tags from '../../../style/assets/img/tags.svg';
import documentation from '../../../style/assets/img/documentation.svg';
import logOut from '../../../style/assets/img/log-out.svg';

interface NavigationButton {
  name: string;
  icon: string;
  link?: string;
  action?: () => void
}

const getSidebarStatus = () => {
  const sidebarStatus = localStorage.getItem("sidebarStatus");
  return sidebarStatus ? JSON.parse(sidebarStatus) : false;
}

export default function Sidebar() {
  const permission = useUserRole();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(getSidebarStatus);

  useEffect(() => {
    localStorage.setItem("sidebarStatus", isOpen.toString());
  }, [isOpen])

  const toggleSidebar = () => setIsOpen(!isOpen);

  const signOut = () => {
    authService.signOutProcess().subscribe();
  };

  const openDocumentation = () => window.open('https://wiki.tektelic.com/Breeze-WEB-App/#about-breeze', '_blank', 'noreferrer');

  const buttonsNames = [
    {
      name: "Devices",
      link: "/device",
      icon: devices
    },
    {
      name: "Rooms",
      link: "/asset",
      icon: rooms
    },
    {
      name: "Indoor Maps",
      link: "/maps",
      icon: maps
    },
    {
      name: "Site Viewers",
      link: "/site-viewers",
      icon: users
    },
    {
      name: "Tags",
      link: "/tags",
      icon: tags
    }
  ];

  const footerButtonsNames = [
    {
      name: "Documentation",
      icon: documentation,
      action: openDocumentation,
    },
    {
      name: "Log out",
      icon: logOut,
      action: signOut,
    }
  ]

  const renderNavigationLink = (buttonsNames: NavigationButton[]) => {
    const renderNavigationButton = (item: NavigationButton) => {
      return (
        <>
          {isOpen
            ? <img src={item.icon} alt="nav-icon"/>
            : <CustomTooltip title={item.name.toLocaleUpperCase()} position="right" withArrow={false}>
              <img src={item.icon} alt="nav-icon"/>
            </CustomTooltip>
          }
          <div className="nav-link_text">{item.name}</div>
        </>)
    }

    return buttonsNames.map((item: NavigationButton, index: number) => {
      return (
        item.link
          ? canSeePage(permission, item.link) &&
          <NavLink to={item.link} key={index}
            className={({ isActive }) => `nav-link ${location.pathname === '/' && index === 0 ? 'active' : isActive ? 'active' : ''}`}  >
            {renderNavigationButton(item)}
          </NavLink >
          : <div className='nav-link' key={index} onClick={() => item.action && item.action()}>
            {renderNavigationButton(item)}
          </div>)
    })
  };

  return (
    <div className={`sidebar ${isOpen ? "sidebar_open" : ""}`}>
      <div className="sidebar_content">
        <Link to="/" className="logo">
          <img src={logo} className="logo-sign" alt="logo-sign" />
          <img src={breeze} className="logo-name" alt="logo-name" />
        </Link>
        <div className="menu">
          <span>Menu</span>
          <button className='btn-open' onClick={toggleSidebar} />
        </div>
        <div className="nav-btns">
          {renderNavigationLink(buttonsNames)}
        </div>
      </div>
      <div className="sidebar_footer">
        {renderNavigationLink(footerButtonsNames)}
      </div>
    </div>
  )
}