import { useEffect, useState } from "react";
import { Device } from "../../interfaces/device.interface";
import { deviceService } from "../../services";

const useDevice = () => {
  const [state, setState] = useState<Device[] | null>(null);

  useEffect(() => {
    const sub = deviceService.devices.subscribe(setState);

    return () => sub.unsubscribe();
  }, []);

  return state;
};

export default useDevice;