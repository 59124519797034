import React, { memo, FunctionComponent } from "react";
import CustomPopup from "../CustomPopup";
interface Props {
  itemType: string;
  itemName?: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  disabled?: boolean;
  content?: string;
}
const DeletePopup: FunctionComponent<Props> = memo(({ itemType, itemName, content, handleSubmit, handleCancel, disabled }) => {
  let comment = content || `Are you sure you want to delete the ${itemType}${itemName ? ` '${itemName}'` : ""}?`;
  return (
    <CustomPopup classes="delete-popup" title={`Delete ${itemType}`} comment={comment} withCloseBtn={false} closeFunc={handleCancel}>
      <div className="form-btn">
        <button type="submit" className="btn delete-red-btn" disabled={disabled} onClick={handleSubmit}>Delete</button>
        <button className="btn cancel-btn" disabled={disabled} onClick={handleCancel}>Cancel</button>
      </div >
    </CustomPopup>
  );
});
export default DeletePopup;
