import React, { FunctionComponent, memo, useEffect, useState } from "react";
import { generateLayoutForSetBeacon, heatmapData } from "../../../../helpers/graphFunctions";
import Plot from "react-plotly.js";
import { useOutsideClick } from "../../../../hooks/useClickOutside";

interface Props {
  graphMetrics: {
    xLine: number,
    yLine: number,
  },
  imgUrl: any,
  savePin: (item: any) => void
  revertChanges: () => void
}


const SetBeaconGraphs:FunctionComponent<Props> = memo(({ graphMetrics, imgUrl, savePin, revertChanges }) => {
  const [heatmap, setHeatmap] = useState<any>({});
  const [layout, setLayout] = useState<any>({});
  const [data, setData] = useState<any>([{}, {}]);
  // eslint-disable-next-line
  const [plotlyData, SetPlotlyData] = useState<any>();
  // eslint-disable-next-line
  const [config, setConfig] = useState<any>({displayModeBar: false});
  const [showGraph, setShowGraph] = useState<boolean>(false);


  useEffect(() => {
    generateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateData = () => {
    const { xLine, yLine } = graphMetrics;
    const { heatmap, beaconTrace, } = heatmapData(xLine, yLine);

    const layout = generateLayoutForSetBeacon({width: 1100, height: 800}, {xLine, yLine}, imgUrl);
    setHeatmap(heatmap);
    setLayout(layout);
    setData([ heatmap, beaconTrace ]);
    setShowGraph(true);
  };

  const addBeaconToMap = async (event: any) => {
    //need to get where curveNumber = 0 (index of trace in data array)
    const dataTrace = event.points.filter((obj: any) => {
      return obj.curveNumber === 0;
    });

    if (!dataTrace[0]?.x || !dataTrace[0]?.y) {
      return alert("You click on the beacon, please click on the other place!");
    }

    let xaxis = [];
    let yaxis = [];

    xaxis.push(dataTrace[0].x);
    yaxis.push(dataTrace[0].y);

    let updatedTrace = {
      ...data[1],
      x: xaxis,
      y: yaxis,
    };
    savePin({x: xaxis, y: yaxis});
    setData([ heatmap, updatedTrace ]);
  };

  const ref = useOutsideClick(revertChanges);

  return (
    <div ref={ref}>
      {
        showGraph && <Plot
          divId="myPlot"
          data={data}
          layout={layout}
          onClick={(e) => addBeaconToMap(e)}
          onInitialized={(figure: any) => SetPlotlyData(figure)}
          onUpdate={(figure: any) => SetPlotlyData(figure)}
          config={config}
        />
      }
    </div>
  );
});

export default SetBeaconGraphs;