import React, { FunctionComponent, memo, useState, KeyboardEvent } from "react";
import { FormikProps } from "formik";
import { object as yupObject, string as yupString, number as yupNumber } from "yup";
import { validation } from "../../../../constants/errors/errors.constant";
import CustomForm from '../../CustomForm'
import CustomInput from '../../CustomInput'
import { CustomSelect, CustomMultiSelect, makeSelectOptions } from '../../CustomSelect'
import { DeviceFormValues } from "./interfaces/device-form.interface";
import { DeviceFormInterface } from "../../../../interfaces/form.interface";
import { getTagColorByName } from "../../../../utils/tags"
import {Tag} from "../../../../interfaces/tag.interface";
import MultipleDeviceForm from "../../MultipleDeviceForm";
const validationSchema = () => yupObject().shape({
    name: yupString()
        .required(validation.isRequired("name"))
        .trim()
        .min(3, validation.minLength(3))
        .max(100, validation.maxLength(100)),
    deviceType: yupString()
        .required(validation.isRequired("type")),
    siteId: yupString()
        .required(validation.isRequired("site")),
    deviceEUI: yupString()
        .required(validation.isRequired("EUI"))
        .trim()
        .matches(/^[0-9ABCDEFabcdef]*$/, validation.invalidEUIFormats)
        .matches(/^647.*$/, validation.invalidEUIstart)
        .min(16, validation.exactLength("EUI", 16))
        .max(16, validation.exactLength("EUI", 16)),
    inactivityTimeout: yupNumber()
        .typeError(validation.isRequired("inactivity timeout"))
        .integer().positive()
        .max(1e10)
});

const DeviceForm: FunctionComponent<DeviceFormInterface> = memo(({ closeFunc, isEdit, tags, sites, ...props }) => {
    const [active, setActiveTab] = useState<boolean>(true);
    const deviceTypeOptions = [{ value: "BREEZE", label: "BREEZE" }, { value: "BREEZE-V", label: "BREEZE-V" }];
    const siteOptions = makeSelectOptions(sites, "id", "name");
    // const tagOptions = makeSelectOptions(tags, "id", "keyFriendlyName");
    const tagOptions = (tagArray?: any[]) => {
      if (tagArray) {
        return makeSelectOptions(tagArray, "keyName", "keyFriendlyName");
      }  else {
        return makeSelectOptions(tags, "keyName", "keyFriendlyName");
      }
    };

    const renderForm = ({
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        isSubmitting,
        isValid,
        dirty,
        handleSubmit,
    }: FormikProps<DeviceFormValues>) => {
        const onBlur = (name: string) => () => setFieldTouched(name);
        const onChangeText = (name: string, value: string) => {
          const trimmedValue = value.trim();
          setFieldValue(name, trimmedValue);
        };
        const onChangeNumber = (name: string, value:  number) => setFieldValue(name, value);
        const onChangeTags = (name: string, values: string[]) => setFieldValue(name, values);
        const handleEnterKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
          if (event.key === 'Enter') {
            handleSubmit();
          }};
      const getTagOptionsBySiteId = (siteId: number, currentTag: string[]) => {
        const data = tags.filter((item: Tag) => item.siteId === siteId || item.keyName === currentTag[0]);
        return tagOptions(data);
      }
      return (
        <form className="map-popup_inputs" onSubmit={handleSubmit} onKeyDown={handleEnterKeyDown}>
          <div className="main-content">
            <div className="popup-form_item">
              <p>Device EUI</p>
              <CustomInput
                name={"deviceEUI"}
                value={values.deviceEUI}
                onChange={onChangeText}
                onBlur={onBlur('deviceEUI')}
                error={Boolean(touched.deviceEUI && errors.deviceEUI)}
                errorMessage={(touched.deviceEUI && errors.deviceEUI) ? errors.deviceEUI : undefined}
                disabled={isEdit || isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item">
              <p>Device Name</p>
              <CustomInput
                name={"name"}
                value={values.name}
                onChange={onChangeText}
                onBlur={onBlur('name')}
                error={Boolean(touched.name && errors.name)}
                errorMessage={(touched.name && errors.name) ? errors.name : undefined}
                disabled={isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item">
              <p>Device Type</p>
              <CustomSelect
                options={deviceTypeOptions}
                placeholder={"Device Type"}
                name={"deviceType"}
                onChange={onChangeText}
                onBlur={onBlur('deviceType')}
                value={values.deviceType?.toString()}
                error={Boolean(touched.deviceType && errors.deviceType)}
                errorMessage={(touched.deviceType && errors.deviceType) ? errors.deviceType : undefined}
                disabled={isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item">
              <p>Inactivity timeout (sec)</p>
              <CustomInput
                type="number"
                name={"inactivityTimeout"}
                value={values.inactivityTimeout}
                onChange={onChangeNumber}
                onBlur={onBlur('inactivityTimeout')}
                error={Boolean(touched.inactivityTimeout && errors.inactivityTimeout)}
                errorMessage={(touched.inactivityTimeout && errors.inactivityTimeout) ? errors.inactivityTimeout : undefined}
                disabled={isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item">
              <p>Site</p>
              <CustomSelect
                options={siteOptions}
                placeholder={"Site"}
                name={"siteId"}
                onChange={onChangeText}
                onBlur={onBlur('siteId')}
                value={values.siteId?.toString()}
                error={Boolean(touched.siteId && errors.siteId)}
                errorMessage={(touched.siteId && errors.siteId) ? errors.siteId : undefined}
                disabled={siteOptions.length === 1 || isSubmitting}
                required={true}
              />
            </div>
            <div className="popup-form_item">
              <p>Tags</p>
              <CustomMultiSelect name="tags"
                                 placeholder="Tags"
                                 selectedClassName={`tags-item`}
                                 options={getTagOptionsBySiteId(+values.siteId, values.tags)}
                                 values={values.tags}
                                 onChange={onChangeTags}
                                 onBlur={onBlur('tags')}
                                 error={Boolean(touched.tags && errors.tags)}
                                 errorMessage={(touched.tags && errors.tags && typeof errors.tags === "string") ? errors.tags : undefined}
                                 disabled={isSubmitting || !values.siteId}
                                 getColor={(name) => getTagColorByName(tags, name)}
              />
            </div>
          </div>
          <div className="form-btn">
            <button type="submit" disabled={!dirty || isSubmitting || !isValid} className="btn save-btn" >Save</button>
            <button type="button" className="btn cancel-btn" onClick={closeFunc}>Cancel</button>
          </div>
        </form>
      )
    };
    return (
      <>
        { !isEdit &&
          <div className="tab-block">
            <div className={`tab ${active && 'active'}`} onClick={() => setActiveTab(!active)}><span
              className='small-rectangle'/> add one device
            </div>
            <div className={`tab ${!active && 'active'}`} onClick={() => setActiveTab(!active)}><span
              className='big-rectangle'/>add many devices
            </div>
        </div>
        }
        { active ? <CustomForm renderForm={renderForm} validationSchema={validationSchema} {...props} />
          : <MultipleDeviceForm closeFunc={closeFunc} initialValues={ {file: null}} /> }
      </>
    )
});

export default DeviceForm;
