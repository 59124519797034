import React, { FunctionComponent, memo, useState } from "react";
import StaticTable from "../StaticTable";
import ButtonEdit from "../Buttons/ButtonEdit";
import ButtonDelete from "../Buttons/ButtonDelete";
import { AttributeFormValue } from "./interfaces/attribute-form.interface"
import useTags from "../../../hooks/useTags";
import useUserRole from "../../../hooks/useUserRole";
import AttributeForm from "./AttributeForm";
import CustomPopup from "../CustomPopup";
import { assetService, messageService, errorService } from "../../../services"
import { Asset, AssetAttribute } from "../../../interfaces/asset.interface";
import DeletePopup from "../DeletePopup";
import TagPanel from "../TagPanel";
import { canShow } from "../../../helpers/userHelper";

interface Prop {
  asset: any;
  editFunc: () => void;
  editTag: () => void;
  deleteFunc: () => void;
}

const friendlyNamesForTableHead = {
  keyFriendlyName: "Key",
  value: "Value"
}

const MAX_ITEM_IN_VALUE_LIST = 3;
const assetInfo = ["siteName", "deviceDtos"];

const assetInfoNames: { [key: string]: string } = {
  siteName: "Site",
  deviceDtos: "Assigned devices"
}

const AssetInfo: FunctionComponent<Prop> = memo(({ asset, editFunc, editTag, deleteFunc }) => {
  const permission = useUserRole();
  const allTags = useTags() || [];

  const [isOpenList, setIsOpenList] = useState(false);
  const handleFullList = () => setIsOpenList(!isOpenList);

  const initialAttributeValues: AttributeFormValue = {
    key: "",
    value: ""
  }

  const [popup, setPopup] = useState({ isOpen: false, type: "create" });
  const [editedAttribute, setEditedAttribute] = useState<AssetAttribute>();
  const [deletedAttribute, setDeletedAttribute] = useState<AssetAttribute>();

  const closePopup = () => setPopup({ ...popup, isOpen: false });

  const displayAssignedDevice = (devices: any[]) => {
    const displayValueList = (valuesList: string[]) => {
      return (<>
        {valuesList.map((device, index) =>
          <p className="device-info-data_value-item" key={index}>{device}</p>)}
      </>)
    }
    const displayHiddenValues = (values: string[]) => {
      return (!isOpenList
        ? <p className="link" onClick={handleFullList}>Click here to see full list</p>
        : <>
          {displayValueList(values)}
          <p className="link" onClick={handleFullList}>Click here to hide full list</p>
        </>)
    }

    let rows_visible = [];
    let rows_hidden = [];
    for (let i = 0; i < devices.length; i++) {
      if (i < MAX_ITEM_IN_VALUE_LIST) {
        rows_visible.push(devices[i].name)
      }
      else {
        rows_hidden.push(devices[i].name);
      }
    }
    return (<>
      {displayValueList(rows_visible)}
      {rows_hidden.length > 0 && displayHiddenValues(rows_hidden)}
    </>)
  }

  const displayData = (key: string) => {
    switch (key) {
      case "siteName":
        return asset[key];
      case "deviceDtos":
        return displayAssignedDevice(asset[key]);
    }
  }

  const updateData = () => {
    assetService.getCurrentAsset(asset.id).subscribe({
      error: (error) => console.log(error)
    })
  };

  const editAsset = (asset: Asset, onComplete: () => void, message: string) => {
    assetService.updateAsset(asset).subscribe({
      next: () => {
        updateData();
        messageService.addMessage(message);
      },
      error: (error) => {
        errorService.addError({ message: `Failed to update room! ` + error.messageText });
        onComplete();
      },
      complete: () => onComplete()
    });
  }

  const submitForm = (values: AttributeFormValue, onComplete: () => void, createBool: boolean) => {
    let attributes = [...asset.attributes];
    if (createBool) {
      let newAttribute = {
        keyName: values.key.replace(/ /g, "_"),
        keyFriendlyName: values.key,
        value: values.value
      }
      attributes.push(newAttribute);
      editAsset({ ...asset, attributes: attributes }, onComplete, `"${values.key}" has been successfully added`);
    } else if (editedAttribute) {
      let index = attributes.findIndex(attr => attr.keyName === editedAttribute.keyName);
      let newAttribute = {
        keyName: editedAttribute.keyName,
        keyFriendlyName: values.key,
        value: values.value
      }
      if (index !== -1) {
        attributes.splice(index, 1, newAttribute);
        editAsset({ ...asset, attributes: attributes }, onComplete, `"${values.key}" has been successfully updated`);
      }
      else {
        errorService.addError({ message: `Failed to update attribute!` });
      }
    }
  };

  const editSubmit = (values: AttributeFormValue) => submitForm(values, closePopup, false);
  const addSubmit = (values: AttributeFormValue) => submitForm(values, closePopup, true);

  const deleteSubmit = () => {
    if (deletedAttribute) {
      let attributes = [...asset.attributes];
      let index = attributes.findIndex(attr => attr.keyName === deletedAttribute.keyName);
      if (index !== -1) {
        attributes.splice(index, 1);
        editAsset({ ...asset, attributes: attributes }, closePopup, `"${deletedAttribute.keyFriendlyName}" has been successfully deleted`);
      }
      else {
        errorService.addError({ message: `Failed to delete attribute!` });
      }
    }
  }

  const addAttribute = () => setPopup({ type: "create", isOpen: true });

  const deleteAttribute = (item: AssetAttribute) => {
    setPopup({ type: "delete", isOpen: true });
    setDeletedAttribute(item)
  }

  const editAttribute = (item: any) => {
    setPopup({ type: "edit", isOpen: true });
    setEditedAttribute({ ...item, key: item.keyFriendlyName })
  }

  const renderPopup = () => {
    switch (popup.type) {
      case "create":
        return (
          <CustomPopup title={"Attribute"} titlePrefix={popup.type} closeFunc={closePopup}>
            <AttributeForm closeFunc={closePopup} initialValues={initialAttributeValues} submitForm={addSubmit} />
          </CustomPopup>
        )
      case "edit":
        return <CustomPopup title={"Attribute"} titlePrefix={popup.type} closeFunc={closePopup}>
          <AttributeForm closeFunc={closePopup} initialValues={editedAttribute} submitForm={editSubmit} isEdit={true} />
        </CustomPopup>
      case "delete":
        return <DeletePopup
          itemType="attribute"
          itemName={deletedAttribute?.keyFriendlyName || ""}
          handleSubmit={deleteSubmit} handleCancel={closePopup} />
    }
  }

  return (
    <div className="device-item asset-page">
      <div className="asset-scroll-block">
        <div className="device-item-block">
          <div className="device-info-block">
            <div className="device-id-block">
              <div className="device-id">
                <span className="device-name">{asset.name}</span>
              </div>
              {canShow(permission)
                ? <div className="device-btn">
                  <ButtonEdit onClick={editFunc} />
                  <ButtonDelete onClick={deleteFunc} />
                </div>
                : null
              }
            </div>
            <div className="device-info">
              {assetInfo.map((data, index) => {
                return (
                  <div className="device-info-data" key={index}>
                    <p className="device-info-data_name">{assetInfoNames[data]}</p>
                    <div className="device-info-data_value">{displayData(data)}</div>
                  </div>)
              })}
            </div>
          </div>
        </div>
        <StaticTable
          friendlyTableHeadNames={friendlyNamesForTableHead}
          data={asset.attributes}
          childrenRoute={""}
          editItem={editAttribute}
          deleteItem={deleteAttribute}
          className={"asset-attributes-table"}
          lastHeadItemElement={<button className="btn save-btn add-attr-btn" onClick={addAttribute}>Add new attribute</button>}
        />
      </div>
      <TagPanel tags={asset.tags} allTags={allTags} editTagFunc={editTag} />
      {popup.isOpen && renderPopup()}
    </div>
  )
});

export default AssetInfo;